import { Form, Input } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { InputProps, TextAreaProps } from 'antd/lib/input';
import * as React from 'react';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { hasDigitsOnlyOrEmpty } from 'logic/utils/is-digit';
import { parseEmptyValueAs } from '../../../logic/formaters/formaters';
const { TextArea } = Input;

interface OwnProps extends InputProps {
  isPassword?: boolean;
  isTextArea?: boolean;
  onBlurFormater?: <T>(value: T) => T;
  avoidBrowserAutofill?: boolean;
  noAntForm?: boolean;
  additionalAntFormItemStyles?: any;
  onlyNumbers?: boolean;
  formItemClassName?: string;
}

type FormTextInputProps = WrappedFieldProps & FormItemProps & TextAreaProps & OwnProps;

export const FormTextInput = ({
  label,
  required,
  validateStatus,
  labelCol,
  wrapperCol,
  isPassword = false,
  onBlurFormater,
  meta,
  input,
  avoidBrowserAutofill = false,
  size = 'middle',
  colon = false,
  noAntForm = false,
  additionalAntFormItemStyles = undefined,
  formItemClassName,
  onlyNumbers,
  isTextArea,
  ...rest
}: FormTextInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const TextInputComponent = isPassword ? Input.Password : Input;
  const { onBlur, onChange, ...restInput } = input;

  const handleBlur: FocusEventHandler<any> = (e) => {
    const newValue = e.target.value;
    const formatedValue = onBlurFormater ? onBlurFormater(newValue) : newValue;
    onBlur(parseEmptyValueAs(null)(formatedValue));
  };

  const handleChange: ChangeEventHandler<any> = (e) => {
    const newValue = parseEmptyValueAs(null)(e.target.value);
    const shouldNotChange = onlyNumbers && !hasDigitsOnlyOrEmpty(newValue);
    if (shouldNotChange) return;
    onChange(newValue);
  };

  const handleOnFocus: ChangeEventHandler<any> = (e) => {
    if (e.target.hasAttribute('readonly')) {
      e.target.removeAttribute('readonly');
      // fix for mobile safari to show virtual keyboard
      e.target.blur();
      e.target.focus();
    }
  };

  const renderInput = () =>
    isTextArea ? (
      <TextArea onBlur={handleBlur} onChange={handleChange} {...restInput} {...rest} />
    ) : (
      <TextInputComponent
        onBlur={handleBlur}
        onChange={handleChange}
        size={size}
        {...restInput}
        {...rest}
        readOnly={avoidBrowserAutofill}
        onFocus={avoidBrowserAutofill ? handleOnFocus : undefined}
      />
    );

  return noAntForm ? (
    renderInput()
  ) : (
    <Form.Item
      style={additionalAntFormItemStyles}
      label={label}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      className={formItemClassName}
      colon={colon}
    >
      {renderInput()}
    </Form.Item>
  );
};
