import { combineEpics } from 'redux-observable';
import { concat, EMPTY, forkJoin, from, of, pipe } from 'rxjs';
import { filter, map, switchMap, mergeMap, throttleTime } from 'rxjs/operators';
import { RootEpic } from 'app/app.epics.type';
import { instituteSlice } from './institute.slice';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from '../const';
import { Feature } from '../authentication/feature/authorization.feature';
import {
  FormInstitutePhoto,
  INSTITUTE_OFFER_FORM_NAME,
  InstituteOfferFormValues,
  validateInstituteOfferForm,
} from 'src/pages-all/offer-form/institute-offer-form-page/institute-offer-form.logic';
import { mapInstituteOfferFormToRequest, mapInstituteOfferResponseToForm } from './institute.logic';
import { navigationSlice } from '../navigation/navigation.slice';
import { generatePath } from 'react-router-dom';
import { AppRoute } from 'app/app.route.const';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { mapIdAndFileToLink } from '../file/file.logic';
import { getAbsoluteFileUrl } from 'logic/utils/file';
import { FileApi } from '../file/file.api';
import { getAuthenticatedUserId } from '../authentication/authentication.selectors';
import { fillReduxForm } from 'logic/redux-form/fill-redux-form';
import { logSlice } from '../log/log.slice';
import { handleDeleteOfferDialog } from './delete-offer.logic';
import { paymentSlice } from 'logic/store/payment/payment.slice';
import { INSTITUTE_OFFER_PAGE_SIZE } from 'src/pages-all/tutoring-institute/components/common';
import { getCurrentInstituteOfferPage } from 'logic/store/institute/institute.selectors';

const createInstituteOffer$: RootEpic = (
  action$,
  _,
  {
    managed,
    badRequestFormErrorHandler,
    ofValidReduxForm,
    instituteApi,
    fileApi,
    ofIsAuthenticated,
    ofIsAuthorized,
  }
) =>
  action$.pipe(
    filter(instituteSlice.actions.createInstituteOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.Institute),
    ofValidReduxForm(
      INSTITUTE_OFFER_FORM_NAME,
      validateInstituteOfferForm,
      translate(translationKeys.publishInstituteOffer.errors.fillRequiredAndChooseDefaultImage)
    ),
    managed(
      instituteSlice.actions.createInstituteOffer,
      pipe(
        map((action) => action.payload.formValues),
        uploadInstituteOfferPhotosIfNeeded(fileApi),
        uploadImpresumFileIfNeeded(fileApi),
        switchMap((formValues) =>
          from(instituteApi.createInstituteOffer(mapInstituteOfferFormToRequest(formValues)))
        )
      ),
      [badRequestFormErrorHandler(INSTITUTE_OFFER_FORM_NAME)]
    ),
    map(() =>
      navigationSlice.actions.navigateTo({
        path: generatePath(AppRoute.InstituteOffers),
      })
    )
  );

const updateInstituteOffer$: RootEpic = (
  action$,
  _,
  {
    managed,
    badRequestFormErrorHandler,
    ofValidReduxForm,
    instituteApi,
    fileApi,
    ofIsAuthenticated,
    ofIsAuthorized,
  }
) =>
  action$.pipe(
    filter(instituteSlice.actions.updateInstituteOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.Institute),
    ofValidReduxForm(INSTITUTE_OFFER_FORM_NAME, validateInstituteOfferForm),
    managed(
      instituteSlice.actions.updateInstituteOffer,
      pipe(
        map((action) => action.payload.formValues),
        uploadInstituteOfferPhotosIfNeeded(fileApi),
        uploadImpresumFileIfNeeded(fileApi),
        switchMap((formValues) =>
          from(
            instituteApi.updateInstituteOffer(
              formValues?._id,
              mapInstituteOfferFormToRequest(formValues)
            )
          )
        )
      ),
      [badRequestFormErrorHandler(INSTITUTE_OFFER_FORM_NAME)]
    ),
    mergeMap((response) =>
      of(
        navigationSlice.actions.navigateTo({
          path: generatePath(AppRoute.InstituteOffers),
        }),
        logSlice.actions.logSuccess({
          message: translate(translationKeys.confirmation.yourOfferWasSuccessfullyUpdated),
        })
      )
    )
  );

const toggleInstituteOfferActive$: RootEpic = (
  action$,
  state$,
  { managed, instituteApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(instituteSlice.actions.toggleInstituteOfferActive.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.Institute),
    managed(
      instituteSlice.actions.toggleInstituteOfferActive,
      pipe(
        switchMap(({ payload: { id, isActive } }) =>
          from(instituteApi.toggleInstituteOfferActive(id, { isPublished: isActive }))
        ),
        mergeMap(() =>
          concat(
            of(instituteSlice.actions.fetchInstituteOffers({ page: getCurrentInstituteOfferPage(state$.value), pageSize: INSTITUTE_OFFER_PAGE_SIZE })),
            of(paymentSlice.actions.fetchCustomer())
          )
        )
      )
    )
  );

const uploadInstituteOfferPhotosIfNeeded = (fileApi: FileApi) =>
  switchMap((formValues: InstituteOfferFormValues | undefined) =>
    forkJoin(
      formValues?.photos?.photos?.map((photo) => uploadInstituteOfferPhoto(photo, fileApi))
    ).pipe(
      map((uploadedFiles) => {
        const defaultFile =
          formValues?.photos?.defaultPhotoIndex !== undefined && uploadedFiles
            ? uploadedFiles[formValues?.photos?.defaultPhotoIndex]
            : undefined;

        return {
          ...formValues,
          photo: {
            url: mapIdAndFileToLink(defaultFile?.fileId, defaultFile?.fileId),
          },
          imageIds: uploadedFiles?.map((file) => file.fileId),
          defaultImageId: defaultFile?.fileId,
        };
      })
    )
  );

const NO_FILE_ID = 'no-file-id';
const NO_FILE_NAME = 'no-file-name';

const uploadInstituteOfferPhoto = (photo: FormInstitutePhoto | undefined, fileApi: FileApi) =>
  photo?.newFile
    ? from(fileApi.uploadFile(photo?.newFile)).pipe(
        map((response) => ({
          fileId: response.data.data?._id || NO_FILE_ID,
          name: response.data.data?.name || NO_FILE_NAME,
        }))
      )
    : of({ fileId: photo?.existingPhoto?.imageId || NO_FILE_ID, name: NO_FILE_NAME });

const uploadImpresumFileIfNeeded = (fileApi: FileApi) =>
  switchMap((formValues: InstituteOfferFormValues | undefined) =>
    formValues?.impressumFile?.originFileObj
      ? from(fileApi.uploadFile(formValues?.impressumFile?.originFileObj)).pipe(
          map((response) => ({
            ...formValues,
            impressumUrl: getAbsoluteFileUrl(
              mapIdAndFileToLink(response.data.data?._id, response.data.data?.name)
            ),
          }))
        )
      : of(formValues)
  );

const fetchInstituteOffers$: RootEpic = (
  action$,
  state$,
  { managed, instituteApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(instituteSlice.actions.fetchInstituteOffers.match),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.Institute),
    managed(
      instituteSlice.actions.fetchInstituteOffers,
      pipe(
        map((action) => action.payload),
        switchMap(({ page, pageSize }) => from(instituteApi.fetchInstituteOffers(getAuthenticatedUserId(state$.value), page, pageSize))),
      )
    ),
    map((response) => {
      return instituteSlice.actions.setInstituteOffers({ response: response.data?.data });
    })
  );

const fetchInstituteOfferForEdit$: RootEpic = (
  action$,
  state$,
  { managed, ofIsAuthenticated, ofIsAuthorized, instituteApi }
) =>
  action$.pipe(
    filter(instituteSlice.actions.fetchInstituteOfferForEdit.match),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.Institute),
    managed(
      instituteSlice.actions.fetchInstituteOfferForEdit,
      pipe(
        map((action) => action.payload),
        switchMap(({ id }) => from(instituteApi.fetchInstituteOffer( id || '' ))),

        mergeMap((response) =>
          response.data?.data
            ? of(fillReduxForm(INSTITUTE_OFFER_FORM_NAME, mapInstituteOfferResponseToForm, response.data?.data))
            : of(
                logSlice.actions.logError({
                  message: translate(translationKeys.messages.offerNotFound),
                }),
                navigationSlice.actions.navigateTo({ path: AppRoute.Home })
              )
        )
      )
    )
  );

const tryRemoveOfferEpic$: RootEpic = (action$, _, { dispatch }): any =>
  action$.pipe(
    filter(instituteSlice.actions.tryRemoveOffer.match),
    switchMap(({ payload }) => {
      handleDeleteOfferDialog({
        onDialogResponseOK: () => {
          dispatch(instituteSlice.actions.removeOffer(payload as string));
        },
      });

      return EMPTY;
    })
  );

const removeOfferEpic$: RootEpic = (
  action$,
  state$,
  { managed, offerApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(instituteSlice.actions.removeOffer.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.Institute),
    managed(
      instituteSlice.actions.removeOffer,
      pipe(
        switchMap((action) => from(offerApi.removeOffer(action.payload))),
        mergeMap(() =>
          of(
            instituteSlice.actions.fetchInstituteOffers({ page: getCurrentInstituteOfferPage(state$.value), pageSize: INSTITUTE_OFFER_PAGE_SIZE }),
            logSlice.actions.logSuccess({
              message: translate(translationKeys.confirmation.offerWasDeletedSuccessfully),
            })
          )
        )
      )
    )
  );

export const instituteEpics$ = combineEpics(
  createInstituteOffer$,
  updateInstituteOffer$,
  fetchInstituteOffers$,
  fetchInstituteOfferForEdit$,
  tryRemoveOfferEpic$,
  removeOfferEpic$,
  toggleInstituteOfferActive$
);
