import axios, { AxiosError } from 'axios';
import { logError } from '../log/log.logic';
import { ErrorHandler } from './error-handler';
import { translate, translationKeys } from 'logic/translations/translations.service';
import {
  isConflictError,
  isForbiddenError,
  isUnsupportedMediaTypeError,
} from 'logic/error/general-api-error';
import { isBadRequestError } from 'logic/error/bad-request.error';
import {
  customBadRequestErrorCodeList,
  customConflictErrorCodeList,
  customForbiddenErrorCodeList,
  customUnsupportedMediaTypeErrorCodeList,
} from 'logic/error/error.const';

export const customApiErrorHandler: ErrorHandler<AxiosError> = (error) => {
  const customErrorCode = error?.response?.data?.error;

  if (error && isBadRequestError(error)) {
    if (axios.isAxiosError(error) && customBadRequestErrorCodeList.includes(customErrorCode)) {
      logError(translate(translationKeys.customHttpErrors.badRequestErrors[customErrorCode]));
      return true;
    }
  }

  if (error && isConflictError(error)) {
    if (axios.isAxiosError(error) && customConflictErrorCodeList.includes(customErrorCode)) {
      logError(translate(translationKeys.customHttpErrors.conflictErrors[customErrorCode]));
      return true;
    }
  }

  if (error && isForbiddenError(error)) {
    if (axios.isAxiosError(error) && customForbiddenErrorCodeList.includes(customErrorCode)) {
      logError(translate(translationKeys.customHttpErrors.forbiddenErrors[customErrorCode]));
      return true;
    }
  }

  if (error && isUnsupportedMediaTypeError(error)) {
    if (
      axios.isAxiosError(error) &&
      customUnsupportedMediaTypeErrorCodeList.includes(customErrorCode)
    ) {
      logError(
        translate(translationKeys.customHttpErrors.unsupportedMediaTypeErrors[customErrorCode])
      );
      return true;
    }
    logError(
      translate(translationKeys.customHttpErrors.unsupportedMediaTypeErrors.UNSUPPORTED_MEDIA_TYPE)
    );
    return true;
  }

  return false
};
