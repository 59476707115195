import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isRequiredValidator, areMatching, isOfValidLength } from 'logic/validators/validators';

export const SET_PASSWORD_FORM_NAME = 'set-password';

export interface SetPasswordFormValues {
  password?: string;
  passwordConfirmation?: string;
}

export type SetPasswordFormValidationErrors = FormErrors<SetPasswordFormValues, any>;

export const validateSetPasswordForm = (formValues: SetPasswordFormValues) => {
  const { password, passwordConfirmation } = formValues;

  const errors: SetPasswordFormValidationErrors = {
    password: isRequiredValidator(password) || isOfValidLength(password),
    passwordConfirmation:
      isRequiredValidator(passwordConfirmation) || areMatching(passwordConfirmation, password),
  };

  return clearEmptyFields(errors);
};
