import { isNumber } from 'lodash';
import { isString } from './string';

export const hasDigitsOnlyOrEmpty = (value: any) => {
  if (!value) return true;
  if (!isString(value) && !isNumber(value)) return false;

  return digitsOnlyRegex.test(`${value}`);
};

const digitsOnlyRegex = /^\d+$/;
