import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isRequiredValidator } from 'logic/validators/validators';
import { translate, translationKeys } from 'logic/translations/translations.service';

export const ELITE_FORM_NAME = 'elitetutor-offer-form';

export interface ElitetutorFormValues {
  [formFields.subjects]?: number[];
  [formFields.requirements]?: any;
}

export type FormValidationErrors = FormErrors<ElitetutorFormValues>;

export const validateEliteForm = (formValues: ElitetutorFormValues) => {
  const { subjects, requirements } = formValues;
  const errors: FormValidationErrors = {
    subjects: isRequiredValidator(subjects),
    requirements: (requirements?.length === 3) ? '' : translate(translationKeys.validator.form.isRequired),
  };

  return clearEmptyFields(errors);
};

export enum formFields {
  subjects = 'subjects',
  requirements = 'requirements',
}

