import Cookies from 'js-cookie';

export const initGDPR = () => {
  if (typeof __tcfapi !== 'undefined') {
    const startTrackingInstantly = () => {
      window.trackFbPixel();
      window.trackGoogleAnalytics();
    };
    const callback = (tcData, success) => {
      if (Object.entries(tcData.purpose.consents).length !== 0) {
        Cookies.set('gatsby-gdpr', 'true');
        startTrackingInstantly();
      } else {
        Cookies.set('gatsby-gdpr', 'false');
      }
    };

    __tcfapi('addEventListener', 2, callback);
  } else {
    setTimeout(() => initGDPR(), 200);
  }
};
