import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isRequiredValidator } from 'logic/validators/validators';

export const UPDATE_BIRTHDAY_FORM_NAME = 'update-birthday';
export const UPDATE_BIRTHDAY_FORM_MIN_YEAR_OF_BIRTH = 1920;
export const UPDATE_BIRTHDAY_FORM_MAX_YEAR_OF_BIRTH = new Date().getFullYear() - 1;

export enum UpdateBirthdayField {
  birthday = 'birthday',
}

export interface UpdateBirthdayFormValues {
  [UpdateBirthdayField.birthday]?: string;
}

export type UpdateBirthdayFormValidationErrors = FormErrors<UpdateBirthdayFormValues, any>;

export const validateUpdateBirthdayForm = (formValues: UpdateBirthdayFormValues) => {
  const { birthday } = formValues;

  const errors: UpdateBirthdayFormValidationErrors = {
    birthday: isRequiredValidator(birthday),
  };

  return clearEmptyFields(errors);
};
