import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isEmailValidator, isRequiredValidator } from '../../../../logic/validators/validators';

export const FORGOT_PASSWORD_FORM_NAME = 'forgot-password';

export interface ForgotPasswordFormValues {
  email?: string;
}

export type ForgotPasswordFormValidationErrors = FormErrors<ForgotPasswordFormValues, any>;

export const validateForgotPasswordForm = (formValues: ForgotPasswordFormValues) => {
  const { email } = formValues;

  const errors: ForgotPasswordFormValidationErrors = {
    email: isRequiredValidator(email) || isEmailValidator(email),
  };

  return clearEmptyFields(errors);
};
