import { RootEpic } from 'app/app.epics.type';
import { trimValue } from 'logic/formaters/formaters';
import { logSlice } from 'logic/store/log/log.slice';
import { initialize } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { from, pipe, of } from 'rxjs';
import { filter, map, switchMap, throttleTime } from 'rxjs/operators';
import {
  UPDATE_USER_NAME_FORM_NAME,
  validateUpdateUserNameForm,
} from 'src/pages-all/user/user-profile/components/user-name-section/update-user-name.logic';
import { UserUpdateRequest } from '../../../api-models/api-models';
import { translate, translationKeys } from '../../../translations/translations.service';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from '../../const';
import { getAuthenticatedUser } from '../authentication.selectors';
import { authenticationSlice } from '../authentication.slice';

const updateUserNameInitialize$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(authenticationSlice.actions.updateUserNameInitialize.match),
    map(() =>
      initialize(UPDATE_USER_NAME_FORM_NAME, {
        firstName: getAuthenticatedUser(state$.value)?.firstName,
        lastName: getAuthenticatedUser(state$.value)?.lastName,
      })
    )
  );

const updateUserName$: RootEpic = (
  action$,
  state$,
  { managed, ofValidReduxForm, authenticationApi, badRequestFormErrorHandler }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.updateUserName.match),
    ofValidReduxForm(UPDATE_USER_NAME_FORM_NAME, validateUpdateUserNameForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.updateUserName,
      pipe(
        switchMap((action) => {
          const userId = getAuthenticatedUser(state$.value)?._id || '';
          const request: UserUpdateRequest = {
            firstName: trimValue(action.payload.formValues?.firstName),
            lastName: trimValue(action.payload.formValues?.lastName),
          };
          return from(authenticationApi.updateUser(userId, request));
        })
      ),
      [badRequestFormErrorHandler(UPDATE_USER_NAME_FORM_NAME)]
    ),
    switchMap((response) =>
      of(
        authenticationSlice.actions.setUser({ user: response.data.data }),
        logSlice.actions.logSuccess({
          message: translate(translationKeys.confirmation.userNameWasSuccessfullyUpdated),
        })
      )
    )
  );

export const userNameEpics$ = combineEpics(updateUserNameInitialize$, updateUserName$);
