import { RootEpic } from 'app/app.epics.type';
import { closeConfirmModal } from 'logic/modals/confirm-modal/confirm-modal';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from 'logic/store/const';
import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { filter, ignoreElements, map, switchMap, tap, throttleTime } from 'rxjs/operators';
import { logSuccess } from '../../../log/log.logic';
import { translate, translationKeys } from '../../../translations/translations.service';
import { getAuthenticatedUserId } from '../authentication.selectors';
import { authenticationSlice } from '../authentication.slice';
import { handleDeleteAccountDialog } from './delete-account.logic';

const tryDeleteAccount$: RootEpic = (action$, _, { dispatch, ofIsAuthenticated }) =>
  action$.pipe(
    filter(authenticationSlice.actions.tryDeleteAccount.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    tap(() =>
      handleDeleteAccountDialog({
        onDialogResponseCancel: closeConfirmModal,
        onDialogResponseOK: () => dispatch(authenticationSlice.actions.deleteAccount()),
      })
    ),
    ignoreElements()
  );

const deleteAccount$: RootEpic = (
  action$,
  state$,
  { managed, authenticationApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.deleteAccount.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    map(() => getAuthenticatedUserId(state$.value)),
    managed(
      authenticationSlice.actions.deleteAccount,
      switchMap((userId) => from(authenticationApi.deleteAccount(userId)))
    ),
    tap(() => {
      logSuccess(translate(translationKeys.userProfile.deleteAccount.notification));
    }),
    map(() => authenticationSlice.actions.logout({ hideNotification: true }))
  );

export const deleteAccountEpics$ = combineEpics(tryDeleteAccount$, deleteAccount$);
