import firebase from 'firebase/app';
import 'firebase/analytics';
import { allowAds } from 'logic/cookies/GDPR';

const firebaseConfig = {
  apiKey: 'AIzaSyDHlcvzaecQ9U2KK7AzqF0GkR_mC0y5Vzg',
  authDomain: 'tutorspace-8926b.firebaseapp.com',
  databaseURL: 'https://tutorspace-8926b.firebaseio.com',
  projectId: 'tutorspace-8926b',
  storageBucket: 'tutorspace-8926b.appspot.com',
  messagingSenderId: '447556671683',
  appId: '1:447556671683:web:679b241fb5ece2d9716a76',
  measurementId: 'G-F2WEMNEXSD',
};

export const isSSR = typeof window === 'undefined';
!isSSR && allowAds && firebase.initializeApp(firebaseConfig);
export const firebaseAnalytics = allowAds && !isSSR && firebase.analytics();
