import { Field } from 'redux-form';
import { FormSelectInput } from 'components/form-inputs/form-select-input/form-select-input';
import { translate, translationKeys } from 'logic/translations/translations.service';
import React, { CSSProperties, FC, useMemo } from 'react';
import { FieldWrapper } from './common';
import { TeachingLevelVariants } from 'logic/api-models/teaching-level';
import { getTeachingLevelsToSubjectOptions } from 'logic/api-models/teaching-level.logic';

type Props = {
  fieldName: string;
  label: string;
  showLabel?: boolean;
  levelsVariant: TeachingLevelVariants;
  style?: CSSProperties;
};

export const TeachingLevelInput: FC<Props> = ({
  fieldName,
  label,
  showLabel = true,
  levelsVariant,
  style,
}) => {
  const teachingLevelsSelectOption = useMemo(
    () => getTeachingLevelsToSubjectOptions(levelsVariant),
    [levelsVariant]
  );

  return (
    <FieldWrapper style={style}>
      <Field
        name={fieldName}
        component={FormSelectInput}
        options={teachingLevelsSelectOption}
        label={showLabel ? label : undefined}
        placeholder={translate(translationKeys.publishOffer.level.placeholder)}
      />
    </FieldWrapper>
  );
};
