import React, { CSSProperties } from 'react';
import { FontWeightProperty } from 'csstype';
import { Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import styled from 'styled-components';
import { colors } from 'styles/colors';

const DefaultTextStyles = styled(Typography.Text)`
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: anywhere;

  &.center {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
`;

export const Text: React.FC<
  TextProps & {
    color?:
      | 'primary'
      | 'blueGray'
      | 'lightGray'
      | 'grey'
      | 'grey5'
      | 'gray4'
      | 'white'
      | 'transparentWhite'
      | 'black'
      | 'green'
      | 'red'
      | 'primaryLight'
      | string;
    size?: number;
    textAlign?: 'justify' | 'left' | 'center';
    className?: string;
    noWrap?: boolean;
    fontWeight?: FontWeightProperty;
    style?: CSSProperties;
    onClick?: () => void;
  }
> = ({ children, color, size, textAlign, className, noWrap, fontWeight, style, ...rest }) => (
  <DefaultTextStyles
    {...rest}
    className={className ? className : textAlign === 'center' ? 'center' : ''}
    style={{
      color: !color
        ? undefined
        : color === 'primary'
        ? colors.primary
        : color === 'grey'
        ? colors.gray3
        : color === 'grey5'
        ? colors.gray5
        : color === 'blueGray'
        ? colors.blueGray
        : color === 'lightGray'
        ? colors.gray10
        : color === 'gray4'
        ? colors.gray4
        : color === 'white'
        ? colors.white
        : color === 'transparentWhite'
        ? colors.transparentWhite
        : color === 'black'
        ? colors.black
        : color === 'green'
        ? colors.darkGreen
        : color === 'red'
        ? colors.red
        : color === 'primaryLight'
        ? colors.primaryLight
        : colors[color],
      fontSize: size ? `${size}px` : undefined,
      textAlign,
      whiteSpace: noWrap ? 'nowrap' : 'normal',
      fontWeight,
      ...style,
    }}
  >
    {children}
  </DefaultTextStyles>
);
