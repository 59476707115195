import { axiosApiAuthenticatedClient } from 'logic/axios/axios-api-authenticated-client';
import { GetAccessToken } from 'logic/axios/axios-headers-authentication.model';
import {
  ApiResponse,
  GetRecordingResponse,
  JoinRoomResponse,
  RoomListResponse,
} from 'logic/api-models/api-models';

export const conferenceApiWithGetAccessToken = (getAccessToken: GetAccessToken) => ({
  fetchSocialCall: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<RoomListResponse>>(
      '/conference/social-call'
    ),
  fetchIntroductionCall: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<RoomListResponse>>(
      '/conference/intro-call'
    ),
  joinCall: (joinRoomID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<JoinRoomResponse>>(
      `/conference/room/${joinRoomID}/join`
    ),
  createAndJoinTestCall: () =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<JoinRoomResponse>>(
      '/conference/room/test-call/join'
    ),
  getRecording: (joinRoomID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<GetRecordingResponse>>(
      `/conference/room/${joinRoomID}/recording`
    ),
});

export type ConferenceApi = ReturnType<typeof conferenceApiWithGetAccessToken>;
