import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { sentryErrorHandler } from 'logic/error/sentry.error';
import { isNotFoundError } from 'logic/error/not-found.error';
import { API_MULTIPLY_CALLS_TIMEOUT_AUTOCOMPLETE_MS } from '../const';
import { geolocationApi } from './geolocation.api';
import { mapMapBoxPlacesToSelectOptions } from './geolocation.logic';
import { MapBoxPlace } from './geolocation.model';
import { geolocationSlice } from './geolocation.slice';
import { RootEpic } from 'app/app.epics.type';
import { Dispatch } from 'redux';

const fetchAutocompletePlaces: RootEpic = (action$, _, { dispatch, managed }) =>
  action$.pipe(
    filter(geolocationSlice.actions.fetchAutocompletePlaces.match),
    debounceTime(API_MULTIPLY_CALLS_TIMEOUT_AUTOCOMPLETE_MS),
    switchMap((action) =>
      of(action).pipe(
        managed(
          geolocationSlice.actions.fetchAutocompletePlaces,
          switchMap(() =>
            from(
              geolocationApi.fetchPlaces(
                action.payload.searchPhrase || '',
                action.payload.searchPlaces
              )
            )
          ),
          [handleGetPacesError(action.payload.valuePlace, dispatch)]
        ),
        map((response) =>
          geolocationSlice.actions.setAutocompletePlacesSelectOptions({
            options: mapMapBoxPlacesToSelectOptions(response.data, action.payload.valuePlace),
            valuePlace: action.payload.valuePlace,
          })
        )
      )
    )
  );

const handleGetPacesError = (valuePlace: MapBoxPlace | undefined, dispatch: Dispatch) => (
  error: any
) => {
  dispatch(
    geolocationSlice.actions.setAutocompletePlacesSelectOptions({
      options: [],
      valuePlace,
    })
  );
  if (!isNotFoundError(error)) {
    sentryErrorHandler(error);
  }
  return true;
};

export const geolocationEpics$ = combineEpics(fetchAutocompletePlaces);
