import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isFileSizeLessOrEqual } from 'logic/validators/validators';
import { MAX_UPLOAD_IMAGE_SIZE_IN_BYTES } from 'src/pages-all/offer-form/offer-form.logic';

export interface FormPhotoValue {
  url?: string;
  file?: File;
}

export enum RegisterAsElitetutorPhotoFormFields {
  photo = 'photo',
}

export const REGISTER_ELITETUTOR_PHOTO_FORM = 'register-elitetutor-photo-form';
export interface RegisterAsElitetutorPhotoFormValues {
  [RegisterAsElitetutorPhotoFormFields.photo]?: FormPhotoValue;
}
export type RegisterAsElitetutorPhotoFormValidationErrors = FormErrors<
  RegisterAsElitetutorPhotoFormValues,
  string
>;

export const validateRegisterAsElitetutorPhotoForm = (
  formValues: RegisterAsElitetutorPhotoFormValues
) => {
  const { photo } = formValues;

  const errors: RegisterAsElitetutorPhotoFormValidationErrors = {
    photo: !!photo?.file
      ? isFileSizeLessOrEqual(photo?.file, MAX_UPLOAD_IMAGE_SIZE_IN_BYTES)
      : undefined,
  };

  return clearEmptyFields(errors);
};
