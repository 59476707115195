import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import {
  isEmailValidator,
  isOfValidLength,
  isRequiredValidator,
} from '../../../../logic/validators/validators';

export const REGISTER_WITH_EMAIL_FORM_NAME = 'register-with-email';

export interface RegisterWithEmailFormValues {
  firstName?: string;
  lastName?: string;
  emailId?: string;
  password?: string;
}

export type RegisterWithEmailFormValidationErrors = FormErrors<RegisterWithEmailFormValues, any>;

export const validateRegisterWithEmailForm = (formValues: RegisterWithEmailFormValues) => {
  const { firstName, lastName, emailId, password } = formValues;

  const errors: RegisterWithEmailFormValidationErrors = {
    firstName: isRequiredValidator(firstName),
    lastName: isRequiredValidator(lastName),
    emailId: isRequiredValidator(emailId) || isEmailValidator(emailId),
    password: isRequiredValidator(password) || isOfValidLength(password),
  };

  return clearEmptyFields(errors);
};
