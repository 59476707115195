import { ElitetutorFormValues } from 'src/pages-all/offer-form/elitetutor-offer-form/elitetutor-offer-form.logic';
import {
  CreateBookingRequest,
  CreditTutorResponse,
  SubmitEliteApplicationRequest,
} from 'logic/api-models/api-models';
import { EliteTutoringBookingFormValues } from 'src/pages-all/booking-form/booking-form.logic';
import { BOOKING_DURATION_IN_MINUTES } from 'logic/store/elitetutor/elitetutor.const';
import moment from 'moment';
import { UpdatePayoutInfoFormValues } from 'src/pages-all/user/user-profile/earnings/components/update-payout-info/update-payout-info.logic';

export const mapOfferFormToRequest: (
  formValues: ElitetutorFormValues | undefined
) => SubmitEliteApplicationRequest = (formValues) => {
  const request: SubmitEliteApplicationRequest = {
    subjects: formValues?.subjects || [],
  };

  return request;
};

export const mapBookingFormToRequest: (
  formValues: EliteTutoringBookingFormValues | undefined,
  seekerId: string,
  price: number,
) => CreateBookingRequest = (formValues, seekerId, price) => {

  const request: CreateBookingRequest = {
    seekerId: seekerId,
    start: moment(formValues?.start?.getTime()).format(),
    bookingDurationInMinutes: BOOKING_DURATION_IN_MINUTES,
    details: {
      subject: formValues?.subject,
      requestTeachingLevel: formValues?.requestTeachingLevel,
      seekerNote: formValues?.seekerNote,
      price: price,
    },
  };

  return request;
};

export const mapCreditTutorToForm = (
  creditTutor: CreditTutorResponse | null | undefined
): UpdatePayoutInfoFormValues | undefined => {
  if (!creditTutor) {
    return;
  }

  const { payoutInfo, donationPercent } = creditTutor;
  if (!payoutInfo && donationPercent == null) {
    return;
  }

  return { donationPercent, ...(payoutInfo ?? {}) };
};
