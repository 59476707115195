import { Menu } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { getMenuItems } from './top-menu.const';
import { Text } from 'components/text/Text';
import { useIsDesktop } from 'logic/hooks/use-is-tablet';

const { SubMenu } = Menu;

export const TopMenu: React.FC = () => {
  const [current, setCurrent] = useState<string>('');
  const isDesktop = useIsDesktop();
  return (
    <div className="menu-container">
      <Menu
        onClick={(e) => setCurrent(e.key.toString())}
        selectedKeys={[current]}
        mode={isDesktop ? 'horizontal' : 'inline'}
        style={{ paddingLeft: '12px', width: isDesktop ? '' : '300px' }}
      >
        {getMenuItems.map((menuItem) => (
          <SubMenu key={menuItem.title} title={menuItem.title}>
            {menuItem.submenu?.map((items, idx) => (
              <Menu.Item
                key={idx}
                style={{
                  height: items.description && 'min-content',
                  padding: items.description && '10px',
                  lineHeight: items.description && '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <a href={items.url}>
                  <Text fontWeight={600} style={{ display: 'block' }}>
                    {items.title}
                  </Text>
                  {items.description && <Text style={{ display: 'block' }}>{items.description}</Text>}
                </a>
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </div>
  );
};
