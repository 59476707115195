import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';
import loadable from '@loadable/component';
import { useInView } from 'react-intersection-observer';

const ReactPlayer = loadable(() => import('react-player'));

const VideoWrapper = styled.div<{ height?: string; width?: string }>`
  border-radius: 10px;
  max-width: 100%;
  overflow: hidden;
  ${({ height, width }) => css`
    height: ${height};
    width: ${width};
  `}
`;

type Props = {
  id: string;
  ratio: number;
  width?: number;
  stretchWidth?: boolean;
  style?: CSSProperties;
};

// TODO: find a better solution for the default width
export const VideoPlayer: FC<Props> = ({ id, ratio, width, style, stretchWidth }) => {
  const [showControls, setShowControls] = useState(true);
  const { min1024 } = useBreakpoints();
  const { ref, inView } = useInView();
  const [showPlayer, setShowPlayer] = useState(false);
  const defaultWidth = min1024 ? 744 : 280;
  const height = (width ? width : defaultWidth) * ratio;
  useEffect(() => {
    inView && !showPlayer && setShowPlayer(true);
  }, [inView, showPlayer]);

  return (
    <VideoWrapper
      ref={ref}
      width={stretchWidth ? '100%' : width ? width + 'px' : defaultWidth + 'px'}
      height={stretchWidth ? '100%' : undefined}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
      style={style}
    >
      {showPlayer && (
        <ReactPlayer
          url={`https://vimeo.com/${id}`}
          width={stretchWidth ? '100%' : style?.width ? style.width : `${width}px`}
          height={stretchWidth ? '100%' : style?.height ? style.height : `${height}px`}
          config={{
            vimeo: {
              playerOptions: {
                controls: showControls,
                color: colors.primary,
              },
            },
          }}
        />
      )}
    </VideoWrapper>
  );
};
