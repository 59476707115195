import { env } from '../env/envs';
import { createAxios } from './axios-create';
import { getAuthenticationHeaders } from './axios-headers-authentication';
import { GetAccessToken } from './axios-headers-authentication.model';
import { getCommonHeaders } from './axios-headers-common';

export const axiosApiAuthenticatedClient = (getAccessToken: GetAccessToken) =>
  createAxios({
    baseURL: env.GATSBY_REACT_APP_API_URL,
    headers: {
      ...getCommonHeaders(),
      ...getAuthenticationHeaders(getAccessToken),
    },
  });
