export const isString = (value: any) => typeof value === 'string';

export const trim = (char: string | undefined) => (value: string | undefined) => {
  if (!value || !isString(value)) return undefined;
  if (!char || !isString(char)) return value;

  if (char === ']') char = '\\]';
  if (char === '\\') char = '\\\\';

  return value.replace(new RegExp('^[' + char + ']+|[' + char + ']+$', 'g'), '');
};

export const trimSlahes = trim('/');

export const truncateString = (string: string, length: number) => {
  if (string.length > length) {
    return string.substring(0, length) + '...';
  } else {
    return string;
  }
};
