export enum Feature {
  Institute = 'Institute',
  RequestOffer = 'RequestOffer',
  MyMessages = 'MyMessages',
  MyOffer = 'MyOffer',
  MyProfile = 'MyProfile',
  GiveLesson = 'GiveLesson',
  SocialCall = 'SocialCall',
  IntroductionCall = 'IntroductionCall',
  ApplyAsElite = 'ApplyAsElite',
  UpdateAvailability = 'UpdateAvailability',
  CreateBooking = 'CreateBooking',
}
