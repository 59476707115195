import { ApiResponse, FileResponse } from 'logic/api-models/api-models';
import { axiosApiAuthenticatedFileClient } from 'logic/axios/axios-api-authenticated-file-client';
import { GetAccessToken } from 'logic/axios/axios-headers-authentication.model';

export const fileApiWithGetAccessToken = (getAccessToken: GetAccessToken) => ({
  uploadFile: (file: File | Blob, onUploadProgress?: (progressEvent: ProgressEvent) => void) => {
    const formData = new FormData();
    formData.append('file', file);

    return axiosApiAuthenticatedFileClient(getAccessToken).post<ApiResponse<FileResponse>>(
      '/file',
      formData,
      {
        onUploadProgress,
      }
    );
  },
  uploadVideo: (file: File | Blob, userId: string, onUploadProgress?: (progressEvent: ProgressEvent) => void) => {
    const formData = new FormData();
    formData.append('file', file);
    return axiosApiAuthenticatedFileClient(getAccessToken).post<ApiResponse<FileResponse>>(
      `/user/${userId}/pending-video`,
      formData,
      {
        onUploadProgress,
      }
    );
  },
});

export type FileApi = ReturnType<typeof fileApiWithGetAccessToken>;
