import { StoreState } from '../../../app/app.reducers';
import { getSubjectByName, getSubjectBySlug } from './subject.logic';

export const getSubjects = (state: StoreState) => state.subject.subjects;

export const getSubjectSelectOptionsNames = (state: StoreState) =>
  state.subject.subjectSelectOptionsNames;

export const getSubjectSelectOptions = (state: StoreState) => state.subject.subjectSelectOptions;

export const getEliteSubjectSelectOptions = (state: StoreState) => state.subject.eliteSubjectSelectOptions;

export const getPopularSubjectSelectOptions = (state: StoreState) =>
  state.subject.popularSubjectSelectOptions;

export const getSubjectsByNumber = (state: StoreState) => state.subject.subjectsByNumber;

export const selectSubjectByName = (subjectName: string | undefined | null) => (
  state: StoreState
) => getSubjectByName(state.subject?.subjectsByName, subjectName);

export const selectSubjectBySlug = (subjectSlug: string | undefined | null) => (
  state: StoreState
) => getSubjectBySlug(state.subject?.subjectsBySlug, subjectSlug);
