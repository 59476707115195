import React, { CSSProperties } from 'react';
import './form-container.styles.less';
import { Form, Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

interface FormContainerProps {
  inProgress?: boolean;
  progressSpinner?: SpinProps;
  className?: string;
  style?: CSSProperties;
  layout?: 'horizontal' | 'inline' | 'vertical' | undefined;
}

export const FormContainer: React.FC<FormContainerProps> = ({
  className = '',
  children,
  progressSpinner,
  inProgress = false,
  layout = undefined,
  style,
}) => (
  <Spin spinning={!progressSpinner && inProgress} {...progressSpinner}>
    <div className={`form-container ${className}`} style={style}>
      <Form className={'form'} layout={layout}>
        {children}
      </Form>
    </div>
  </Spin>
);

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

export const formButtonModalLayout = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
  },
};
