import { combineEpics } from 'redux-observable';
import { filter, ignoreElements, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { navigate } from 'gatsby';
import { scrollToTop } from 'components/scroll-to-top/ScrollToTop';
import { navigationSlice } from './navigation.slice';
import { hasWindow } from 'logic/utils/window';
import { openInNewTab } from './navigation.logic';
import { RootEpic } from 'app/app.epics.type';

const navigateTo$: RootEpic = (action$, _, { ofIsAuthenticated, ofIsAuthorized }) =>
  action$.pipe(
    filter(navigationSlice.actions.navigateTo.match),
    switchMap((action) =>
      of(action).pipe(
        ofIsAuthenticated(!!action.payload.authenticate),
        ofIsAuthorized(action.payload.authorizeFeature),
        tap((action) => {
          if (action.payload.openInNewTab) {
            openInNewTab(action.payload.path);
          } else {
            navigate(action.payload.path);
            scrollToTop();
          }
        })
      )
    ),
    ignoreElements()
  );

const back$: RootEpic = (action$) =>
  action$.pipe(
    filter(navigationSlice.actions.back.match),
    tap((action) => {
      if (hasWindow) {
        window.history.back();
      }
    }),
    ignoreElements()
  );

export const navigationEpic$ = combineEpics(navigateTo$, back$);
