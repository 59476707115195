import { USER_PROFILE_DEFAULT_AVATAR_IMAGE } from 'assets/images/images';
import { env } from '../env/envs';
import { isString, trimSlahes } from './string';

export const getImageSrcCreator = (apiUrl: string | undefined) => (url: string | undefined) => {
  if (!url || !isString(url)) return USER_PROFILE_DEFAULT_AVATAR_IMAGE;

  const isRelative = !url.includes('http');

  if (isRelative && (!apiUrl || !isString(apiUrl))) return USER_PROFILE_DEFAULT_AVATAR_IMAGE;

  return isRelative ? `${trimSlahes(apiUrl)}/${trimSlahes(url)}` : url;
};

export const getImageSrc = getImageSrcCreator(env.GATSBY_REACT_APP_API_URL);
