export enum RequestTutoringSteps {
  Subject = 'subject',
  TutoringType = 'tutoring-form',
  Level = 'level',
  Hours = 'hours',
}

export enum AlternativeOffersKey {
  AlternativeOffersFirst = 'AlternativeOfferFirst',
  AlternativeOffersSecond = 'AlternativeOfferSecond',
}
