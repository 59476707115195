import React from 'react';
import './master-header.styles.less';
import { Button, Dropdown, Layout } from 'antd';
import { Link } from 'gatsby';
import { LOGO, LOGO_WITH_TEXT_BLACK } from '../../../../assets/icons/icons';
import { useIsDesktop } from '../../../../logic/hooks/use-is-tablet';
import { TopMenu } from '../top-menu/top-menu';
import loadable from '@loadable/component';
import { Profile } from 'src/pages-all/home/components/parts/premium-header/premium-header';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const RightMenu = loadable(() => import('../right-menu/right-menu'));

export const StyledDropdown = styled(Dropdown)`
  margin-right: 12px;
  align-self: center;
`;

const { Header } = Layout;

export const MasterHeader: React.FC = () => {
  const isDesktop = useIsDesktop();

  return (
    <Header className="master-header noselect">
      <div className="header-container">
        <div className="left-menu-content">
          {!isDesktop && (
            <Link to="/">
              <img className="logo" src={LOGO} alt="logo" loading="lazy" />
            </Link>
          )}
          {isDesktop && (
            <Link to="/">
              <img
                className="logo-with-text"
                src={LOGO_WITH_TEXT_BLACK}
                alt="logo"
                loading="lazy"
              />
            </Link>
          )}
          {isDesktop && <TopMenu />}
          {!isDesktop && (
            <Profile>
              <div className="right-menu-content">
                <RightMenu />
              </div>
            </Profile>
          )}
        </div>
        {isDesktop && (
          <Profile>
            <div className="right-menu-content">
              <RightMenu />
            </div>
          </Profile>
        )}
        {!isDesktop && (
          <StyledDropdown overlay={<TopMenu />} trigger={['hover', 'click']}>
            <Button>
              <MenuOutlined />
            </Button>
          </StyledDropdown>
        )}
      </div>
    </Header>
  );
};
