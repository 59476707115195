import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isRequiredValidator, isEmailValidator } from 'logic/validators/validators';

export const UPDATE_EMAIL_FORM_NAME = 'update-email';

export interface UpdateEmailFormValues {
  emailId?: string;
}

export type UpdateEmailFormValidationErrors = FormErrors<UpdateEmailFormValues, any>;

export const validateUpdateEmailForm = (formValues: UpdateEmailFormValues) => {
  const { emailId } = formValues;

  const errors: UpdateEmailFormValidationErrors = {
    emailId: isRequiredValidator(emailId) || isEmailValidator(emailId),
  };

  return clearEmptyFields(errors);
};
