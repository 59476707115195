import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DiscountResponse,
  GetBillingInfoResponse,
  GetPaymentMethodsResponse,
  GetSubscriptionResponse,
  InvoicePaymentSettingsRequest,
  InvoiceResponse,
  PaymentCollectionMethod,
  PaymentsConfigResponse,
  PaymentsNextInvoiceResponse,
} from 'logic/api-models/payments';

export interface PaymentState {
  paymentMethods?: GetPaymentMethodsResponse;
  subscriptions?: GetSubscriptionResponse;
  billingInfo?: GetBillingInfoResponse;
  config?: PaymentsConfigResponse;
  nextInvoice?: PaymentsNextInvoiceResponse;
  offerIdPendingActivation?: string;
  discount?: DiscountResponse;
  invoices?: InvoiceResponse[];

  addPaymentMethodModalOpened: boolean;
  billingInfoModalOpened: boolean;
  confirmPaymentStartModalOpened: boolean;
}

const initialState: PaymentState = {
  addPaymentMethodModalOpened: false,
  billingInfoModalOpened: false,
  confirmPaymentStartModalOpened: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: () => initialState,
    fetchCustomer: () => {},
    setUpCardPaymentMethod: (_state, _action: PayloadAction<any>) => {},
    setUpIbanSEPAPaymentMethod: (_state, _action: PayloadAction<any>) => {},
    setPaymentMethods: (state, action: PayloadAction<GetPaymentMethodsResponse | undefined>) => {
      state.paymentMethods = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<GetSubscriptionResponse | undefined>) => {
      state.subscriptions = action.payload;
    },
    setConfig: (state, action: PayloadAction<PaymentsConfigResponse | undefined>) => {
      state.config = action.payload;
    },
    setNextInvoice: (state, action: PayloadAction<PaymentsNextInvoiceResponse | undefined>) => {
      state.nextInvoice = action.payload;
    },
    setDiscount: (state, action: PayloadAction<DiscountResponse | undefined>) => {
      state.discount = action.payload;
    },
    setInvoices: (state, action: PayloadAction<InvoiceResponse[] | undefined>) => {
      state.invoices = action.payload;
    },
    updateBillingInfo: (_state) => {},
    setUpInvoicePaymentMethod: (_state, action: PayloadAction<InvoicePaymentSettingsRequest>) => {},
    setBillingInfo: (state, action: PayloadAction<GetBillingInfoResponse | undefined>) => {
      state.billingInfo = action.payload;
    },
    setUpNewSubscription: () => {},
    openAddPaymentMethodModal: (state) => {
      state.addPaymentMethodModalOpened = true;
    },
    closeAddPaymentMethodModal: (state) => {
      state.addPaymentMethodModalOpened = false;
    },
    openBillingInfoModal: (state) => {
      state.billingInfoModalOpened = true;
    },
    closeBillingInfoModal: (state) => {
      state.billingInfoModalOpened = false;
    },
    openConfirmPaymentStartModal: (
      state,
      action: PayloadAction<{ offerIdPendingActivation: string }>
    ) => {
      state.confirmPaymentStartModalOpened = true;
      state.offerIdPendingActivation = action.payload.offerIdPendingActivation;
    },
    closeConfirmPaymentStartModal: (state) => {
      state.confirmPaymentStartModalOpened = false;
      state.offerIdPendingActivation = undefined;
    },
    tryCancelSubscription: () => undefined,
    cancelSubscription: () => undefined,
    updateStandardPaymentMethod: (
      _state,
      action: PayloadAction<{
        id?: string;
        collectionMethod?: PaymentCollectionMethod;
        isInvoice?: boolean;
      }>
    ) => {},
  },
});
