import { GetAccessToken } from 'logic/axios/axios-headers-authentication.model';
import {
  ApiResponse,
  CheckResetTokenResponse,
  FacebookLoginRequest,
  LoginRequest,
  LoginResponse,
  RegisterElitetutorRequest,
  RegisterRequest,
  RegisterResponse,
  RegisterWithoutPasswordRequest,
  ResendSmsRequest,
  ResetPasswordRequest,
  SendPasswordResetRequest,
  SmsActivationRequest,
  User,
  UserUpdateRequest,
} from '../../api-models/api-models';
import { axiosApiAuthenticatedClient } from '../../axios/axios-api-authenticated-client';
import { axiosApiClient } from '../../axios/axios-api-guest-client';

function withLoginDefaults<T>(body: T): T {
  return {
    ...body,
    expiryEnabled: true,
  };
}

export const authenticationApiWithGetAccessToken = (getAccessToken: GetAccessToken) => ({
  login: (body: LoginRequest) =>
    axiosApiClient().post<ApiResponse<LoginResponse>>(
      '/login',
      withLoginDefaults<LoginRequest>(body)
    ),
  loginWithFacebook: (accessToken: string) =>
    axiosApiClient().post<ApiResponse<LoginResponse>>(
      '/login-facebook',
      withLoginDefaults<FacebookLoginRequest>({ accessToken } as FacebookLoginRequest)
    ),
  register: (body: RegisterRequest) =>
    axiosApiClient().post<ApiResponse<RegisterResponse>>(
      '/register',
      withLoginDefaults<RegisterRequest>(body)
    ),
  registerWithoutPassword: (body: RegisterWithoutPasswordRequest) =>
    axiosApiClient().post<ApiResponse<RegisterResponse>>(
      '/register-without-password',
      withLoginDefaults<RegisterWithoutPasswordRequest>(body)
    ),
  registerAsElitetutor: (body: RegisterElitetutorRequest) =>
    axiosApiClient().post<ApiResponse<RegisterResponse>>(
      '/register-elitetutor',
      withLoginDefaults<RegisterElitetutorRequest>(body)
    ),
  remindPassword: (body: SendPasswordResetRequest) =>
    axiosApiClient().post('/send-reset-password-email', body),
  setPassword: (body: ResetPasswordRequest) => axiosApiClient().post('/reset-password', body),
  validatePasswordToken: (token: string | null) =>
    axiosApiClient().get<ApiResponse<CheckResetTokenResponse>>('/check-reset-token', {
      params: { token },
    }),
  updateUser: (userId: string, body: UserUpdateRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).patch(`/user/${userId}`, body),
  resendVerificationSms: (body: ResendSmsRequest) =>
    axiosApiClient().post('/user/resend-verification-sms', body),
  activatePhoneNumber: (body: SmsActivationRequest) =>
    axiosApiClient().post('/user/activate-phone-number', body),
  fetchCurrentUser: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<User>>('/user/current'),
  logout: () => axiosApiAuthenticatedClient(getAccessToken).delete('/login'),
  deleteAccount: (userId: string | undefined) =>
    axiosApiAuthenticatedClient(getAccessToken).delete(`user/${userId}`),
  deleteUserVideo: (userId: string | undefined) =>
    axiosApiAuthenticatedClient(getAccessToken).delete(`user/${userId}/video`),
});

export type AuthenticationApi = ReturnType<typeof authenticationApiWithGetAccessToken>;
