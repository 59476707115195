import React, { FC } from 'react';
import { GroupWrap } from 'components/group-wrap/group-wrap';
import { FACEBOOK, LINKEDIN, TIK_TOK, INSTAGRAM } from 'assets/icons/icons';
import { CustomIcon } from 'components/custom-icon/CustomIcon';
import { Lazy } from 'components/lazy/lazy';

const buttons = [
  {
    src: FACEBOOK,
    alt: 'facebook',
    href: 'https://www.facebook.com/tutorspaceDE/',
  },
  {
    src: INSTAGRAM,
    alt: 'instagram',
    href: 'https://www.instagram.com/tutorspace_nachhilfe/',
  },
  {
    src: LINKEDIN,
    alt: 'linkedin',
    href: 'https://www.linkedin.com/company/tutorspace/',
  },
  {
    src: TIK_TOK,
    alt: 'tik tok',
    href: 'https://www.tiktok.com/@tutorspace',
  },
];

export const SocialButtons: FC = () => {
  return (
    <GroupWrap gap={1}>
      {buttons.map(({ src, alt, href }, idx) => (
        <a href={href} key={idx} target={'_blank'} rel={'noreferrer'}>
          <Lazy>
            <CustomIcon src={src} height={32} width={32} alt={alt} loading="lazy" />
          </Lazy>
        </a>
      ))}
    </GroupWrap>
  );
};
