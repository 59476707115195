import { FormErrors } from 'redux-form';
import { isArray } from 'lodash';
import { trimValue } from 'logic/formaters/formaters';
import { formatFileSize } from 'logic/utils/file';
import { translationKeys, translate } from '../translations/translations.service';
import isIban from 'validator/lib/isIBAN';
import isBic from 'validator/lib/isBIC';

export const IS_REQUIRED = translate(translationKeys.validator.form.isRequired);
export const EMAIL_FORMAT_IS_NOT_VALID = translate(
  translationKeys.validator.form.emailFormatIsNotValid
);
export const GENERAL_FORMAT_IS_NOT_VALID = translate(
  translationKeys.validator.form.generalFormatIsNotValid
);
export const SYMBOLS_NOT_VALID = translate(translationKeys.validator.form.generalFormatIsNotValid);
export const COMMENT_WITH_LINK_NOT_VALID = translate(
  translationKeys.validator.form.commentFormatIsNotValid
);
export const PASSWORD_FORMAT_IS_NOT_VALID = translate(
  translationKeys.validator.form.passwordFormatIsNotValid
);
export const PASSWORDS_MUST_MATCH = translate(translationKeys.validator.form.passwordsMustMatch);
export const PASSWORD_TOO_SHORT = translate(translationKeys.validator.form.passwordTooShort);
export const TEXT_MUST_HAVE_AT_LEAST_ONE_LETTER = translate(
  translationKeys.validator.form.textMustHaveAtLeastOneLetter
);
export const TEXT_MUST_HAVE_AT_LEAST_ONE_NUMBER = translate(
  translationKeys.validator.form.textMustHaveAtLeastOneNumber
);
export const getInputTooShortTranslation = (minLength: number) =>
  translate(translationKeys.validator.form.inputTooShort, { minLength });
export const INPUT_TOO_LONG = translate(translationKeys.validator.form.inputTooLong);
export const getFileSizeCannotBeMoreThanTranslation = (maxFileSize: number) =>
  translate(translationKeys.validator.form.fileSizeCannotBeMoreThan, {
    maxFileSize: formatFileSize(maxFileSize, 0),
  });

export const TEXT_IS_TOO_SHORT = (minLength: number) =>
  translate(translationKeys.validator.form.textIsTooShort, { minLength });

export const getMustBeLowerThanTranslation = (maxValue: number) =>
  translate(translationKeys.validator.form.valueMustBeSmallerThan, { maxValue: maxValue + 1 });
export const getMustBeHigherThanTranslation = (minValue: number) =>
  translate(translationKeys.validator.form.valueMustBeHigherThan, { minValue: minValue - 1 });

export const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LINK_REG = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+/;
export const POSTCODE_REG = /^[\d]{4,6}$/;
export const EURO_ALPHA = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+$/u;
export const EURO_ALPHANUMERIC = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+$/u;
export const EURO_ALPHANUMERIC_EXTENDED = /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-/]+$/u;

export const isRequiredValidator = <T extends string | number | object | object[]>(
  value: T | undefined | null,
  customError?: string
) =>
  (!isArray(value) || !!value.length) && (!!value || value === 0)
    ? undefined
    : customError || IS_REQUIRED;

export const isEmailValidator = (email: string | undefined | null) =>
  EMAIL_REG.test((email || '').toLowerCase()) ? undefined : EMAIL_FORMAT_IS_NOT_VALID;

export const isNotLinkValidator = (input: string | undefined) =>
  !LINK_REG.test((input || '').toLowerCase()) ? undefined : COMMENT_WITH_LINK_NOT_VALID;

export const isPostcodeValidator = (postcode: string | undefined | null) =>
  POSTCODE_REG.test(postcode || '') ? undefined : GENERAL_FORMAT_IS_NOT_VALID;

export const isIbanValidator = (iban: string | undefined | null) =>
  isIban(iban || '') ? undefined : GENERAL_FORMAT_IS_NOT_VALID;

export const isBicValidator = (bic: string | undefined | null) =>
  isBic(bic || '') ? undefined : GENERAL_FORMAT_IS_NOT_VALID;

export const IsEuroAlpha = (input: string | undefined | null) =>
  EURO_ALPHA.test(input || '') ? undefined : SYMBOLS_NOT_VALID;

export const IsEuroAlphanumeric = (input: string | undefined | null) =>
  EURO_ALPHANUMERIC.test(input || '') ? undefined : SYMBOLS_NOT_VALID;

export const IsEuroAlphanumericExtended = (input: string | undefined | null) =>
  EURO_ALPHANUMERIC_EXTENDED.test(input || '') ? undefined : SYMBOLS_NOT_VALID;

export const isMinLengthValidator = (minLength: number) => (text: string | undefined) => {
  const trimmedInput = trimValue(text) || '';
  return trimmedInput.length < minLength ? TEXT_IS_TOO_SHORT(minLength) : undefined;
};

export const isOfValidLength = (input = '') => {
  const trimmedInput = trimValue(input);
  if (!trimmedInput) return PASSWORD_TOO_SHORT;

  return trimmedInput.length >= 8 ? undefined : PASSWORD_TOO_SHORT;
};

export const isOfMinLength = (input = '', minLength = 5) => {
  const trimmedInput = trimValue(input);
  if (!trimmedInput) return getInputTooShortTranslation(minLength);

  return trimmedInput.length >= minLength ? undefined : getInputTooShortTranslation(minLength);
};

export const isOfMaxLength = (input = '') => {
  const trimmedInput = trimValue(input);
  if (!trimmedInput) return getInputTooShortTranslation(1);

  return trimmedInput.length <= 1600 ? undefined : INPUT_TOO_LONG;
};

export const areMatching = (input?: string, other?: string): string | undefined =>
  input === other ? undefined : PASSWORDS_MUST_MATCH;

export type ReduxFormValidator<V extends object> = (formValues: V) => FormErrors<V, string>;

export type ReduxFormAsyncValidator<T> = (serverErrors: T, formFieldsKeys?: string[]) => FormErrors;

export const isFileSizeLessOrEqual = (file: File | Blob | undefined, maxFileSize: number) =>
  (file?.size || 0) > maxFileSize ? getFileSizeCannotBeMoreThanTranslation(maxFileSize) : undefined;

export const isMax = (input: number, max: number) =>
  input <= max ? undefined : getMustBeLowerThanTranslation(max);
export const isMin = (input: number, min: number) =>
  input >= min ? undefined : getMustBeHigherThanTranslation(min);

export const hasAtLeastOneLetter = (input?: string) =>
  !input || !/\w*[a-zA-Z]\w*/.test(input) ? TEXT_MUST_HAVE_AT_LEAST_ONE_LETTER : undefined;

export const hasAtLeastOneNumber = (input?: string) =>
  !input || !/\w*[0-9]\w*/.test(input) ? TEXT_MUST_HAVE_AT_LEAST_ONE_NUMBER : undefined;
