import { Button, Modal, Space } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { ModalFuncProps } from 'antd/lib/modal';
import { ModalFunc } from 'antd/lib/modal/confirm';
import React from 'react';
import styled from 'styled-components';

const { warn } = Modal;

let confirmModal: ReturnType<ModalFunc> | undefined = undefined;

export type ModalButton = ButtonProps & { text: string };

export const showConfirmModal = ({
  title,
  content,
  buttons,
  ...rest
}: ModalFuncProps & {
  buttons: ModalButton[];
}) => {
  const handleClick = (handler?: ButtonProps['onClick'], e?: any) => {
    handler && handler(e);
    closeConfirmModal();
  };

  confirmModal = warn({
    title: (
      <ModalTitle>
        <div>{title}</div>
      </ModalTitle>
    ),
    content: (
      <div>
        <p>{content}</p>

        <ButtonsSpace direction="vertical">
          {buttons.map((button, index) => (
            <ModalButton key={index} {...button} onClick={(e) => handleClick(button.onClick, e)}>
              {button.text}
            </ModalButton>
          ))}
        </ButtonsSpace>
      </div>
    ),
    okButtonProps: { hidden: true },
    onCancel: () => closeConfirmModal(),
    ...rest,
  });
};

export const closeConfirmModal = () => {
  if (confirmModal) {
    confirmModal.destroy();
    confirmModal = undefined;
  }
};

export const ButtonsSpace = styled(Space)`
  margin-bottom: -20px;
  width: 100%;
`;

export const ModalButton = styled(Button)`
  width: 100%;
`;

export const ModalTitle = styled.div`
  text-align: center;
`;
