import { CSSProperties, FunctionComponent } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import { colors } from 'src/styles/colors';

export const CONTENT_CONTAINER_WIDTH = 1440;

type BackgroundType = 'teal' | 'white' | 'none' | 'inherit';

interface ContainerProps {
  className?: string;
  inProgress?: boolean;
  progressSpinner?: SpinProps;
  background?: BackgroundType | string;
  style?: CSSProperties;
}
const Background = styled.div<{ bg: BackgroundType | string }>`
  background-color: ${({ bg }) => (bg === 'teal' ? colors.lightTeal : bg)};
`;

const StyledContainer = styled.div`
  ${tw`relative mx-auto overflow-x-hidden xl:overflow-x-visible`};
  max-width: ${CONTENT_CONTAINER_WIDTH}px;
`;

export const ContentContainer: FunctionComponent<ContainerProps> = ({
  className = '',
  children,
  background,
  progressSpinner,
  inProgress = false,
  style,
}) => {
  return (
    <Background bg={background}>
      <Spin spinning={!progressSpinner && inProgress} {...progressSpinner}>
        <StyledContainer className={className} style={style}>
          {children}
        </StyledContainer>
      </Spin>
    </Background>
  );
};
