export enum SortOffersBy {
  Default = 'STANDARD',
  LowestPrice = 'LOWEST_PRICE',
  HighestPrice = 'HIGHEST_PRICE',
  Rating = 'RATING',
  Distance = 'DISTANCE',
  Newest = 'NEWEST',
}

export const SEARCH_SORT_BY_DEFAULT = SortOffersBy.Default;

export const isSortOffersBy = (
  sortOffersBy: SortOffersBy | undefined
): sortOffersBy is SortOffersBy => !!sortOffersBy;
