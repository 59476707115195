import { DEFAULT_LESSON_LENGTH } from 'src/pages-all/offer-form/offer-form.logic';
import { StoreState } from 'app/app.reducers';
import { CreateOfferRequest, User } from 'logic/api-models/api-models';
import { getImageSrc } from 'logic/utils/image';
import { getUserYearOfBirth } from './offer.selectors';
import { MyOfferFormValues } from 'src/pages-all/offer-form/my-offer-form-page/my-offer-form.logic';

export const mapOfferResponseToForm: (
  state: StoreState
) => (user: User | undefined) => MyOfferFormValues = (state) => (user) => {
  const yearOfBirth = getUserYearOfBirth(state);
  const offer = user?.offer;
  const result: MyOfferFormValues = {
    _id: offer?._id,
    subjects: offer?.subjects as MyOfferFormValues['subjects'],
    description: offer?.offerDesc,
    place: offer?.location?.place,
    country: offer?.location?.country,
    postcode: offer?.location?.postcode,
    region: offer?.location?.region,
    address: offer?.location?.address,
    longitude: offer?.exactLocationPoint?.coordinates[0] ?? offer?.lng,
    latitude: offer?.exactLocationPoint?.coordinates[1] ?? offer?.lat,
    photo: { url: user?.imageUrl ? getImageSrc(user?.imageUrl) : undefined },
    price: offer?.priceAtleast,
    level: offer?.teachingLevel,
    location: offer?.tutoringType as MyOfferFormValues['location'],
    yearOfBirth,
    lessonLength: offer?.tutoringTime as MyOfferFormValues['lessonLength'],
  };

  return result;
};

export const mapOfferFormToRequest: (
  formValues: MyOfferFormValues | undefined
) => CreateOfferRequest = (formValues) => {
  const withCoords =
    formValues?.longitude && formValues?.latitude
      ? {
          coords: {
            lng: formValues?.longitude || 0,
            lat: formValues?.latitude || 0,
          },
        }
      : undefined;

  const request: CreateOfferRequest = {
    subjects: formValues?.subjects || [],
    description: formValues?.description || '',
    address: {
      place: formValues?.place || '',
      country: formValues?.country || '',
      postcode: formValues?.postcode || '',
      region: formValues?.region || '',
      address: formValues?.address || '',
    },
    ...withCoords,
    // photo is ignored, because it's handled by updateUserPhotoAndAge
    price: formValues?.price || 0,
    level: formValues?.level || 0,
    location: formValues?.location || [],
    lessonLength: formValues?.lessonLength || DEFAULT_LESSON_LENGTH,
  };

  return request;
};
