import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { authenticationSlice } from 'logic/store/authentication/authentication.slice';
import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { AuthenticationStepKey } from '../store/authentication/authentication.model';
import { modalSlice } from '../store/modal/modal.slice';
import { ErrorHandler } from './error-handler';
import { Dispatch } from 'redux';
import { hasWindow } from 'logic/utils/window';

export const isUnauthenticatedError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.UNAUTHORIZED;

export const unauthenticatedErrorHandler: (dispatch: Dispatch) => ErrorHandler<AxiosError> = (
  dispatch
) => (error) => {
  if (error && isUnauthenticatedError(error)) {
    dispatch(
      authenticationSlice.actions.logout({
        hideNotification: true,
        followupAction: modalSlice.actions.openAuthenticationModal({
          stepKey: AuthenticationStepKey.Login,
          successfulLoginRedirectPath: hasWindow ? window?.location?.pathname : undefined,
        }),
      })
    );
    return true;
  }

  return false;
};
