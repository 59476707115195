import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchFormValues } from 'components/search/search.logic';
import {
  OffersListResponse,
  OfferResponse,
  TutoringRequestResponse,
  TutoringRequestRequest,
  OfferSearchResponseDto,
} from 'logic/api-models/api-models';
import { ObjectByKey } from 'logic/utils/object';
import { ReportOfferReason } from 'logic/api-models/report-offer';
import { MyOfferFormValues } from 'src/pages-all/offer-form/my-offer-form-page/my-offer-form.logic';
import { AlternativeOffersKey } from './offer.model';

export interface OfferState {
  offerSearchResults?: OfferSearchResponseDto;
  offerById?: ObjectByKey<OfferResponse>;
  offerRequests?: TutoringRequestResponse[];
  offerRequestInitValues: Partial<TutoringRequestRequest>;
  alternativeOffersSearchResults?: ObjectByKey<OffersListResponse>;
  eliteOffersSearchResults?: OffersListResponse;
  refugeeOfferSearchResults?: OffersListResponse;
}

const initialState: OfferState = {
  offerRequestInitValues: {},
};

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    reset: (state) => ({
      ...initialState,
      offerSearchResults: state.offerSearchResults,
      offerById: state.offerById,
      alternativeOfferSearchResults: state.alternativeOffersSearchResults,
    }),
    navigateToSearchResults: (
      _state,
      _action: PayloadAction<{ formValues: SearchFormValues }>
    ) => {},
    searchOffers: (
      _state,
      _action: PayloadAction<{ formValues: SearchFormValues | undefined }>
    ) => {},
    setOfferSearchResults: (
      state,
      action: PayloadAction<{ offers: OfferSearchResponseDto | undefined }>
    ) => {
      state.offerSearchResults = action.payload.offers;
    },
    fetchOffer: (_state, _action: PayloadAction<{ offerId: string | undefined }>) => undefined,
    setOffer: (state, action: PayloadAction<{ offer: OfferResponse | undefined }>) => {
      if (!state.offerById) state.offerById = {};
      const { offer } = action.payload;

      if (offer?._id) {
        state.offerById[offer?._id] = offer;
      }
    },
    tryRemoveOffer: (_state, _action: PayloadAction<string | undefined>) => {},
    removeOffer: (_state, _action: PayloadAction<string | undefined>) => {},
    requestOfferImmediately: (
      _state,
      _action: PayloadAction<{ request: Partial<TutoringRequestRequest> }>
    ) => {},
    requestOffer: () => {},
    setOfferRequestInitValues: (
      _state,
      _action: PayloadAction<Partial<TutoringRequestRequest>>
    ) => {
      _state.offerRequestInitValues = {
        ..._state.offerRequestInitValues,
        ..._action.payload,
      };
    },
    clearOfferRequestInitValues: (_state) => {
      _state.offerRequestInitValues = {};
    },
    fetchOfferRequests: () => {},
    setOfferRequests: (
      state,
      action: PayloadAction<{
        offerRequests: TutoringRequestResponse[] | undefined;
      }>
    ) => {
      state.offerRequests = action.payload.offerRequests;
    },
    rejectOfferRequest: (_state, _action: PayloadAction<{ offerRequestId: string }>) => {},
    acceptOfferRequest: (_state, _action: PayloadAction<{ offerRequestId: string }>) => {},
    updateOfferStatus: (
      _state,
      _action: PayloadAction<{ offerId: string; isPublished: boolean }>
    ) => {},
    fetchOfferForEdit: () => {},
    createMyOffer: (
      _state,
      _action: PayloadAction<{ formValues: MyOfferFormValues | undefined }>
    ) => {},
    createOfferElitetutorRegistration: (
      _state,
      _action: PayloadAction<{ formValues: MyOfferFormValues | undefined }>
    ) => {},
    updateMyOffer: (
      _state,
      _action: PayloadAction<{ formValues: MyOfferFormValues | undefined }>
    ) => {},
    reportOffer: (
      _state,
      _action: PayloadAction<{ offerId: string; reason: ReportOfferReason }>
    ) => {},
    rateOffer: (
      _state,
      _action: PayloadAction<{ offerId: string; rating: number; comment: string }>
    ) => {},
    searchAlternativeOffers: (
      _state,
      _action: PayloadAction<{
        alternativeOffersKey: AlternativeOffersKey;
        formValues: SearchFormValues | undefined;
      }>
    ) => {},
    setAlternativeOfferSearchResults: (
      state,
      action: PayloadAction<{
        alternativeOffersKey: AlternativeOffersKey;
        offers: OffersListResponse | undefined;
      }>
    ) => {
      const { alternativeOffersKey: alternativeOfferKey, offers } = action.payload;
      if (!state.alternativeOffersSearchResults) state.alternativeOffersSearchResults = {};
      state.alternativeOffersSearchResults[alternativeOfferKey] = offers;
    },
    setEliteOfferSearchResults: (
      state,
      action: PayloadAction<{ offers: OffersListResponse | undefined }>
    ) => {
      state.eliteOffersSearchResults = action.payload.offers;
    },
    setRefugeeOfferSearchResults: (
      state,
      action: PayloadAction<{ offers: OffersListResponse | undefined }>
    ) => {
      state.refugeeOfferSearchResults = action.payload.offers;
    },
    searchEliteOffers: (
      _state,
      action: PayloadAction<{ formValues: SearchFormValues | undefined }>
    ) => {},
    searchRefugeeOffers: (
      _state,
      action: PayloadAction<{ formValues: SearchFormValues | undefined }>
    ) => {},
    navigateToEliteSearchResults: (
      _state,
      _action: PayloadAction<{ formValues: SearchFormValues }>
    ) => {},
    navigateToRefugeeOnlineSearchResults: (
      _state,
      _action: PayloadAction<{ formValues: SearchFormValues }>
    ) => {},
    navigateToRefugeeLocalSearchResults: (
      _state,
      _action: PayloadAction<{ formValues: SearchFormValues }>
    ) => {},
  },
});
