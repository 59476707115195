import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElitetutorFormValues } from 'src/pages-all/offer-form/elitetutor-offer-form/elitetutor-offer-form.logic';
import {
  BookingForSeekerResponse,
  BookingListResponse,
  CreateBookingResponse,
  CreditTutorResponse,
  ElitepaymentChangeSubscriptionRequest,
  ElitePaymentConfigResponse,
  ElitepaymentCreditPurchaseRequest,
  ElitepaymentCreditPurchaseResponse,
  ElitepaymentSeekerInfoResponse,
  EliteratingResponse,
  EliteseekerCreditEventListResponse,
  ElitetutorCreditEventListResponse,
  GetPersonalizedSubscriptionTokenDataResponse,
  SeekerSubscriptionChangeInfoResponse,
  SubjectApplicationDetailsListResponse,
  SubmitEliteApplicationResponse,
  UpdateElitetutorVacanciesRequest,
  VacancyListResponse,
} from 'logic/api-models/api-models';

import { EliteTutoringBookingFormValues } from 'src/pages-all/booking-form/booking-form.logic';
import {
  LessonTypePreset,
  ViewAs,
} from 'src/pages-all/user/user-profile/up-coming-lessons/UpComingLessons.types';
import { MyPricingFormValues } from 'src/pages-all/user/user-profile/my-pricing/my-pricing.logic';
import { UpdatePayoutInfoFormValues } from 'src/pages-all/user/user-profile/earnings/components/update-payout-info/update-payout-info.logic';
import { SubjectType } from 'models/subject-type';
import { BookingCreditInsufficientInfo } from 'logic/store/elitetutor/elitetutor.model';
import { EarnMoneyFormValues } from 'src/pages-all/user/user-profile/earn-money/earn-money.logic';

export interface ElitetutorState {
  submitEliteApplicationResponseState: SubmitEliteApplicationResponse | null;
  subjectApplicationsResponseState: SubjectApplicationDetailsListResponse | null;
  bookingListResponseState: {
    bookingListResponse: BookingListResponse | null;
    type: LessonTypePreset | null;
    viewAs: ViewAs | null;
    payload: { userID: string; size: number; page: number; preset?: LessonTypePreset } | null;
  };
  vacancyListResponseState: VacancyListResponse | null;
  createBookingResponseState: CreateBookingResponse | null;
  bookingCreditInsufficientInfo: BookingCreditInsufficientInfo | null;
  elitePaymentConfigResponseState: ElitePaymentConfigResponse | null;
  elitePaymentSeekerInfoResponseState: ElitepaymentSeekerInfoResponse | null;
  eliteseekerCreditEventListResponseState: EliteseekerCreditEventListResponse | null;
  elitetutorCreditEventListResponseState: ElitetutorCreditEventListResponse | null;
  creditTutorResponseState: CreditTutorResponse | null;
  cancelBookingResponseState: BookingForSeekerResponse | null;
  seekerSubscriptionChangeInfoPreviewResponseState: SeekerSubscriptionChangeInfoResponse | null;
  elitepaymentCreditPurchaseResponseState: ElitepaymentCreditPurchaseResponse | null;
  eliteratingResponseState: EliteratingResponse[];
  personalizedSubscriptionData: GetPersonalizedSubscriptionTokenDataResponse | null;
}

const initialState: ElitetutorState = {
  submitEliteApplicationResponseState: null,
  subjectApplicationsResponseState: null,
  bookingListResponseState: {
    bookingListResponse: null,
    type: null,
    viewAs: null,
    payload: null,
  },
  vacancyListResponseState: null,
  createBookingResponseState: null,
  bookingCreditInsufficientInfo: null,
  elitePaymentConfigResponseState: null,
  elitePaymentSeekerInfoResponseState: null,
  eliteseekerCreditEventListResponseState: null,
  elitetutorCreditEventListResponseState: null,
  creditTutorResponseState: null,
  cancelBookingResponseState: null,
  seekerSubscriptionChangeInfoPreviewResponseState: null,
  elitepaymentCreditPurchaseResponseState: null,
  eliteratingResponseState: [],
  personalizedSubscriptionData: null,
};

export const elitetutorSlice = createSlice({
  name: 'elitetutor',
  initialState,
  reducers: {
    reset: (_state) => {
      let resetState = { ...initialState };
      resetState.vacancyListResponseState = _state.vacancyListResponseState;
      return resetState;
    },
    applyAsEliteViaForm: (
      _state,
      _action: PayloadAction<{ formValues: ElitetutorFormValues }>
    ) => {},
    applyAsElite: (_state, _action: PayloadAction<{ subjects: SubjectType[] }>) => {},
    getSubjectApplications: (_state, _action: PayloadAction<{ userID: string }>) => {
      _state.subjectApplicationsResponseState = null;
    },
    setSubjectApplications: (
      state,
      action: PayloadAction<{ response: SubjectApplicationDetailsListResponse | undefined }>
    ) => {
      const { response } = action.payload;
      if (response) {
        state.subjectApplicationsResponseState = response;
      }
    },
    setElitetutorState: (
      state,
      action: PayloadAction<{ response: SubmitEliteApplicationResponse | undefined }>
    ) => {
      const { response } = action.payload;
      if (response) {
        state.submitEliteApplicationResponseState = response;
      }
    },
    getElitetutorBookings: (
      _state,
      _action: PayloadAction<{
        userID: string;
        size: number;
        page: number;
        preset?: LessonTypePreset;
      } | null>
    ) => {
      _state.bookingListResponseState.bookingListResponse = null;
    },
    getEliteseekerBookings: (
      _state,
      _action: PayloadAction<{
        userID: string;
        size: number;
        page: number;
        preset?: LessonTypePreset;
      } | null>
    ) => {
      _state.bookingListResponseState.bookingListResponse = null;
    },
    setBookings: (state, action: PayloadAction<{ response: BookingListResponse | undefined }>) => {
      const { response } = action.payload;
      if (response) {
        state.bookingListResponseState.bookingListResponse = response;
      }
    },
    updateAvailability: (
      _state,
      _action: PayloadAction<{
        updateElitetutorVacanciesRequest: UpdateElitetutorVacanciesRequest;
        tutorID: string;
      }>
    ) => {},
    fetchVacancyList: (state, action: PayloadAction<{ tutorID: string }>) => {},
    setVacancyListResponseState: (
      state,
      action: PayloadAction<{ response: VacancyListResponse | undefined }>
    ) => {
      const { response } = action.payload;
      if (response) {
        state.vacancyListResponseState = response;
      }
    },
    resetVacancyListResponseState: (state) => {
      state.vacancyListResponseState = null;
    },
    createBooking: (
      _state,
      _action: PayloadAction<{
        formValues: EliteTutoringBookingFormValues;
        tuteeID: string;
        tutorID: string;
        price: number;
        viewAs?: ViewAs;
      }>
    ) => {},
    setCreateBookingState: (
      state,
      action: PayloadAction<{ response: CreateBookingResponse | undefined }>
    ) => {
      if (action.payload.response) {
        state.createBookingResponseState = action.payload.response;
      }
    },
    setBookingsType: (
      state,
      action: PayloadAction<{
        type: LessonTypePreset | undefined;
        viewAs: ViewAs | undefined;
      }>
    ) => {
      if (action.payload.type) {
        state.bookingListResponseState.type = action.payload.type;
      }
      if (action.payload.viewAs) {
        state.bookingListResponseState.viewAs = action.payload.viewAs;
      }
    },
    setBookingsPayload: (
      state,
      action: PayloadAction<{
        userID: string;
        size: number;
        page: number;
        preset?: LessonTypePreset;
      }>
    ) => {
      if (action.payload) {
        state.bookingListResponseState.payload = action.payload;
      }
    },
    getElitePaymentConfig: (_state) => {},
    setElitePaymentConfig: (
      state,
      action: PayloadAction<{ response: ElitePaymentConfigResponse | undefined }>
    ) => {
      if (action.payload.response) {
        state.elitePaymentConfigResponseState = action.payload.response;
      }
    },
    createSubscriptionIntent: (
      _state,
      _action: PayloadAction<{ formValues: MyPricingFormValues }>
    ) => {},
    createSubscriptionIntentWithToken: (
      _state,
      _action: PayloadAction<{
        formValues: MyPricingFormValues;
        token: string;
        couponCode?: string;
      }>
    ) => {},
    getElitePaymentSeekerInfo: (_state) => {},
    setElitePaymentSeekerInfo: (
      _state,
      action: PayloadAction<{ response: ElitepaymentSeekerInfoResponse | undefined }>
    ) => {
      if (action.payload.response) {
        _state.elitePaymentSeekerInfoResponseState = action.payload.response;
      }
    },
    getEliteseekerCreditEvents: (
      _state,
      _action: PayloadAction<{ userID: string; size: number; page: number }>
    ) => {},
    setEliteseekerCreditEvents: (
      _state,
      action: PayloadAction<{ response: EliteseekerCreditEventListResponse | undefined }>
    ) => {
      if (action.payload.response) {
        _state.eliteseekerCreditEventListResponseState = action.payload.response;
      }
    },
    cancelBooking: (
      _state,
      action: PayloadAction<{ seekerID: string; bookingID: string; userID: string }>
    ) => {},
    setCanceledBooking: (
      _state,
      action: PayloadAction<{ response: BookingForSeekerResponse | undefined | null }>
    ) => {
      if (action.payload.response || action.payload.response === null) {
        _state.cancelBookingResponseState = action.payload.response;
      }
    },
    getElitetutorCreditEvents: (
      _state,
      _action: PayloadAction<{ userID: string; size: number; page: number }>
    ) => {},
    setElitetutorCreditEvents: (
      _state,
      action: PayloadAction<{ response: ElitetutorCreditEventListResponse | undefined }>
    ) => {
      if (action.payload.response) {
        _state.elitetutorCreditEventListResponseState = action.payload.response;
      }
    },
    updatePayoutInfoInitialize: () => {},
    updatePayoutInfo: (_state, _action: PayloadAction<UpdatePayoutInfoFormValues>) => {},
    deletePayoutInfo: (_state) => {},
    getCurrentCreditTutor: (_state, _action: PayloadAction<{ userID: string }>) => {},
    setCreditTutor: (
      _state,
      action: PayloadAction<{ response: CreditTutorResponse | undefined }>
    ) => {
      if (action.payload.response) {
        _state.creditTutorResponseState = action.payload.response;
      }
    },
    requestManualPayout: (_state) => {},
    subscriptionChangePreview: (
      _state,
      _action: PayloadAction<ElitepaymentChangeSubscriptionRequest>
    ) => {},
    setSubscriptionChangePreview: (
      state,
      action: PayloadAction<{ response?: SeekerSubscriptionChangeInfoResponse }>
    ) => {
      if (action.payload.response) {
        state.seekerSubscriptionChangeInfoPreviewResponseState = action.payload.response;
      } else {
        state.seekerSubscriptionChangeInfoPreviewResponseState = null;
      }
    },
    subscriptionChange: (
      _state,
      _action: PayloadAction<ElitepaymentChangeSubscriptionRequest>
    ) => {},
    setPersonalizedSubscriptionTokenData: (
      state,
      action: PayloadAction<{ response: GetPersonalizedSubscriptionTokenDataResponse }>
    ) => {
      if (action.payload.response) {
        state.personalizedSubscriptionData = action.payload.response;
      }
    },
    getPersonalizedSubscriptionTokenData: (_state, _action: PayloadAction<{ token: string }>) => {},
    creditPurchase: (_state, _action: PayloadAction<ElitepaymentCreditPurchaseRequest>) => {},
    setCreditPurchase: (
      state,
      action: PayloadAction<{ response?: ElitepaymentCreditPurchaseResponse }>
    ) => {
      if (action.payload.response) {
        state.elitepaymentCreditPurchaseResponseState = action.payload.response;
      } else {
        state.elitepaymentCreditPurchaseResponseState = null;
      }
    },
    promptCreditPurchase: (_state, _action: PayloadAction<{ seekerId: string }>) => {},
    setBookingCreditInsufficientInfo: (
      state,
      action: PayloadAction<BookingCreditInsufficientInfo>
    ) => {
      state.bookingCreditInsufficientInfo = action.payload;
    },
    setEliteratings: (
      state,
      action: PayloadAction<{ response: EliteratingResponse[] | undefined }>
    ) => {
      const { response } = action.payload;
      if (response) {
        state.eliteratingResponseState = response;
      }
    },
    getEliteratings: (_state, _action: PayloadAction) => {},
    uploadEliterating: (_state, _action: PayloadAction<{ formValues: EarnMoneyFormValues }>) => {},
  },
});
