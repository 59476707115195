import { combineEpics } from 'redux-observable';
import { filter, ignoreElements, tap } from 'rxjs/operators';
import { AnalyticsEvent, triggerAnalyticsEvent } from 'logic/analytics/analytics';
import { modalSlice } from './modal.slice';
import { RootEpic } from 'app/app.epics.type';

const openUserContact$: RootEpic = (action$) =>
  action$.pipe(
    filter(modalSlice.actions.openUserContact.match),
    tap(() => {
      triggerAnalyticsEvent(AnalyticsEvent.SeekerHasOpenedTutorsContactDetails);
    }),
    ignoreElements()
  );

export const modalEpics$ = combineEpics(openUserContact$);
