import { omit, pick, map, isArray, isString, reduce } from 'lodash';
import { isObject } from '../../utils/object';
import { clearEmptyFields } from '../../validators/utils';
import {
  BadRequestValidationFieldMessages,
  BadRequestValidationMessages,
  MapServerErrorsToReduxFormErrors,
} from './bad-request-form.model';

const mapServerErrorArray = (
  errors: BadRequestValidationFieldMessages | undefined
): string | undefined =>
  !!errors && isArray(errors) && errors.length > 0 && isString(errors[0]) ? errors[0] : undefined;

export const mapServerErrorObject = (validationMessages: BadRequestValidationMessages): any => {
  if (
    isString(validationMessages) ||
    isArray(validationMessages) ||
    !isObject(validationMessages)
  ) {
    return {};
  }

  return clearEmptyFields(
    reduce(
      validationMessages || {},
      (result, value, key) => ({
        ...result,
        [key]: mapServerErrorArray(value),
      }),
      {}
    )
  );
};

export const errorFieldAndValuesToString = (obj: object | undefined) =>
  map(obj, (value, key) => `${key}: ${value}`).join(', ');

export const mapServerErrorsToReduxFormErrors: MapServerErrorsToReduxFormErrors = (
  validationMessages,
  formFields = []
) => {
  const allErrors = mapServerErrorObject(validationMessages || {});
  const errorInsideForm = pick(allErrors, formFields);
  const errorOutsideForm = omit(allErrors, formFields);
  const _error = errorFieldAndValuesToString(errorOutsideForm);

  const result = {
    ...errorInsideForm,
    _error,
  };

  return clearEmptyFields(result);
};
