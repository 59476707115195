import * as React from 'react';
import { JsonLdHelmet } from 'components/helmets/JsonLdHelmet';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { REVIEW_PLATFORM_STARS, REVIEW_PLATFORM_VERIFIED } from 'assets/icons/icons';
import { colors } from 'src/styles/colors';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';

export const ReviewReferences = () => {
  const schemaOrgJsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: 'Online Nachhilfe',
    image: '',
    brand: 'TutorSpace',
    aggregateRating: {
      '@type': 'AggregateRating',
      bestRating: '5',
      worstRating: '1',
      // remember to change the translation object `promotionCertified` when changing `ratingValue` or `ratingCount`
      ratingValue: '4.8',
      ratingCount: '206',
    },
  };

  return (
    <div>
      <JsonLdHelmet payload={schemaOrgJsonLd} />
      <ReviewReference />
    </div>
  );
};

// common project components are not used since this component should seem like an external component
const ReviewReference = () => {
  const { minTablet } = useBreakpoints();

  return (
    <div style={{ fontFamily: 'Arial', fontSize: 16 }}>
      <span style={{ color: 'gray' }}>
        {`${translate(translationKeys.homepage.promotionCertified.reviews)} ${translate(
          translationKeys.homepage.promotionCertified.numberOfReviews
        )}\xa0\xa0•\xa0\xa0${translate(
          translationKeys.homepage.promotionCertified.reviewRatingInWords
        )}`}
      </span>
      <div
        style={{
          marginTop: '0.25rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={REVIEW_PLATFORM_STARS}
          alt="stars"
          style={{ width: minTablet ? '192px' : '128px' }}
        />
        <span style={{ color: 'gray' }}>
          {`\xa0\xa0${translate(translationKeys.homepage.promotionCertified.averageRating)}`}
        </span>
      </div>
      <div
        style={{
          marginTop: '0.5rem',
          backgroundColor: colors.reviewVerified,
          padding: '0.25rem',
          borderRadius: '2px',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          gap: '0.25rem',
          width: 'fit-content',
        }}
      >
        <img src={REVIEW_PLATFORM_VERIFIED} alt="stars" />
        <div style={{ display: 'flex' }}>
          <span style={{ fontWeight: 700, fontSize: 10 }}>
            {translate(translationKeys.homepage.promotionCertified.verifiedCompany)}
          </span>
        </div>
      </div>
    </div>
  );
};
