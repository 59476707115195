import { AxiosError } from 'axios';
import { logError } from '../log/log.logic';
import { translate, translationKeys } from '../translations/translations.service';
import { ErrorHandler } from './error-handler';
import { isUnauthenticatedError } from './unauthenticated.error';

export const wrongCredentialsErrorHandler: ErrorHandler<AxiosError> = (error) => {
  if (error && isUnauthenticatedError(error)) {
    logError(translate(translationKeys.errors.incorrectCredentials));
    return true;
  }

  return false;
};
