import styled from 'styled-components';
import React, { CSSProperties, FC } from 'react';
import { Text } from 'components/text/Text';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';
import { Typography } from 'antd';
import { colors } from 'src/styles/colors';
import { TITLE_LINE_HEIGHT } from 'src/pages-all/home/home.page.const';

export const SubSectionHeadlineWrapper = styled.div<{
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  center?: boolean;
  noMargin?: boolean;
  fullWidth?: boolean;
}>`
  text-align: ${({ tablet }) => (tablet ? '' : 'center')};
  margin: ${({ desktop, mobile, tablet, center, noMargin }) =>
    noMargin
      ? '0px'
      : center
      ? '80px auto 0 auto'
      : mobile
      ? '80px 0 0 0'
      : desktop
      ? '80px 100px 0 100px'
      : tablet
      ? '80px 70px 0 70px'
      : '80px auto 0 auto'};
  width: ${({ mobile, fullWidth }) => (fullWidth ? '' : mobile ? '' : '80%')};
`;

type Props = {
  headline?: string;
  center?: boolean;
  reverseColor?: boolean;
  noLineBreak?: boolean;
  style?: CSSProperties;
  noMargin?: boolean;
  fullWidth?: boolean;
  noHeadlineColor?: boolean;
  level?: 1 | 2 | 3;
};

export const SubSectionHeadline: FC<Props> = ({
  headline,
  center,
  reverseColor,
  children,
  noLineBreak,
  style,
  noMargin,
  fullWidth,
  noHeadlineColor,
  level,
}) => {
  const { maxMobileLarge, minTablet, minDesktop } = useBreakpoints();
  const { Title } = Typography;

  const fontSize = minTablet ? 38 : 26;
  const fontWeight = 700;
  const display = noLineBreak ? '' : 'block';
  const textAlign = center || !minTablet ? 'center' : 'left';

  const getColor = (i: number, variant: 'Text' | 'other') => {
    const black = variant === 'Text' ? 'black' : colors.black;
    const primary = variant === 'Text' ? 'primary' : colors.primary;

    if (noHeadlineColor) return black;
    if (reverseColor) return i === 0 ? primary : black;
    return i === 1 ? primary : black;
  };

  const renderHeadline = () => {
    if (!headline) return null;

    if (level) {
      return (
        <Title
          level={level}
          style={{
            fontSize: fontSize,
            fontWeight: fontWeight,
            display: display,
            textAlign: textAlign,
            lineHeight: TITLE_LINE_HEIGHT,
          }}
        >
          {headline.split('\n').map((line, i, array) => (
            <span key={i} style={{ color: getColor(i, 'other') }}>
              {line}
              {i !== array.length - 1 && <br />}
            </span>
          ))}
        </Title>
      );
    } else {
      return headline.split('\n').map((line, i) => {
        return (
          <Text
            key={i}
            size={fontSize}
            fontWeight={fontWeight}
            color={getColor(i, 'Text')}
            style={{
              display: display,
              textAlign: textAlign,
            }}
          >
            {line}
          </Text>
        );
      });
    }
  };

  return (
    <SubSectionHeadlineWrapper
      center={center}
      tablet={minTablet}
      mobile={maxMobileLarge}
      desktop={minDesktop}
      style={style}
      noMargin={noMargin}
      fullWidth={fullWidth}
    >
      {renderHeadline()}
      {children}
    </SubSectionHeadlineWrapper>
  );
};
