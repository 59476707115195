import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LogState {}

const initialState: LogState = {};

export const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    logSuccess: (_state, _action: PayloadAction<{ message: string }>) => {},
    logError: (_state, _action: PayloadAction<{ message: string }>) => {},
  },
});
