import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

export const JsonLdHelmet: FC<{
  payload: object | string;
}> = ({ payload }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {typeof payload === 'string' ? payload : JSON.stringify(payload)}
      </script>
    </Helmet>
  );
};
