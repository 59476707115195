import i18n, { Scope, TranslateOptions } from 'i18n-js';
import moment from 'moment';
import { reduce } from 'lodash';
import { isObject } from '../utils/object';
import { de } from './de';
import 'moment/locale/de';
import parser from 'html-react-parser';

i18n.fallbacks = true;
i18n.translations = { de };
i18n.locale = 'de';
moment.locale('de');

export const translate = (scope: Scope, options?: TranslateOptions) => {
  return i18n.t(scope, options);
};

export const translateWithParser = (scope: Scope, options?: TranslateOptions) => {
  return parser(i18n.t(scope, options));
};

export enum DateTranslationFormats {
  MonthName = '%B',
  Year = '%Y',
}

export const translateDateTime = (
  format: DateTranslationFormats[],
  date?: Date,
  separator = ' '
) => {
  if (!date) {
    return '';
  }

  return i18n.strftime(date, format.join(separator));
};

export const getTranslationKeys = <T>(translations: T, path = ''): T => {
  if (translations) {
    return reduce(
      translations as any,
      (accumulator: any, value: any, key: string) => {
        const newPath = `${path}${!!path ? '.' : ''}${key}`;
        return {
          ...accumulator,
          [key]: isObject(value) ? getTranslationKeys(value, newPath) : newPath,
        };
      },
      {}
    );
  } else {
    return translations;
  }
};

const translationKeysDe = getTranslationKeys(de);

export const translationKeys = translationKeysDe;
