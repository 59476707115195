import i18n from 'i18n-js';
import { en } from 'logic/translations/en';
import { de } from 'logic/translations/de';
import { ua } from 'logic/translations/ua';
import { I18nConfig } from 'logic/translations/i18n-config';
import { useI18nConfig } from 'logic/translations/use-i18n-config';

export const configureI18n = ({ locale }: I18nConfig) => {
  i18n.reset();
  i18n.locale = locale || 'de';
  i18n.defaultLocale = 'de';
  i18n.fallbacks = true;
  i18n.translations = { de, en, ua };
};

/**
 * Applies to the I18n global the current I18nConfigContext. This is needed
 * (at least) in every component that should update on locale changes.
 *
 */
export const useLocalizedI18nGlobal = () => {
  const [i18nConfig] = useI18nConfig();

  configureI18n(i18nConfig);
};
