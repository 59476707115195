import styled from 'styled-components';
import { colors } from 'styles/colors';

export const FieldWrapper = styled.div`
  margin-top: 20px;

  .ant-form-item-label {
    label {
      font-size: 16px;
      color: ${colors.gray3};
    }
  }
`;
