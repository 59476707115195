import { Store } from 'redux';
import { getFormValues } from 'redux-form';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapFormValues } from './get-form-values.type';

export const mapFormValuesWithGetStoreState: (getState: Store['getState']) => MapFormValues = (
  getState
) => <F>(formName: string) => <T>(source: Observable<T>) =>
  source.pipe(map(() => getFormValues(formName)(getState()) as F));
