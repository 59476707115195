import { FormErrors } from 'redux-form';
import { TutoringTypeUnion } from 'logic/api-models/tutoring-type';
import { clearEmptyFields } from 'logic/validators/utils';
import { isFileSizeLessOrEqual, isRequiredValidator } from 'logic/validators/validators';
import {
  FormPhotoValue,
  FormVideoValue,
  MAX_UPLOAD_IMAGE_SIZE_IN_BYTES,
  MAX_UPLOAD_VIDEO_SIZE_IN_BYTES,
  OfferFormFields,
} from '../offer-form.logic';

export const MY_OFFER_FORM_NAME = 'my-offer-form';
export interface MyOfferFormValues {
  _id?: string;
  [OfferFormFields.subjects]?: number[];
  [OfferFormFields.description]?: string;
  [OfferFormFields.place]?: string;
  [OfferFormFields.country]?: string;
  [OfferFormFields.postcode]?: string;
  [OfferFormFields.region]?: string;
  [OfferFormFields.address]?: string;
  [OfferFormFields.longitude]?: number;
  [OfferFormFields.latitude]?: number;
  [OfferFormFields.photo]?: FormPhotoValue;
  [OfferFormFields.price]?: number;
  [OfferFormFields.level]?: number;
  [OfferFormFields.location]?: TutoringTypeUnion[];
  [OfferFormFields.yearOfBirth]?: number;
  [OfferFormFields.lessonLength]?: 45 | 60;
  [OfferFormFields.video]?: FormVideoValue;
}

export type MyOfferFormValidationErrors = FormErrors<MyOfferFormValues, string>;

export const validateMyOfferForm = (formValues: MyOfferFormValues) => {
  const {
    subjects,
    description,
    address,
    photo,
    price,
    level,
    location,
    yearOfBirth,
    video,
  } = formValues;

  const errors: MyOfferFormValidationErrors = {
    subjects: isRequiredValidator(subjects),
    description: isRequiredValidator(description),
    address: isRequiredValidator(address),
    photo: !!photo?.file
      ? isFileSizeLessOrEqual(photo?.file, MAX_UPLOAD_IMAGE_SIZE_IN_BYTES)
      : undefined,
    price: isRequiredValidator(price),
    level: isRequiredValidator(level),
    location: isRequiredValidator(location),
    yearOfBirth: isRequiredValidator(yearOfBirth),
    video: !!video?.file
      ? isFileSizeLessOrEqual(video?.file, MAX_UPLOAD_VIDEO_SIZE_IN_BYTES)
      : undefined,
  };

  return clearEmptyFields(errors);
};
