import { isProduction } from '../env/envs';
import { logError } from '../log/log.logic';
import { translate, translationKeys } from '../translations/translations.service';
import { ErrorHandler } from './error-handler';

export const defaultErrorHandler: ErrorHandler<Error> = (error) => {
  logError(getErrorMessage(error));

  if (!isProduction()) {
    console.error(error);
  }

  return true;
};

const getErrorMessage = (error: Error | undefined) =>
  error?.message || translate(translationKeys.errors.unknownError);
