import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PdfState {
  offerPdfUrl?: string;
}

const initialState: PdfState = {};

export const pdfSlice = createSlice({
  name: 'pdf',
  initialState,
  reducers: {
    createMyOfferPdf: () => undefined,
    setMyOfferPdfUrl: (state, action: PayloadAction<{ offerPdfUrl: string | undefined }>) => {
      state.offerPdfUrl = action.payload.offerPdfUrl;
    },
  },
});
