import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import { isRequiredValidator } from '../../../../logic/validators/validators';

export const LOGIN_FROM_NAME = 'login';

export interface LoginFormValues {
  email?: string;
  password?: string;
}

export type LoginFormValidationErrors = FormErrors<LoginFormValues, any>;

export const validateLoginForm = (formValues: LoginFormValues) => {
  const { email, password } = formValues;

  const errors: LoginFormValidationErrors = {
    email: isRequiredValidator(email),
    password: isRequiredValidator(password),
  };

  return clearEmptyFields(errors);
};
