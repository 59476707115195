import React, { useEffect } from 'react';
import { Layout, Spin } from 'antd';
import { history } from 'app/app.route.history';
import { scrollToId, ScrollToTop } from 'components/scroll-to-top/ScrollToTop';
import { authenticationSlice } from '../../logic/store/authentication/authentication.slice';
import { useInProgress } from '../../logic/store/tracker/tracker.hooks';
import { MasterFooter } from './components/master-footer/master-footer';
import { MasterHeader } from './components/master-header/master-header';
import { MainLayout } from './components/main-layout';
import { MainContent } from './components/main-content';
const { Content } = Layout;

interface MasterPageProps {
  inProgress?: boolean;
  topSection?: React.ReactNode;
  pageHeader?: React.ReactNode;
  withFooter?: boolean;
  withAdvert?: boolean;
  topPromotionBar?: React.ReactNode;
  withNavigation?: boolean;
  contentStyle?: React.CSSProperties;
  backgroundColor?: string;
  customFooter?: React.ReactNode;
  customHeader?: React.ReactNode;
}

export const MasterPage: React.FC<MasterPageProps> = ({
  children,
  inProgress,
  topSection,
  pageHeader,
  topPromotionBar,
  withNavigation = true,
  withFooter = true,
  withAdvert = true,
  contentStyle,
  backgroundColor,
  customFooter,
  customHeader,
}) => {
  const inProgressLogout = useInProgress(authenticationSlice.actions.logout);
  const spinning = inProgress || inProgressLogout;

  useEffect(() => {
    return history.listen((location) => {
      if (location.state && location.state.scrollToId) {
        setTimeout(() => scrollToId(`#${location.state.scrollToId}`), 1000); // Dirty hack to scroll
      }
    });
  }, []);

  return (
    <Spin spinning={spinning}>
      <ScrollToTop />
      <Layout>
        {topPromotionBar}
        {withNavigation && <MasterHeader />}
        {customHeader}
        {pageHeader}
        {topSection && <Content>{topSection}</Content>}
        <MainLayout style={{ backgroundColor }}>
          <MainContent style={contentStyle}>{children}</MainContent>
        </MainLayout>
        {!spinning && (
          <>
            {/* hide temporarily until mobile apps are revised */}
            {/* {withAdvert && <GetTheApp />} */}
            <MainLayout style={{ backgroundColor: 'white' }}>
              <MainContent style={contentStyle}>
                {withFooter && <MasterFooter />}
                {customFooter}
              </MainContent>
            </MainLayout>
          </>
        )}
      </Layout>
    </Spin>
  );
};
