import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { logError } from '../log/log.logic';
import { translate, translationKeys } from '../translations/translations.service';
import { ErrorHandler } from './error-handler';

export const isInternalServerError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.INTERNAL_SERVER_ERROR;

export const internalServerErrorHandler: ErrorHandler<AxiosError> = (error) => {
  if (error && isInternalServerError(error)) {
    logError(translate(translationKeys.errors.internalServerError));
    return true;
  }

  return false;
};
