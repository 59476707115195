import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { subjectApi } from './subjects.api';
import { subjectSlice } from './subject.slice';
import { RootEpic } from 'app/app.epics.type';

const getSubjects$: RootEpic = (action$, _, { managed }) =>
  action$.pipe(
    filter(subjectSlice.actions.fetchSubjects.match),
    managed(
      subjectSlice.actions.fetchSubjects,
      switchMap(() => from(subjectApi.fetchSubjects()))
    ),
    map((response) => response.data.data),
    map((subjects) => subjectSlice.actions.setSubjects({ subjects }))
  );

export const subjectEpic$ = combineEpics(getSubjects$);
