import { Booking, OfferResponse } from 'logic/api-models/api-models';
import { AppRoute } from 'app/app.route.const';
import { SubjectType } from '../../models/subject-type';

export const isElitetutor = (offerResponse?: OfferResponse, pathname?: string) => {
  if (pathname)
    return (
      offerResponse &&
      offerResponse.hasOwnProperty('elitetutor') &&
      pathname.includes(AppRoute.EliteTutorDetails.split('/')[1])
    );
  return offerResponse && offerResponse.hasOwnProperty('elitetutor');
};

export const isRefugeeTutor = (offerResponse?: OfferResponse, pathname?: string) => {
  if (pathname)
    return (
      offerResponse &&
      offerResponse.subjects?.includes(SubjectType.GermanForRefugees) &&
      pathname.includes(AppRoute.RefugeeTutorDetails.split('/')[1])
    );
  return offerResponse && offerResponse.subjects?.includes(SubjectType.GermanForRefugees);
};

export const isTrialableOffer = (offerResponse?: OfferResponse) => {
  return offerResponse?.elitetutor?.price === null || offerResponse?.elitetutor?.price === 0;
};

export const isTrialBooking = (booking?: Booking) => {
  return booking?.details?.price === null || booking?.details?.price === 0;
};
