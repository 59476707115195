import { epic$ } from 'app/app.epics';
import { RootEpic } from 'app/app.epics.type';

export const loadEpics = (epics: RootEpic[]) => epics.forEach(loadEpic);

export const loadEpic = (epic: RootEpic) => {
  if (wasNotYetLoaded(epic)) {
    loadedEpics.push(epic);
    epic$.next(epic);
  }
};

const wasNotYetLoaded = (epic: RootEpic) => loadedEpics.indexOf(epic) === -1;

const loadedEpics: RootEpic[] = [];
