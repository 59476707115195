import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetRecordingResponse, RoomListResponse } from 'logic/api-models/api-models';

export interface ConferenceState {
  socialCallRoomList: RoomListResponse;
  introductionCallRoomList: RoomListResponse;
  recordingResponse: GetRecordingResponse | null;
}

const initialState: ConferenceState = {
  socialCallRoomList: {},
  introductionCallRoomList: {},
  recordingResponse: null,
};

export const conferenceSlice = createSlice({
  name: 'conference',
  initialState,
  reducers: {
    reset: (state) => initialState,
    fetchSocialCalls: () => {},
    setSocialCalls: (
      state,
      action: PayloadAction<{ socialCalls: RoomListResponse | undefined }>
    ) => {
      if (action?.payload) {
        state.socialCallRoomList = action.payload.socialCalls || {};
      }
    },
    fetchIntroductionCalls: () => {},
    setIntroductionCalls: (
      state,
      action: PayloadAction<{ introductionCalls: RoomListResponse | undefined }>
    ) => {
      if (action?.payload) {
        state.introductionCallRoomList = action.payload.introductionCalls || {};
      }
    },
    joinCall: (state, action: PayloadAction<string>) => {},
    createAndJoinTestCall: () => {},
    getRecording: (state, action: PayloadAction<string>) => {
      state.recordingResponse = null;
    },
    setRecordingResponse: (state, action: PayloadAction<{ response?: GetRecordingResponse }>) => {
      if (action?.payload?.response) {
        state.recordingResponse = action.payload.response;
      }
    },
  },
});
