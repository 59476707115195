import { AxiosError } from 'axios';
import { logError } from '../log/log.logic';
import { translate, translationKeys } from '../translations/translations.service';
import { isBadRequestError } from './bad-request.error';
import { ErrorHandler } from './error-handler';

export const isIncorrectEmailError = (error: AxiosError) =>
  isBadRequestError(error) && error.response?.data.error === 'EMAIL_NOT_FOUND';

export const incorrectEmailErrorHandler: ErrorHandler<AxiosError> = (error) => {
  if (error && isBadRequestError(error)) {
    logError(translate(translationKeys.errors.incorrectEmail));
    return true;
  }

  return false;
};
