import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { isProduction } from 'logic/env/envs';
import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { logError } from '../log/log.logic';
import { getAxiosErrorMessage } from './api-error.logic';
import { ErrorHandler } from './error-handler';

export const isBadRequestError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.BAD_REQUEST;

export const badRequestErrorDefaultHandler: ErrorHandler<AxiosError> = (error) => {
  if (error && isBadRequestError(error)) {
    logError(getAxiosErrorMessage(error));

    if (!isProduction()) {
      console.error(error);
    }

    return true;
  }

  return false;
};
