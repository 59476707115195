import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { modalSlice } from '../../modal/modal.slice';
import { AuthenticationStepKey } from '../authentication.model';
import { getIsAuthenticated } from '../authentication.selectors';
import { Dispatch, Store } from 'redux';
import { hasWindow } from 'logic/utils/window';

export const ofIsAuthenticatedWithDispatch = (dispatch: Dispatch, getState: Store['getState']) => (
  shouldAuthenticate = true
) => <T>(source: Observable<T>) =>
  source.pipe(
    filter(() => {
      const isAuthenticated = getIsAuthenticated(getState());

      if (!isAuthenticated && shouldAuthenticate) {
        dispatch(
          modalSlice.actions.openAuthenticationModal({
            stepKey: AuthenticationStepKey.RegisterWithEmail,
            successfulLoginRedirectPath: hasWindow ? window?.location?.pathname : undefined,
          })
        );
        return false;
      } else {
        return true;
      }
    })
  );
