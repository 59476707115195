import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { logError } from 'logic/log/log.logic';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { ErrorHandler } from './error-handler';

interface ErrorData {
  error?: string;
  message?: string;
}

export const isOfferAlreadyReported = (error: AxiosError<ErrorData>) =>
  getApiErrorStatusCode(error) === StatusCodes.BAD_REQUEST;

export const offerAlreadyReportedError: ErrorHandler<AxiosError<ErrorData>> = (error) => {
  if (error && isOfferAlreadyReported(error)) {
    logError(translate(translationKeys.errors.offerAlreadyReported));
    return true;
  }

  return false;
};
