import { SelectOption } from '../../../components/form-inputs/select-option/select-option';

export enum MapBoxPlace {
  Country = 'country',
  Region = 'region',
  City = 'place',
  Street = 'address',
  Postcode = 'postcode',
  Locality = 'locality',
}

export interface MapBoxContextSelectOption {
  [MapBoxPlace.Country]?: MapBoxContext;
  [MapBoxPlace.Region]?: MapBoxContext;
  [MapBoxPlace.City]?: MapBoxContext;
  [MapBoxPlace.Street]?: MapBoxContext;
  [MapBoxPlace.Postcode]?: MapBoxContext;
  [MapBoxPlace.Locality]?: MapBoxContext;
}

export interface MapBoxPlacesResponse {
  features?: MapBoxPlacesFeature[];
}

export interface MapBoxPlacesFeature {
  id?: string;
  address?: string;
  context?: MapBoxContext[];
  place_name?: string;
  place_type?: string[];
  text_de?: string;
  geometry?: MapBoxGeometry;
}

export interface MapBoxGeometry {
  type: MapBoxGeometryType;
  coordinates?: number[];
}

export enum MapBoxGeometryType {
  Point = 'Point',
}
export interface MapBoxContext {
  id?: string;
  text?: string;
  short_code?: string;
}

export interface PlaceSelectOptionValue {
  id?: string;
  labelShort?: string;
  labelLong?: string;
  longitude?: number;
  latitude?: number;
  context?: MapBoxContextSelectOption;
}

export type PlaceSelectOption = SelectOption<
  PlaceSelectOptionValue,
  string,
  MapBoxContextSelectOption
>;
