import { StoreState } from '../../../app/app.reducers';
import { getAuthenticatedUser } from '../authentication/authentication.selectors';
import { AlternativeOffersKey } from './offer.model';

export const getOfferSearchResults = (state: StoreState) => state.offer.offerSearchResults;

export const getAlternativeOffersSearchResults = (alternativeOffersKey: AlternativeOffersKey) => (
  state: StoreState
) => state.offer.alternativeOffersSearchResults?.[alternativeOffersKey];

export const getOffer = (offerId: string | undefined) => (state: StoreState) =>
  (offerId && state.offer?.offerById && state.offer.offerById[offerId]) || undefined;

export const getOfferRequests = (state: StoreState) => state.offer?.offerRequests;

export const getUserYearOfBirth = (state: StoreState) => getAuthenticatedUser(state)?.yearOfBirth;

export const getEliteOfferSearchResults = (state: StoreState) =>
  state.offer.eliteOffersSearchResults;

export const getRefugeeOfferSearchResults = (state: StoreState) =>
  state.offer.refugeeOfferSearchResults;
