import { StoreState } from '../../../app/app.reducers';
import { TrackStatus } from './tracker.slice';

export const getInProgress = (trackName: string) => (state: StoreState) =>
  state.tracker[trackName]?.status === TrackStatus.InProgress;

export const getAnyInProgress = (trackNames: string[] | undefined) => (state: StoreState) =>
  !!trackNames?.find((trackName) => getInProgress(trackName)(state));

export const getSucceeded = (trackName: string) => (state: StoreState) =>
  state.tracker[trackName]?.status === TrackStatus.Success;

export const getFailed = (trackName: string) => (state: StoreState) =>
  state.tracker[trackName]?.status === TrackStatus.Failure;
