import { JoinRoomResponse } from 'logic/api-models/api-models';
import { translate, translationKeys } from 'logic/translations/translations.service';
import moment from 'moment';

export const generateJoinBBBError = (response?: JoinRoomResponse) => {
  const earliestJoin = response?.earliestJoin;
  const latestJoin = response?.latestJoin;

  if (!earliestJoin || !latestJoin) return translate(translationKeys.errors.contactSupport);
  if (moment().isAfter(moment(latestJoin))) return translate(translationKeys.errors.noBBBJoinURLClosed);

  const earliestJoinMoment = moment(earliestJoin);
  return translate(translationKeys.errors.noBBBJoinURL, { date: earliestJoinMoment.format('DD.MM.'), time: earliestJoinMoment.format('HH:mm') });
};
