import { AxiosError } from 'axios';
import { getApiErrorStatusCode } from 'logic/axios/axios-api-error';
import { StatusCodes } from 'http-status-codes';

export const isForbiddenError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.FORBIDDEN;

export const isConflictError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.CONFLICT;

export const isUnsupportedMediaTypeError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.UNSUPPORTED_MEDIA_TYPE;
