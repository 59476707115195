import React, { useState } from 'react';
import './premium-header.styles.less';
import { Button, Layout, Menu } from 'antd';
import { Link } from 'gatsby';
import { LOGO, LOGO_PREMIUM } from 'assets/icons/icons';
import { translate, translationKeys } from 'logic/translations/translations.service';
import styled from 'styled-components';
import { scrollToId } from 'components/scroll-to-top/ScrollToTop';
import RightMenu from 'src/pages-all/master-page/components/right-menu/right-menu';
import { useIsDesktop } from 'logic/hooks/use-is-tablet';
import { MenuOutlined } from '@ant-design/icons';
import { StyledDropdown } from 'src/pages-all/master-page/components/master-header/master-header';
import { Text } from 'components/text/Text';

const { Header } = Layout;

export const Profile = styled.div`
  padding-left: 20px;
  .logo {
    width: 32px;
    height: 32px;
  }
`;

export const PremiumHeader: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [current, setCurrent] = useState<string>('');

  const PremiumNavigation = () => (
    <div>
      <Menu
        onClick={(e) => setCurrent(e.key.toString())}
        selectedKeys={[current]}
        mode={isDesktop ? 'horizontal' : 'vertical'}
        style={{ paddingLeft: '12px', width: isDesktop ? '' : '200px' }}
      >
        <Menu.Item>
          <div onClick={() => scrollToId('#unsere-premiumtutoren')}>
            <Text style={{ display: 'block' }}>
              {translate(translationKeys.premiumHeader.menuItems.teacher)}
            </Text>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => scrollToId('#pricing')}>
            <Text style={{ display: 'block' }}>
              {translate(translationKeys.premiumHeader.menuItems.pricing)}
            </Text>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={() => scrollToId('#reviews')}>
            <Text style={{ display: 'block' }}>
              {translate(translationKeys.premiumHeader.menuItems.rating)}
            </Text>
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Header className="premium-header noselect">
      <div className="header-container">
        <div className="left-menu-content">
          {!isDesktop && (
            <Link to="/">
              <img className="logo" src={LOGO} alt="logo" loading="lazy" />
            </Link>
          )}
          {isDesktop && (
            <Link to="/">
              <img
                className="logo-with-text"
                src={LOGO_PREMIUM}
                alt="logo"
                loading="lazy"
              />
            </Link>
          )}
          {isDesktop && <PremiumNavigation />}
          {!isDesktop && (
            <Profile>
              <div className="right-menu-content">
                <RightMenu />
              </div>
            </Profile>
          )}
        </div>
        {!isDesktop && (
          <StyledDropdown overlay={<PremiumNavigation />} trigger={['hover', 'click']}>
            <Button>
              <MenuOutlined />
            </Button>
          </StyledDropdown>
        )}
        {isDesktop && (
          <Profile>
            <div className="right-menu-content">
              <RightMenu/>
            </div>
          </Profile>)
        }
      </div>
    </Header>
  );
};
