import { combineEpics } from 'redux-observable';
import { filter, ignoreElements, map, tap } from 'rxjs/operators';
import { requestTutoringSlice } from './request-tutoring.slice';
import { TutoringType } from 'logic/api-models/tutoring-type';
import { RequestTutoringSteps } from 'logic/store/offer/offer.model';
import { offerSlice } from 'logic/store/offer/offer.slice';
import { modalSlice } from 'logic/store/modal';
import { RootEpic } from 'app/app.epics.type';
import { SubjectType } from 'models/subject-type';
import { TutoringRequestRequest } from 'logic/api-models/api-models';
import { TutoringRequestTeachingLevel } from 'logic/api-models/teaching-level';

const initRequestTutoringFlow$: RootEpic = (action$, _, { dispatch }) =>
  action$.pipe(
    filter(requestTutoringSlice.actions.initRequestTutoringFlow.match),
    tap(({ payload: { offer, isRefugeeTutor } }) => {
      const { tutoringType, subjects } = offer;
      const definedSubjects = subjects && (subjects?.filter(Boolean) as number[] | undefined);
      const definedTutoringTypes =
        tutoringType && (tutoringType?.filter(Boolean) as TutoringType[] | undefined);

      const hasOnly1Subject = definedSubjects?.length === 1;
      const hasOnly1TutoringType = definedTutoringTypes?.length === 1;
      const hasSubjectGermanForRefugees = definedSubjects?.includes(SubjectType.GermanForRefugees);

      // define flow for particular offer
      const stepFlow = [
        hasOnly1Subject || (isRefugeeTutor && hasSubjectGermanForRefugees)
          ? undefined
          : RequestTutoringSteps.Subject,
        hasOnly1TutoringType || isRefugeeTutor ? undefined : RequestTutoringSteps.TutoringType,
        isRefugeeTutor ? undefined : RequestTutoringSteps.Level,
        RequestTutoringSteps.Hours, // must be present because the Submit button is here
      ].filter(Boolean) as RequestTutoringSteps[];

      const offerRequest: Partial<TutoringRequestRequest> = {
        offerId: offer._id,
      };

      if (isRefugeeTutor) {
        const refugeeDefaults: Partial<TutoringRequestRequest> = {
          subjects: [SubjectType.GermanForRefugees],
          teachingLocation: definedTutoringTypes,
          requestTeachingLevel: TutoringRequestTeachingLevel.CLASS1,
          tutoringSessionsPerMonth: 2,
        };
        if (!hasSubjectGermanForRefugees) {
          delete refugeeDefaults.subjects;
        }
        Object.assign(offerRequest, refugeeDefaults);
      }

      if (hasOnly1Subject) {
        offerRequest.subjects = definedSubjects?.map(Number) || [];
      }

      if (hasOnly1TutoringType) {
        offerRequest.teachingLocation = definedTutoringTypes;
      }

      if (isRefugeeTutor && stepFlow.length === 1) {
        dispatch(offerSlice.actions.requestOfferImmediately({ request: offerRequest }));
      } else {
        dispatch(requestTutoringSlice.actions.setRequestTutoringFlow(stepFlow));
        dispatch(requestTutoringSlice.actions.setRequestTutoringCurrIdx(0));
        dispatch(offerSlice.actions.setOfferRequestInitValues(offerRequest));
        dispatch(modalSlice.actions.openRequestTutoringModal());
      }
    }),
    ignoreElements()
  );

const moveToPreviousRequestTutoringStep$: RootEpic = (action$, state$): any =>
  action$.pipe(
    filter(requestTutoringSlice.actions.moveToPreviousTutoringRequestFlowStep.match),
    map(() => {
      const step = state$.value.requestTutoring.requestTutoringCurrIdx;

      return requestTutoringSlice.actions.setRequestTutoringCurrIdx(step - 1);
    })
  );

const moveToNextRequestTutoringStep$: RootEpic = (action$, state$): any =>
  action$.pipe(
    filter(requestTutoringSlice.actions.moveToNextTutoringRequestFlowStep.match),
    map(() => {
      const step = state$.value.requestTutoring.requestTutoringCurrIdx;

      return requestTutoringSlice.actions.setRequestTutoringCurrIdx(step + 1);
    })
  );

export const requestTutoringEpics$ = combineEpics(
  initRequestTutoringFlow$,
  moveToPreviousRequestTutoringStep$,
  moveToNextRequestTutoringStep$
);
