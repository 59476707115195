import { FormErrors } from 'redux-form';
import { clearEmptyFields } from '../../../../logic/validators/utils';
import {
  isEmailValidator,
  isPostcodeValidator,
  isRequiredValidator,
} from '../../../../logic/validators/validators';
import { isValidPhoneNumber } from 'logic/phone/phone.validator';

export const REGISTER_AS_ELITETUTOR_FORM_NAME = 'register-as-elitetutor';

export interface RegisterAsElitetutorFormValues {
  firstName?: string;
  lastName?: string;
  emailId?: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
  zipCode?: string;
}

export type RegisterAsElitetutorFormValidationErrors = FormErrors<
  RegisterAsElitetutorFormValues,
  any
>;

export const validateRegisterAsElitetutorForm = (formValues: RegisterAsElitetutorFormValues) => {
  const { firstName, lastName, emailId, phoneNumber, phoneCountryCode, zipCode } = formValues;

  const errors: RegisterAsElitetutorFormValidationErrors = {
    firstName: isRequiredValidator(firstName),
    lastName: isRequiredValidator(lastName),
    emailId: isRequiredValidator(emailId) || isEmailValidator(emailId),
    phoneNumber:
      isRequiredValidator(phoneNumber) || isValidPhoneNumber(phoneNumber, phoneCountryCode),
    zipCode: isRequiredValidator(zipCode) || isPostcodeValidator(zipCode),
  };

  return clearEmptyFields(errors);
};
