import { AppRoute } from 'app/app.route.const';
import { OfferResponse } from 'logic/api-models/api-models';
import { isObject } from 'logic/utils/object';
import { clearEmptyFields } from 'logic/validators/utils';
import qs from 'qs';
import { generatePath } from 'react-router-dom';
import {
  isTutoringSchoolOffer,
  getOfferId,
  getOfferCity,
  getSchoolName,
} from 'logic/store/offer/offer.logic';
import { isElitetutor, isRefugeeTutor } from 'logic/helpers/tutorHelper';

export const openInNewTab = (url: string | undefined) => {
  url && window.open(url);
};

export const parseQueryString = (params: object) => {
  if (!isObject(params)) return '';
  const result = qs.stringify(clearEmptyFields(params));
  return result ? `?${result}` : '';
};

export const getOfferRoutePath = (
  offer: OfferResponse | undefined,
  showElite?: boolean | undefined,
  isRefugeeSearch?: boolean
) => {
  if (isTutoringSchoolOffer(offer))
    return generatePath(AppRoute.SchoolDetails, {
      id: getOfferId(offer),
      city: getOfferCity(offer),
      schoolTitle: getSchoolName(offer),
    });
  if (showElite && isElitetutor(offer)) {
    return generatePath(AppRoute.EliteTutorDetails, { id: getOfferId(offer) });
  }
  if (isRefugeeSearch && isRefugeeTutor(offer)) {
    return generatePath(AppRoute.RefugeeTutorDetails, { id: getOfferId(offer) });
  }
  return generatePath(AppRoute.TutorDetails, { id: getOfferId(offer) });
};
