import { combineEpics } from 'redux-observable';
import { from } from 'rxjs';
import { filter, ignoreElements, switchMap, tap, throttleTime } from 'rxjs/operators';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from '../const';
import { ratingSlice } from './rating.slice';
import { ratingApi } from './rating.api';
import { getApiErrorStatusCode } from 'logic/axios/axios-api-error';
import { RootEpic } from 'app/app.epics.type';
import { featureFlags, ofFeatureFlagEnabled } from 'logic/feature-flag/feature-flag';

const fetchUserRatings$: RootEpic = (action$, _, { dispatch, managed }) =>
  action$.pipe(
    filter(ratingSlice.actions.fetchUserRatings.match),
    ofFeatureFlagEnabled(featureFlags.ratings),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      ratingSlice.actions.fetchUserRatings,
      switchMap((action) => from(ratingApi.fetchRatingsByOfferId(action.payload.offerId || '')))
    ),
    tap((response) => {
      dispatch(ratingSlice.actions.setUserRatings({ userRatings: response.data.data }));
    }),
    ignoreElements()
  );

const fetchFormRating$: RootEpic = (action$, _, { dispatch, managed }) =>
  action$.pipe(
    filter(ratingSlice.actions.fetchRatingForm.match),
    ofFeatureFlagEnabled(featureFlags.ratings),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      ratingSlice.actions.fetchRatingForm,
      switchMap((action) => from(ratingApi.fetchRatingsFormByUniqId(action.payload.uniqId || ''))),
      [handleFetchFormRatingError]
    ),
    tap((response) => {
      dispatch(ratingSlice.actions.setUserRatingsForm({ form: response.data.data }));
    }),
    ignoreElements()
  );

const handleFetchFormRatingError = (error: any) => {
  switch (getApiErrorStatusCode(error)) {
    case 404:
      return true;
    default:
      return false;
  }
};

const rateTutor$: RootEpic = (action$, _, { dispatch, managed }) =>
  action$.pipe(
    filter(ratingSlice.actions.sendRating.match),
    ofFeatureFlagEnabled(featureFlags.ratings),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      ratingSlice.actions.fetchRatingForm,
      switchMap((action) =>
        from(ratingApi.rateTutor(action.payload.uniqId, action.payload.rateObject))
      )
    ),
    tap((response) => {
      dispatch(ratingSlice.actions.setUserRatingsForm({ form: response.data.data }));
    }),
    ignoreElements()
  );

export const ratingEpic$ = combineEpics(fetchUserRatings$, fetchFormRating$, rateTutor$);
