import { DEFAULT_LESSON_LENGTH } from 'src/pages-all/offer-form/offer-form.logic';
import {
  CreateInstituteOfferRequest,
  OfferResponse,
  OpeningHoursRequest,
  OpeningHoursResponse,
} from 'logic/api-models/api-models';
import {
  InstituteOfferFormValues,
  OpeningDayHoursForm,
} from 'src/pages-all/offer-form/institute-offer-form-page/institute-offer-form.logic';
import { isEmpty, map } from 'lodash';
import { WeekDay } from 'logic/api-models/api-models';

export const mapInstituteOfferResponseToForm: (
  offer: OfferResponse | undefined
) => InstituteOfferFormValues = (offer) => {
  const result: InstituteOfferFormValues = {
    _id: offer?._id,
    name: offer?.tutoringSchool?.name,
    description: offer?.offerDesc,
    photos: {
      photos: offer?.tutoringSchool?.images?.map((image) => ({ existingPhoto: image })),
      defaultPhotoIndex: offer?.tutoringSchool?.images?.findIndex((image) => image?.isDefault),
    },
    address: offer?.location?.address,
    postcode: offer?.location?.postcode,
    place: offer?.location?.place,
    region: offer?.location?.region,
    country: offer?.location?.country,
    longitude: offer?.lng,
    latitude: offer?.lat,
    price: offer?.priceAtleast,
    level: offer?.teachingLevel,
    subjects: offer?.subjects as InstituteOfferFormValues['subjects'],
    location: offer?.tutoringType as InstituteOfferFormValues['location'],
    groupSizes: offer?.tutoringSchool?.groupSizes as InstituteOfferFormValues['groupSizes'],
    openingHours: mapOpeningHoursToForm(
      offer?.tutoringSchool?.openingHours as (OpeningHoursResponse | undefined)[] | undefined
    ),
    primaryContact: offer?.tutoringSchool?.primaryContact,
    phoneNumber: offer?.tutoringSchool?.phoneNumber,
    email: offer?.tutoringSchool?.email,
    websiteUrl: offer?.tutoringSchool?.websiteUrl,
    impressumUrl: offer?.tutoringSchool?.impressumUrl,

    lessonLength: offer?.tutoringTime as InstituteOfferFormValues['lessonLength'],
  };

  return result;
};

export const mapInstituteOfferFormToRequest: (
  formValues: InstituteOfferFormValues | undefined
) => CreateInstituteOfferRequest = (formValues) => {
  const withCoords =
    formValues?.longitude && formValues?.latitude
      ? {
          coords: {
            lng: formValues?.longitude || 0,
            lat: formValues?.latitude || 0,
          },
        }
      : undefined;

  const isNewOffer = formValues?._id === undefined;
  const withIsPublished = isNewOffer ? { isPublished: false } : undefined;

  const request: CreateInstituteOfferRequest = {
    name: formValues?.name || null,
    description: formValues?.description || null,
    address: {
      address: formValues?.address || '',
      postcode: formValues?.postcode || '',
      place: formValues?.place || '',
      region: formValues?.region || '',
      country: formValues?.country || '',
    },
    ...withCoords,
    price: formValues?.price || 0,
    level: formValues?.level || 0,
    location: formValues?.location || [],
    subjects: formValues?.subjects || [],

    groupSizes: formValues?.groupSizes || [],
    openingHours: mapOpeningHoursFormToRequest(formValues?.openingHours),

    primaryContact: formValues?.primaryContact || null,
    phoneNumber: formValues?.phoneNumber || null,
    email: formValues?.email || null,
    websiteUrl: formValues?.websiteUrl || null,
    impressumUrl: formValues?.impressumUrl || null,
    imageIds: formValues?.imageIds || [],
    defaultImageId: formValues?.defaultImageId || null,

    lessonLength: formValues?.lessonLength || DEFAULT_LESSON_LENGTH,

    ...withIsPublished,
  };

  return request;
};

const mapOpeningHoursFormToRequest = (
  form: InstituteOfferFormValues['openingHours']
): CreateInstituteOfferRequest['openingHours'] =>
  map(WeekDay).reduce((acc, weekDay) => {
    const morning =
      form && form[weekDay]?.morning?.length === 2 ? form[weekDay]?.morning : undefined;
    const evening =
      form && form[weekDay]?.evening?.length === 2 ? form[weekDay]?.evening : undefined;

    if (morning && morning[0] && morning[1]) {
      acc.push({
        dayOfWeek: weekDay,
        opens: morning[0],
        closes: morning[1],
      });
    }

    if (evening && evening[0] && evening[1]) {
      acc.push({
        dayOfWeek: weekDay,
        opens: evening[0],
        closes: evening[1],
      });
    }
    return acc;
  }, [] as OpeningHoursRequest[]);

const mapOpeningHoursToForm = (
  openingHours: (OpeningHoursResponse | undefined)[] | undefined
): InstituteOfferFormValues['openingHours'] =>
  map(WeekDay).reduce((acc, weekDay) => {
    const dayOpenings = openingHours?.filter((openings) => openings?.dayOfWeek === weekDay);
    const isMorning = dayOpenings && !!dayOpenings[0];
    const isEvening = dayOpenings && !!dayOpenings[1];

    let day: OpeningDayHoursForm = {};
    if (isMorning && dayOpenings !== undefined) {
      day.morning = [dayOpenings[0]?.opens, dayOpenings[0]?.closes];
    }
    if (isEvening && dayOpenings !== undefined) {
      day.evening = [dayOpenings[1]?.opens, dayOpenings[1]?.closes];
    }
    if (!isEmpty(day)) {
      return {
        ...acc,
        [weekDay]: day,
      };
    }

    return acc;
  }, {} as InstituteOfferFormValues['openingHours']);

export const mapOpeningHoursToDisplay = (
  openingHours: (OpeningHoursResponse | undefined)[] | undefined
): InstituteOfferFormValues['openingHours'] =>
  map(WeekDay).reduce((acc, weekDay) => {
    const dayOpenings = openingHours?.filter((openings) => openings?.dayOfWeek === weekDay);
    const isMorning = dayOpenings && !!dayOpenings[0];
    const isEvening = dayOpenings && !!dayOpenings[1];

    let day: OpeningDayHoursForm = {};
    if (isMorning && dayOpenings !== undefined) {
      day.morning = [dayOpenings[0]?.opens, dayOpenings[0]?.closes];
    }
    if (isEvening && dayOpenings !== undefined) {
      day.evening = [dayOpenings[1]?.opens, dayOpenings[1]?.closes];
    }
    if (!isEmpty(day)) {
      return {
        ...acc,
        [weekDay]: day,
      };
    }

    return acc;
  }, {});
