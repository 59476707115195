export enum AuthenticationStepKey {
  Login = 'Login',
  ForgotPassword = 'ForgotPassword',
  RegisterWithEmail = 'RegisterWithEmail',
  ContinueWithFacebookConfirmation = 'ContinueWithFacebookConfirmation',
  RegisterPhone = 'RegisterPhone',
  VerifyPhoneCode = 'VerifyPhoneCode',
  SelectUserType = 'SelectUserType',
}

export interface RegisterWithEmailWithoutPasswordValues {
  firstName?: string;
  lastName?: string;
  emailId?: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
}
