import { getCurrentYear } from 'logic/date-time/date-time';
import { FILE_SIZE_POWER_BASE } from 'logic/utils/file';

export const MAX_DESCRIPTION_LENGTH = 1500;
export const MIN_PRICE_PER_LESSON = 1;
export const MAX_PRICE_PER_LESSON = 99;
export const LESSON_PRICE_CURRENCY = '€';
export const MIN_AGE_YEARS = 1;
export const MAX_AGE_YEARS = 100;
export const MIN_YEAR_OF_BIRTH = getCurrentYear() - MAX_AGE_YEARS;
export const MAX_YEAR_OF_BIRTH = getCurrentYear() - MIN_AGE_YEARS;
export const DEFAULT_LESSON_LENGTH = 45;
export const UPLOAD_IMAGE_ACCEPTED_FORMAT = '.png,.jpeg,.jpg';
export const UPLOAD_VIDEO_ACCEPTED_FORMAT = '.mp4,.avi,.mov,.flv,.mkv,.mpg,.mpeg,.webm,.wmv,video/mp4,video/x-m4v,video/*';
export const MAX_UPLOAD_IMAGE_SIZE_IN_BYTES = 6 * FILE_SIZE_POWER_BASE * FILE_SIZE_POWER_BASE;
export const MAX_UPLOAD_VIDEO_SIZE_IN_BYTES = 120 * FILE_SIZE_POWER_BASE * FILE_SIZE_POWER_BASE;

export interface FormPhotoValue {
  url?: string;
  file?: File;
}

export interface FormVideoValue {
  url?: string;
  file?: File;
}

export enum OfferFormFields {
  subjects = 'subjects',
  description = 'description',
  place = 'place',
  country = 'country',
  postcode = 'postcode',
  region = 'region',
  address = 'address',
  longitude = 'longitude',
  latitude = 'latitude',
  photo = 'photo',
  price = 'price',
  level = 'level',
  location = 'location',
  yearOfBirth = 'yearOfBirth',
  lessonLength = 'lessonLength',

  name = 'name',
  groupSizes = 'groupSizes',
  openingHours = 'openingHours',

  primaryContact = 'primaryContact',
  phoneNumber = 'phoneNumber',
  email = 'email',
  websiteUrl = 'websiteUrl',
  impressumUrl = 'impressumUrl',
  impressumFile = 'impressumFile',

  photos = 'photos',
  imageIds = 'imageIds',
  defaultImageId = 'defaultImageId',

  video = 'video',
}
