import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Feature } from './authorization.feature';
import { Dispatch, Store } from 'redux';
import { isAuthorized } from './of-is-authorized.logic';

export const ofIsAuthorizedWithDispatch = (dispatch: Dispatch, getState: Store['getState']) => (
  feature: Feature | undefined
) => <T>(source: Observable<T>) =>
  source.pipe(filter(() => isAuthorized(dispatch, getState)(feature)));
