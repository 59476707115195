import { badRequestFormErrorHandlerWithStore } from 'logic/error/bad-request-form/bad-request-form.error';
import { managedWithDispatch } from 'logic/operators/managed.operator';
import { ofValidReduxFormWitchStore } from 'logic/redux-form/of-valid-redux-form.operator';
import { getAccessToken } from 'logic/store/authentication/authentication.selectors';
import {
  Action,
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Store,
  StoreEnhancer,
} from 'redux';
import * as reduxLogger from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import { rootEpic } from '../app/app.epics';
import { isProduction } from '../logic/env/envs';
import { WindowWithDevTools } from '../models/window-with-dev-tools';
import { EpicDependencies } from './app.epics.type';
import { reducers, StoreState } from './app.reducers';
import { authenticationApiWithGetAccessToken } from '../logic/store/authentication/authentication.api';
import { offerApiWithGetAccessToken } from '../logic/store/offer/offer.api';
import { paymentsApiWithGetAccessToken } from '../logic/store/payment/payment.api';
import { instituteApiWithGetAccessToken } from '../logic/store/institute/institute.api';
import { fileApiWithGetAccessToken } from '../logic/store/file/file.api';
import { ofIsAuthenticatedWithDispatch } from 'logic/store/authentication/feature/of-is-authenticated.operator';
import { ofIsAuthorizedWithDispatch } from 'logic/store/authentication/feature/of-is-authorized.operator';
import { mapFormValuesWithGetStoreState } from 'logic/redux-form/get-form-values';
import { loadEpics } from 'logic/lazy-load-epic/lazy-load-epic';
import { initializeEpics$ } from 'logic/store/initialize/initialize.epic';
import { authenticationEpics$ } from 'logic/store/authentication/authentication.epic';
import { geolocationEpics$ } from 'logic/store/geolocation/geolocation.epic';
import { subjectEpic$ } from 'logic/store/subject/subject.epic';
import { navigationEpic$ } from 'logic/store/navigation/navigation.epic';
import { offerEpic$ } from 'logic/store/offer/offer.epic';
import { modalEpics$ } from 'logic/store/modal';
import { ratingEpic$ } from 'logic/store/rating/rating.epic';
import { requestTutoringEpics$ } from 'logic/store/request-tutoring/request-tutoring.epic';
import { instituteEpics$ } from 'logic/store/institute/institute.epic';
import { logEpics$ } from 'logic/store/log/log.epic';
import { conferenceApiWithGetAccessToken } from 'logic/store/conference/conference.api';
import { conferenceEpic$ } from 'logic/store/conference/conference.epic';
import { eliteTutorEpic$ } from 'logic/store/elitetutor/elitetutor.epic';
import { elitetutorApiWithGetAccessToken } from 'logic/store/elitetutor/elitetutor.api';

const commonEpics = [
  initializeEpics$,
  authenticationEpics$,
  geolocationEpics$,
  subjectEpic$,
  navigationEpic$,
  offerEpic$,
  modalEpics$,
  ratingEpic$,
  requestTutoringEpics$,
  instituteEpics$,
  logEpics$,
  conferenceEpic$,
  eliteTutorEpic$,
];

const epicMiddleware = createEpicMiddleware<Action, Action, StoreState>();
const middlewares: Middleware[] = [epicMiddleware];
let enhancer: StoreEnhancer;

if (isProduction() || typeof window === 'undefined') {
  enhancer = compose(applyMiddleware(...middlewares));
} else {
  const logger: Middleware = reduxLogger.createLogger({ collapsed: true });
  middlewares.push(logger);
  const reduxDevToolsExtensionCompose = ((window as unknown) as WindowWithDevTools)
    .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = reduxDevToolsExtensionCompose
    ? reduxDevToolsExtensionCompose({
        trace: true,
        traceLimit: 25,
      })
    : compose;
  enhancer = composeEnhancers(applyMiddleware(...middlewares));
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'subject'],
};

const reducer = persistCombineReducers(persistConfig, {
  ...reducers,
});

export const configureStore = () => {
  const store: Store = createStore(reducer, enhancer);
  const persistor = persistStore(store);
  const epicDependencies: EpicDependencies = {
    dispatch: store.dispatch,
    managed: managedWithDispatch(store.dispatch),
    badRequestFormErrorHandler: badRequestFormErrorHandlerWithStore(store),
    ofValidReduxForm: ofValidReduxFormWitchStore(store),
    authenticationApi: authenticationApiWithGetAccessToken(() => getAccessToken(store.getState())),
    offerApi: offerApiWithGetAccessToken(() => getAccessToken(store.getState())),
    paymentApi: paymentsApiWithGetAccessToken(() => getAccessToken(store.getState())),
    instituteApi: instituteApiWithGetAccessToken(() => getAccessToken(store.getState())),
    fileApi: fileApiWithGetAccessToken(() => getAccessToken(store.getState())),
    ofIsAuthenticated: ofIsAuthenticatedWithDispatch(store.dispatch, store.getState),
    ofIsAuthorized: ofIsAuthorizedWithDispatch(store.dispatch, store.getState),
    mapFormValues: mapFormValuesWithGetStoreState(store.getState),
    conferenceApi: conferenceApiWithGetAccessToken(() => getAccessToken(store.getState())),
    elitetutorApi:  elitetutorApiWithGetAccessToken(() => getAccessToken(store.getState())),
  };

  epicMiddleware.run((action$, state$) => rootEpic(action$, state$, epicDependencies));
  loadEpics(commonEpics);

  return {
    persistor,
    store,
  };
};

export const { store, persistor } = configureStore();
