import React, { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

type LazyProps = {};

export const Lazy: FC<LazyProps> = ({ children }) => {
  const { ref, inView } = useInView({
    rootMargin: '500px',
    triggerOnce: true,
  });
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    inView && !shouldShow && setShouldShow(true);
  }, [inView, shouldShow]);

  return <div ref={ref}>{shouldShow && children}</div>;
};
