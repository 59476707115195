import {
  ApiResponse,
  CreateInstituteOfferRequest,
  CreateOfferResponse,
  OfferResponse,
  OffersListResponse,
} from 'logic/api-models/api-models';
import { axiosApiAuthenticatedClient } from 'logic/axios/axios-api-authenticated-client';
import { GetAccessToken } from 'logic/axios/axios-headers-authentication.model';

export const instituteApiWithGetAccessToken = (getAccessToken: GetAccessToken) => ({
  createInstituteOffer: (request: CreateInstituteOfferRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<CreateOfferResponse>>(
      '/school-offer',
      request
    ),
  updateInstituteOffer: (id: string | undefined | null, request: CreateInstituteOfferRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).patch<ApiResponse<CreateOfferResponse>>(
      `/school-offer/${id}`,
      request
    ),
  toggleInstituteOfferActive: (id: string | undefined, request: { isPublished: boolean }) =>
    axiosApiAuthenticatedClient(getAccessToken).patch<ApiResponse<CreateOfferResponse>>(
      `/school-offer/${id}`,
      request
    ),
  fetchInstituteOffers: (userId: string | undefined, page?: number, pageSize?: number) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<OffersListResponse>>(
      `/user/${userId}/offer?size=${pageSize || 20}&page=${page || 1}`
    ),
  fetchInstituteOffer: (offerId: string) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<OfferResponse>>(
      `/school-offer/${offerId}`
    ),
});

export type InstituteApi = ReturnType<typeof instituteApiWithGetAccessToken>;
