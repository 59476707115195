import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdatePhoneNumberFormValues } from 'src/pages-all/user/user-profile/components/phone-number-section/update-phone-number.logic';
import { ForgotPasswordFormValues } from '../../../components/authentication/forms/forgot-password/forgot-password.logic';
import { LoginFormValues } from '../../../components/authentication/forms/login/login.logic';
import { RegisterPhoneFormValues } from '../../../components/authentication/forms/register-phone/register-phone.logic';
import { RegisterWithEmailFormValues } from '../../../components/authentication/forms/register-with-email/register-with-email.logic';
import { VerifyPhoneFormValues } from '../../../components/authentication/forms/verify-phone/verify-phone.logic';
import { LoginResponse, User } from '../../api-models/api-models';
import { UserType } from 'models/user-type';
import { UpdateUserNameFormValues } from 'src/pages-all/user/user-profile/components/user-name-section/update-user-name.logic';
import { UpdateBirthdayFormValues } from 'src/pages-all/user/user-profile/up-coming-lessons/components/update-birthday.logic';
import { RegisterAsElitetutorFormValues } from 'src/pages-all/register-as-elitetutor/forms/register-as-elitetutor/register-as-elitetutor.logic';
import { UtmParams } from 'src/pages-all/register-as-elitetutor/register-as-elitetutor.model';
import {
  RegisterAsElitetutorAnswers,
  RegisterAsElitetutorUserDetails,
} from 'src/pages-all/register-as-elitetutor/hooks/use-register-as-elitetutor-local-storage';
import { RegisterWithEmailWithoutPasswordValues } from 'logic/store/authentication/authentication.model';

export interface AuthenticationState {
  user?: User;
  accessToken?: string;
  isTokenValid?: boolean;
  userEtag?: string;
}

const initialState: AuthenticationState = {};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    reset: () => initialState,
    loginWithEmail: (
      _state,
      _action: PayloadAction<{ formValues: LoginFormValues | undefined }>
    ) => {},
    continueOnboardingIfNeeded: () => {},
    postLoginActions: (
      _state,
      _action: PayloadAction<{ loginResponse: LoginResponse | undefined }>
    ) => {},
    connectWithFacebook: () => {},
    loginWithFacebook: (
      _state,
      _action: PayloadAction<{ fbAccessToken: string | undefined }>
    ) => {},
    loginWithFacebookFailure: () => {},
    connectWithApple: () => {},
    loginWithAppleResponse: (
      _state,
      _action: PayloadAction<{ response: LoginResponse | undefined }>
    ) => {},
    forgotPassword: (
      _state,
      _action: PayloadAction<{ formValues: ForgotPasswordFormValues | undefined }>
    ) => {},
    validatePasswordToken: (_state, _action: PayloadAction<{ token: string | null }>) => {},
    setTokenValid: (state, action: PayloadAction<boolean>) => {
      state.isTokenValid = action.payload;
    },
    setPassword: (_state, _action: PayloadAction<{ password?: string; token: string }>) => {},
    sendPhoneVerificationCode: (
      _state,
      _action: PayloadAction<{
        formValues: RegisterPhoneFormValues | undefined;
      }>
    ) => {},
    verifyPhone: (
      _state,
      _action: PayloadAction<{ formValues: VerifyPhoneFormValues | undefined }>
    ) => {},
    registerWithEmail: (
      _state,
      _action: PayloadAction<{
        formValues: RegisterWithEmailFormValues | undefined;
      }>
    ) => {},

    // this action is currently specific to the `register as elitetutor` flow - see register-as-elitetutor.page.tsx
    registerWithEmailWithoutPassword: (
      _state,
      _action: PayloadAction<{
        formValues: RegisterWithEmailWithoutPasswordValues | undefined;
      }>
    ) => {},
    registerAsElitetutor: (
      _state,
      _action: PayloadAction<{
        formValues: RegisterAsElitetutorFormValues;
        answers?: RegisterAsElitetutorAnswers;
        userDetails?: RegisterAsElitetutorUserDetails;
        utmParams: UtmParams;
      }>
    ) => {},
    setUserType: (_state, _action: PayloadAction<{ userType: UserType }>) => {},
    setAccessToken: (state, action: PayloadAction<{ accessToken: string | undefined }>) => {
      state.accessToken = action.payload.accessToken;
    },
    setUser: (state, action: PayloadAction<{ user: User | undefined }>) => {
      state.user = action.payload.user;
    },
    updatePassword: (_state, _action: PayloadAction<{ password?: string }>) => {},
    updateEmailInitialize: () => {},
    updateEmail: (_state, _action: PayloadAction<{ emailId?: string }>) => {},
    updateUserNameInitialize: () => {},
    updateUserName: (
      _state,
      _action: PayloadAction<{ formValues?: UpdateUserNameFormValues }>
    ) => {},
    updateFullBirthday: (
      _state,
      _action: PayloadAction<{ formValues?: UpdateBirthdayFormValues }>
    ) => {},
    logout: (
      _state,
      _action: PayloadAction<{ hideNotification?: boolean; followupAction?: AnyAction }>
    ) => {},
    fetchCurrentUser: () => {},
    tryDeleteAccount: () => {},
    deleteAccount: () => {},
    updatePhoneInitialize: () => {},
    updatePhoneFinalize: () => {},
    updatePhone: (
      _state,
      _action: PayloadAction<{
        formValues: UpdatePhoneNumberFormValues | undefined;
      }>
    ) => {},
    verifyUpdatedPhone: () => {},
    setUserEtag: (state, action: PayloadAction<{ userEtag: string }>) => {
      state.userEtag = action.payload.userEtag;
    },
    deleteUserVideo: () => {},
  },
});
