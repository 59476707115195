import * as React from 'react';
import { SelectOption } from '../form-inputs/select-option/select-option';
import './country-code.styles.less';
import { getCountryCallingCode } from '../../logic/phone/phone.validator';
import { Country } from '../../models/country';

export type CountryCodeSelectOption = SelectOption<
  string | null | undefined,
  JSX.Element | string | null | undefined,
  Country | null | undefined
>;

export const getCountryCodeOptions = (
  countries: Country[] | undefined = []
): CountryCodeSelectOption[] => countries.map((c) => getCountryCodeSelectOption(c));

const getCountryCodeSelectOption = (country: Country): CountryCodeSelectOption => {
  const callingCode = `+${getCountryCallingCode(country.country_code)}`;

  return {
    label: <span className={'calling-code-select-option-code'}>{callingCode}</span>,
    value: country.country_code,
    tag: country,
  };
};

export const getCountryNamesWithCodeOptions = (
  countries: Country[] | undefined = []
): CountryCodeSelectOption[] => countries.map((c) => getCountryNameWithCodeSelectOption(c));

const getCountryNameWithCodeSelectOption = (country: Country): CountryCodeSelectOption => {
  const label = `${country.name} (+${getCountryCallingCode(country.country_code)})`;

  return {
    label,
    value: country.country_code,
    tag: country,
  };
};

export const getCountryCodesISOAlpha2SelectOptions = (
  countries: Country[]
): CountryCodeSelectOption[] =>
  countries.map(({ country_code, name }) => ({
    label: country_code,
    value: country_code,
    tag: { country_code, name },
  }));
