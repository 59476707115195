import { useSelector } from 'react-redux';
import { FormErrors, getFormValues } from 'redux-form';
import { TutoringRequestRequest } from 'logic/api-models/api-models';
import { isMin, isMax, isRequiredValidator } from 'logic/validators/validators';
import { clearEmptyFields } from 'logic/validators/utils';

export type RequestTutoringFormValues = Partial<TutoringRequestRequest>;
export type RequestTutoringFormErrors = FormErrors<TutoringRequestRequest, any>;

export const REQUEST_TUTORING_FORM_NAME = 'request-tutoring-form';

export enum RequestTutoringFields {
  Level = 'requestTeachingLevel',
  Hours = 'tutoringSessionsPerMonth',
  Subjects = 'subjects',
  Location = 'teachingLocation',
}

export const useCurrentFormValues = () =>
  useSelector(getFormValues(REQUEST_TUTORING_FORM_NAME)) as RequestTutoringFormValues;

export const validateRequestTutoringForm = (values: RequestTutoringFormValues) => {
  const { tutoringSessionsPerMonth, requestTeachingLevel } = values;

  const errors: Partial<RequestTutoringFormErrors> = {
    requestTeachingLevel: isRequiredValidator(requestTeachingLevel),
    tutoringSessionsPerMonth:
      tutoringSessionsPerMonth &&
      (isMax(tutoringSessionsPerMonth, 40) || isMin(tutoringSessionsPerMonth, 1)),
  };

  return clearEmptyFields(errors);
};
