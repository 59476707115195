import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from 'app/app.store';
import { authenticationSlice } from 'logic/store/authentication/authentication.slice';

export const createAxios = (config?: AxiosRequestConfig | undefined): AxiosInstance => {
  const axiosInstance = axios.create(config);
  axiosInstance.interceptors.response.use(userEtagMiddleware);
  return axiosInstance;
};

const USER_ETAG_URL_BLACKLIST = new Set(['/user/current']);

const userEtagMiddleware = (response: AxiosResponse) => {
  if (USER_ETAG_URL_BLACKLIST.has(response.config.url ?? '')) {
    return response;
  }

  const userEtag = response.headers['de.tutorspace.user-etag'];
  if (userEtag) {
    store.dispatch(authenticationSlice.actions.setUserEtag({ userEtag }));
  }
  return response;
};
