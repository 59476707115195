import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObjectByKey } from 'logic/utils/object';
export interface StepFlowState {
  stepFlowByKey?: ObjectByKey<any>;
}

const initialState: StepFlowState = {};

export const stepFlowSlice = createSlice({
  name: 'stepFlow',
  initialState,
  reducers: {
    reset: () => initialState,
    setFlowStep: (state, action: PayloadAction<{ stepFlowName: string; flowStepKey: string }>) => {
      if (!state.stepFlowByKey) {
        state.stepFlowByKey = {};
      }
      state.stepFlowByKey[action.payload.stepFlowName] = action.payload.flowStepKey;
    },
    resetFlowStep: (state, action: PayloadAction<{ stepFlowName: string }>) => {
      if (!state.stepFlowByKey) {
        state.stepFlowByKey = {};
      }
      state.stepFlowByKey[action.payload.stepFlowName] = undefined;
    },
  },
});
