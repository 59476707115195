import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigationSlice } from 'logic/store/navigation/navigation.slice';
import { Stack } from 'components/stack/stack';
import { Text } from 'components/text/Text';
import styled from 'styled-components';
import tw from 'twin.macro';
import { colors } from 'styles/colors';
import { Breakpoints } from 'logic/hooks/use-breakpoints';
import {
  getAccountRights,
  getAccountType,
} from 'logic/store/authentication/authentication.selectors';
import { AccountRight, AccountType } from 'logic/api-models/api-models';
import {
  translate,
  translateWithParser,
  translationKeys,
} from 'logic/translations/translations.service';
import { Link } from 'gatsby';
import { AppRoute } from 'app/app.route.const';
import { SideBarLinks } from './sidebars';
import {
  getElitePaymentSeekerInfo,
  getOpenEliteratings,
} from '../../logic/store/elitetutor/elitetutor.selectors';
import { elitetutorSlice } from '../../logic/store/elitetutor/elitetutor.slice';
import { conferenceSlice } from 'logic/store/conference/conference.slice';
import { useInProgress } from 'logic/store/tracker/tracker.hooks';
import { Badge } from 'antd';

export const LayoutSidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${tw`p-2`};
  margin: 0 24px 24px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  position: relative;

  grid-area: sidebar;
  background-color: white;

  @media (${Breakpoints.maxDesktop}) {
    display: none;
  }
`;

const PromotionWrapper = styled.div`
  position: absolute;
  bottom: 24px;
`;

const BottomWrapper = styled.div`
  margin: 40px 24px 12px 16px;
`;

const HelpWrapper = styled.div`
  margin: 16px 0 0 0;
`;

const DonationInfo = styled.div`
  margin: 0 0 16px 0;
`;

const StyledLink = styled.a`
  text-decoration: underline;
`;

const StyledParagraph = styled.p`
  margin: 0;
`;

type SidebarLinkProps = {
  onClick(): void;
  Icon: any;
  IconActive: any;
  text: string;
  path: string;
  badgeCount?: number;
};

const SidebarLink: React.FC<SidebarLinkProps> = ({
  onClick,
  text,
  Icon,
  IconActive,
  path,
  badgeCount,
}) => {
  const isActive = window?.location.pathname === path;

  return (
    <span onClick={onClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
      <span
        style={{
          backgroundColor: isActive ? colors.sidebarActiveRowGary : undefined,
          borderRadius: 11,
          width: '100%',
          height: '100%',
          padding: 8,
        }}
      >
        <span style={{ marginRight: 11 }}>{isActive ? IconActive : Icon}</span>
        <Badge count={badgeCount} offset={[15, 0]}>
          <span>
            <Text size={16}>{text}</Text>
          </span>
        </Badge>
      </span>
    </span>
  );
};

const SideBarSubHeadline: React.FC<{ text: string }> = ({ text }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          borderRadius: 11,
          width: '100%',
          height: '100%',
          padding: 8,
        }}
      >
        <Text size={16} color="black">
          {text}
        </Text>
      </span>
    </span>
  );
};

export const Sidebar: FC<{ options: SideBarLinks }> = ({ options }) => {
  const dispatch = useDispatch();
  const accountType = useSelector(getAccountType) || AccountType.STUDENT;
  const accountRights = useSelector(getAccountRights);
  const elitePaymentSeekerInfo = useSelector(getElitePaymentSeekerInfo);
  const openEliteratings = useSelector(getOpenEliteratings);
  const isTestCallLoading = useInProgress(conferenceSlice.actions.createAndJoinTestCall);

  useEffect(() => {
    if (!elitePaymentSeekerInfo && accountRights?.includes(AccountRight.ELITESEEKER)) {
      dispatch(elitetutorSlice.actions.getElitePaymentSeekerInfo());
    }
    // stringify dependencies to avoid multiple actions based on the same data
  }, [dispatch, elitePaymentSeekerInfo?.toString(), accountRights?.toString()]);

  useEffect(() => {
    if (accountRights?.includes(AccountRight.ELITETUROR)) {
      dispatch(elitetutorSlice.actions.getEliteratings());
    }
    // stringify dependencies to avoid multiple actions based on the same data
  }, [accountRights?.toString(), dispatch]);

  const handleOnLinkClick = (path: string) => {
    dispatch(
      navigationSlice.actions.navigateTo({
        path,
      })
    );
  };

  const handleCreateAndJoinTestCall = () => {
    dispatch(conferenceSlice.actions.createAndJoinTestCall());
  };

  return (
    <LayoutSidebar>
      <Stack gap={0} flow="row" alignItems="start">
        {options.regular.map((link) => (
          <SidebarLink key={link.path} onClick={() => handleOnLinkClick(link.path)} {...link} />
        ))}
        {options.eliteTutor.length > 0 && (
          <>
            <hr style={{ margin: '8px 16px 8px 16px' }} />
            <SideBarSubHeadline
              key={'tutor'}
              text={translate(translationKeys.sidebar.elitetutor.title)}
            />
          </>
        )}
        {options.eliteTutor.map((link) => {
          if (link.showBadge && link.path === AppRoute.EarnMoney) {
            return (
              <SidebarLink
                badgeCount={openEliteratings.length}
                key={link.path}
                onClick={() => handleOnLinkClick(link.path)}
                {...link}
              />
            );
          } else {
            return (
              <SidebarLink key={link.path} onClick={() => handleOnLinkClick(link.path)} {...link} />
            );
          }
        })}
        {options.eliteSeeker.length > 0 && (
          <>
            <hr style={{ margin: '8px 16px 8px 16px' }} />
            <SideBarSubHeadline
              key={'tutor'}
              text={translate(translationKeys.sidebar.eliteseeker.title)}
            />
          </>
        )}
        {options.eliteSeeker.map((link) => (
          <SidebarLink key={link.path} onClick={() => handleOnLinkClick(link.path)} {...link} />
        ))}
      </Stack>
      {accountRights && !accountRights?.length && accountType !== AccountType.TUTORING_INSTITUTE && (
        <PromotionWrapper>
          <Link to={AppRoute.TutorspacePremiumBecome}>
            {translate(translationKeys.sidebar.custom.promotion)}
          </Link>
        </PromotionWrapper>
      )}
      {accountRights &&
        accountRights.findIndex(
          (accountRight) =>
            accountRight === AccountRight.ELITESEEKER || accountRight === AccountRight.ELITETUROR
        ) > -1 && (
          <BottomWrapper>
            {elitePaymentSeekerInfo?.confirmedBookings &&
              elitePaymentSeekerInfo.confirmedBookings.count > 0 && (
                <>
                  <DonationInfo>
                    {translateWithParser(translationKeys.sidebar.custom.donationInfo, {
                      donationCount: elitePaymentSeekerInfo?.confirmedBookings?.count,
                    })}
                  </DonationInfo>
                  <hr
                    style={{
                      borderColor: colors.primary,
                      color: colors.primary,
                      backgroundColor: colors.primary,
                      border: 0,
                      borderTop: '1.5px solid',
                    }}
                  />
                </>
              )}
            <HelpWrapper>
              <StyledParagraph>
                {translate(translationKeys.sidebar.custom.testCall.doYouWantToTest)}
              </StyledParagraph>
              <span>
                👉&nbsp;
                {isTestCallLoading ? (
                  translate(translationKeys.sidebar.custom.testCall.loading)
                ) : (
                  <StyledLink onClick={handleCreateAndJoinTestCall}>
                    {translate(translationKeys.sidebar.custom.testCall.start)}
                  </StyledLink>
                )}
              </span>
            </HelpWrapper>
            <HelpWrapper>
              <StyledParagraph>
                {translate(translationKeys.sidebar.custom.help.headline)}
              </StyledParagraph>
              <div>
                👉&nbsp;
                <StyledLink href={'https://linktr.ee/tutorspace'}>
                  {translate(translationKeys.sidebar.custom.help.textForTutor)}
                </StyledLink>
              </div>
              <div>
                👉&nbsp;
                <StyledLink
                  onClick={() => handleOnLinkClick(AppRoute.SupportAndHelpYouAskWeAnswer)}
                >
                  {translate(translationKeys.sidebar.custom.help.textForSeeker)}
                </StyledLink>
              </div>
            </HelpWrapper>
          </BottomWrapper>
        )}
    </LayoutSidebar>
  );
};
