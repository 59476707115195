import { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

function ScrollToTopInner({ history }: RouteComponentProps) {
  useEffect(() => {
    const unlisten = history.listen(scrollToTop);
    return unlisten;
  }, [history]);

  return null;
}

export const scrollToTop = () => window.scrollTo(0, 0);
export const scrollToId = (selector: string) => {
  const hasDocument = typeof document !== 'undefined';

  if (hasDocument) {
    document.querySelector(selector)?.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export const ScrollToTop = withRouter(ScrollToTopInner);
