import { FormErrors } from 'redux-form';
import { TutoringTypeUnion } from 'logic/api-models/tutoring-type';
import { clearEmptyFields, clearEmptyFieldsAndEmptyObjects } from 'logic/validators/utils';
import { isFileSizeLessOrEqual, isRequiredValidator } from 'logic/validators/validators';
import { MAX_UPLOAD_IMAGE_SIZE_IN_BYTES, OfferFormFields } from '../offer-form.logic';
import { OfferImageResponse, WeekDay } from 'logic/api-models/api-models';
import { TimeOfDay } from 'logic/api-models/time-of-day';
import { UploadFile } from 'antd/lib/upload/interface';
import { GroupSize } from 'logic/api-models/api-models';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { isEmpty, map } from 'lodash';

export const INSTITUTE_OFFER_FORM_NAME = 'institute-offer-form';

export interface FormInstitutePhoto {
  newFile?: File;
  existingPhoto?: OfferImageResponse;
}

export interface FormInstitutePhotos {
  photos?: (FormInstitutePhoto | undefined)[];
  defaultPhotoIndex?: number;
}

export interface InstituteOfferFormValues {
  _id?: string | null;
  [OfferFormFields.name]?: string | null;
  [OfferFormFields.description]?: string | null;
  [OfferFormFields.photos]?: FormInstitutePhotos;
  [OfferFormFields.address]?: string | null;
  [OfferFormFields.postcode]?: string | null;
  [OfferFormFields.place]?: string | null;
  [OfferFormFields.region]?: string | null;
  [OfferFormFields.country]?: string | null;
  [OfferFormFields.longitude]?: number | null;
  [OfferFormFields.latitude]?: number | null;
  [OfferFormFields.price]?: number | null;
  [OfferFormFields.level]?: number | null;
  [OfferFormFields.subjects]?: number[];
  [OfferFormFields.location]?: TutoringTypeUnion[];

  [OfferFormFields.groupSizes]?: GroupSize[];
  [OfferFormFields.openingHours]?: OpeningHoursForm;
  [OfferFormFields.primaryContact]?: string | null;
  [OfferFormFields.phoneNumber]?: string | null;
  [OfferFormFields.email]?: string | null;
  [OfferFormFields.websiteUrl]?: string | null;
  [OfferFormFields.impressumUrl]?: string | null;
  [OfferFormFields.impressumFile]?: UploadFile;

  [OfferFormFields.imageIds]?: string[];
  [OfferFormFields.defaultImageId]?: string | null;

  [OfferFormFields.lessonLength]?: 45 | 60;
}

export interface OpeningHoursForm {
  [WeekDay.MONDAY]?: OpeningDayHoursForm;
  [WeekDay.TUESDAY]?: OpeningDayHoursForm;
  [WeekDay.WEDNESDAY]?: OpeningDayHoursForm;
  [WeekDay.THURSDAY]?: OpeningDayHoursForm;
  [WeekDay.FRIDAY]?: OpeningDayHoursForm;
  [WeekDay.SATURDAY]?: OpeningDayHoursForm;
  [WeekDay.SUNDAY]?: OpeningDayHoursForm;
}

export interface OpeningDayHoursForm {
  [TimeOfDay.Morning]?: (string | undefined)[];
  [TimeOfDay.Evening]?: (string | undefined)[];
}

export type InstituteOfferFormValidationErrors = FormErrors<InstituteOfferFormValues, any>;

export const validateInstituteOfferForm = (formValues: InstituteOfferFormValues) => {
  const {
    name,
    description,
    photos,
    address,
    price,
    level,
    subjects,
    location,
    groupSizes,
    openingHours,
    websiteUrl,
    impressumUrl,
    impressumFile,
  } = formValues;

  const errors: InstituteOfferFormValidationErrors = {
    name: isRequiredValidator(name),
    description: isRequiredValidator(description),
    photos:
      isRequiredValidator(photos?.photos) ||
      isRequiredValidator(
        photos?.defaultPhotoIndex,
        translate(translationKeys.validator.form.isDefaultPhotoRequired)
      ),
    address: isRequiredValidator(address),
    price: isRequiredValidator(price),
    level: isRequiredValidator(level),
    subjects: isRequiredValidator(subjects),
    location: isRequiredValidator(location),
    groupSizes: isRequiredValidator(groupSizes),
    openingHours: isOpeningHoursOverlaping(openingHours),
    websiteUrl: isRequiredValidator(websiteUrl),
    impressumUrl: !impressumFile?.originFileObj ? isRequiredValidator(impressumUrl) : undefined,
    impressumFile: impressumFile?.fileName
      ? isFileSizeLessOrEqual(impressumFile?.originFileObj, MAX_UPLOAD_IMAGE_SIZE_IN_BYTES)
      : undefined,
  };

  return clearEmptyFields(errors);
};

export const OPENINGS_HOURS_OVERLAPS = translate(
  translationKeys.validator.form.openingHoursOverlaps
);

export const isOpeningHoursOverlaping = (openingHours: OpeningHoursForm | undefined) => {
  if (!openingHours) return undefined;

  const errors = clearEmptyFieldsAndEmptyObjects(
    map(WeekDay).reduce(
      (acc, weekDay) => ({
        ...acc,
        [weekDay]: isOpeningDayHoursOverlaping(openingHours[weekDay]),
      }),
      {} as OpeningHoursForm
    )
  );

  return !isEmpty(errors) ? errors : undefined;
};

export const isOpeningDayHoursOverlaping = (openingDayHours: OpeningDayHoursForm | undefined) => {
  if (!openingDayHours) return undefined;

  const morningStart = openingDayHours?.morning && openingDayHours?.morning[0];
  const morningEnd = openingDayHours?.morning && openingDayHours?.morning[1];

  const eveningStart = openingDayHours?.evening && openingDayHours?.evening[0];
  const eveningEnd = openingDayHours?.evening && openingDayHours?.evening[1];

  return !eveningStart || !eveningEnd || !morningStart || !morningEnd || eveningStart >= morningEnd
    ? undefined
    : { [TimeOfDay.Evening]: OPENINGS_HOURS_OVERLAPS };
};
