import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export const featureFlags = {
  ratings: true,
  showTutoringSchools: true,
  searchResultsCityContent: true,
  eliteTutorPromotion: true,
  showTutoringSchoolsInMainSearch: false,
};

export const ofFeatureFlagEnabled = (featureFlag: boolean) => <T>(source: Observable<T>) =>
  source.pipe(filter(() => featureFlag));
