import { FC, Children, ReactNode, CSSProperties } from 'react';
import * as React from 'react';
import { isFragment } from 'react-is';
import styled from 'styled-components';

type AlignItems = 'center' | 'baseline' | 'normal';
type JustifyContent = 'normal' | 'space-between' | 'end' | 'center';

const WrapContainer = styled.div<{
  gap: number;
  alignItems?: AlignItems;
  justifyContent?: JustifyContent;
}>`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ gap }) => `${gap / 2}rem`};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
`;

const WrapItem = styled.div<{ gap: number }>`
  margin: ${({ gap }) => `${gap / 2}rem`};
`;

// sadly the gap rule is currently not really supported for flex
// that's why we need this workaround
export const GroupWrap: FC<{
  gap: number;
  alignItems?: AlignItems;
  justifyContent?: JustifyContent;
  classes?: string;
  style?: CSSProperties;
}> = ({ children, classes, ...props }) => {
  return (
    <WrapContainer {...props}>
      {Children.map(children, function recursive(child, index): ReactNode {
        if (isFragment(child)) {
          return Children.map(child.props.children, recursive);
        } else if (child) {
          return (
            <WrapItem key={index} className={`group-wrap-item ${classes}`} gap={props.gap}>
              {child}
            </WrapItem>
          );
        } else {
          return null;
        }
      })}
    </WrapContainer>
  );
};
