import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initializeSlice } from '../../logic/store/initialize/initialize.slice';
import { useSucceeded } from '../../logic/store/tracker/tracker.hooks';
import { AppSpin } from '../app-spin/app-spin';
import { logInWithAppleIfCan } from 'logic/store/authentication/apple/apple.epic.logic';
import { loadEpic } from 'logic/lazy-load-epic/lazy-load-epic';
import { authenticationAppleEpics$ } from 'logic/store/authentication/apple/apple.epic';

loadEpic(authenticationAppleEpics$);

export const InitializeGate: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const isSucceeded = useSucceeded(initializeSlice.actions.initialize);

  useEffect(() => {
    dispatch(initializeSlice.actions.initialize());
    logInWithAppleIfCan();
  }, [dispatch]);

  return !isSucceeded ? <AppSpin /> : <>{children}</>;
};
