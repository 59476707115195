import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'logic/api-models/api-models';
import { AuthenticationStepKey } from '../authentication/authentication.model';

export interface ModalState {
  authenticationStepKey?: AuthenticationStepKey;
  successfulLoginRedirectPath?: string;
  continueWithFacebookConfirmationData?: {
    fbUser?: IFacebookAPIUserResponse;
    fbAccessToken?: string;
  };
  userContactVisible?: boolean;
  userContactUser?: User;
  ratingsVisible?: boolean;
  requestTutoringModalVisible?: boolean;
  reportOfferModalVisible?: boolean;
  giveRatingModalVisible?: boolean;
  updatePayoutInfoModalVisible?: boolean;
  manualPayoutModalVisible?: boolean;
  birthdayRequiredModalVisible?: boolean;
  promptCreditPurchaseModalVisible?: boolean;
  eliteratingModalVisible?: boolean;
}

const initialState: ModalState = {};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    reset: () => initialState,
    setContinueWithFacebookConfirmationData: (
      state,
      action: PayloadAction<
        | {
            fbUser: IFacebookAPIUserResponse | undefined;
            fbAccessToken: string | undefined;
          }
        | undefined
      >
    ) => {
      state.continueWithFacebookConfirmationData = action.payload;
    },
    openAuthenticationModal: (
      state,
      action: PayloadAction<{
        stepKey: AuthenticationStepKey;
        successfulLoginRedirectPath?: string;
      }>
    ) => {
      state.authenticationStepKey = action.payload.stepKey;
      state.successfulLoginRedirectPath = action.payload.successfulLoginRedirectPath;
    },
    closeAuthenticationModal: (state) => {
      state.authenticationStepKey = undefined;
    },
    openUserContact: (state, action: PayloadAction<{ user: User | undefined }>) => {
      state.userContactVisible = true;
      state.userContactUser = action.payload.user;
    },
    closeUserContact: (state) => {
      state.userContactVisible = false;
      state.userContactUser = undefined;
    },
    openReviewsModal: (state) => {
      state.ratingsVisible = true;
    },
    closeReviewsModal: (state) => {
      state.ratingsVisible = false;
    },
    openRequestTutoringModal: (_state) => {
      _state.requestTutoringModalVisible = true;
    },
    closeRequestTutoringModal: (state) => {
      state.requestTutoringModalVisible = false;
    },
    openReportOfferModal: (_state) => {
      _state.reportOfferModalVisible = true;
    },
    closeReportOfferModal: (_state) => {
      _state.reportOfferModalVisible = false;
    },
    openGiveRatingModal: (_state) => {
      _state.giveRatingModalVisible = true;
    },
    closeGiveRatingModal: (_state) => {
      _state.giveRatingModalVisible = false;
    },
    openUpdatePayoutInfoModal: (_state) => {
      _state.updatePayoutInfoModalVisible = true;
    },
    closeUpdatePayoutInfoModal: (_state) => {
      _state.updatePayoutInfoModalVisible = false;
    },
    openManualPayoutModal: (_state) => {
      _state.manualPayoutModalVisible = true;
    },
    closeManualPayoutModal: (_state) => {
      _state.manualPayoutModalVisible = false;
    },
    openBirthdayRequiredModal: (_state) => {
      _state.birthdayRequiredModalVisible = true;
    },
    closeBirthdayRequiredModal: (_state) => {
      _state.birthdayRequiredModalVisible = false;
    },
    openPromptCreditPurchaseModal: (_state) => {
      _state.promptCreditPurchaseModalVisible = true;
    },
    closePromptCreditPurchaseModal: (_state) => {
      _state.promptCreditPurchaseModalVisible = false;
    },
    openEliteratingModal: (_state) => {
      _state.eliteratingModalVisible = true;
    },
    closeEliteratingModal: (_state) => {
      _state.eliteratingModalVisible = false;
    },
  },
});
