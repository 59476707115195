import { OfferResponse } from 'logic/api-models/api-models';
import { slugifyText } from 'logic/slugify-text/slugify-text';

export const isTutoringSchoolOffer = (offer: OfferResponse | undefined) => !!offer?.tutoringSchool;

export const getOfferId = (offer: OfferResponse | undefined) => offer?._id || 'undefined';

export const getOfferCity = (offer: OfferResponse | undefined) =>
  slugifyText(offer?.location?.place || 'undefined');

export const getSchoolName = (offer: OfferResponse | undefined) =>
  slugifyText(offer?.tutoringSchool?.name || 'undefined');
