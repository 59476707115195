import React from 'react';
import { ButtonProps } from 'antd/lib/button';
import { showConfirmModal, closeConfirmModal } from 'logic/modals/confirm-modal/confirm-modal';
import { translate, translationKeys } from 'logic/translations/translations.service';

interface DialogResultHandlers {
  onDialogResponseCancel?: ButtonProps['onClick'];
  onDialogResponseOK?: ButtonProps['onClick'];
}

export const handleDeleteOfferDialog = ({
  onDialogResponseCancel = closeConfirmModal,
  onDialogResponseOK,
}: DialogResultHandlers) => {
  showConfirmModal({
    width: 340,
    icon: null,
    title: (
      <>
        {translate(translationKeys.tutoringInstituteProfile.offersPage.deleteOffer.modalTitle1)}
        <br />
        {translate(translationKeys.tutoringInstituteProfile.offersPage.deleteOffer.modalTitle2)}
      </>
    ),
    content: (
      <>
        <br />
        {translate(translationKeys.tutoringInstituteProfile.offersPage.deleteOffer.modalText1)}
        <br />
        <br />
        {translate(translationKeys.tutoringInstituteProfile.offersPage.deleteOffer.modalText2)}
        <br />
      </>
    ),
    buttons: [
      {
        text: translate(translationKeys.tutoringInstituteProfile.offersPage.deleteOffer.back),
        onClick: onDialogResponseCancel,
        type: 'primary',
      },
      {
        text: translate(
          translationKeys.tutoringInstituteProfile.offersPage.deleteOffer.confirmDeletion
        ),
        onClick: onDialogResponseOK,
      },
    ],
  });
};
