import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isFileSizeLessOrEqual, isRequiredValidator } from 'logic/validators/validators';
import { FILE_SIZE_POWER_BASE } from 'logic/utils/file';

export const MAX_UPLOAD_IMAGE_SIZE_IN_BYTES = 6 * FILE_SIZE_POWER_BASE * FILE_SIZE_POWER_BASE;

export const EARN_MONEY_FORM_NAME = 'earn-money-form';
export interface EarnMoneyFormValues {
  seekerId?: string;
  screenshot?: {
    url?: string;
    file?: File;
  };
}

export type EarnMoneyFormValidationErrors = FormErrors<EarnMoneyFormValues, string>;

export const validateEarnMoneyForm = (formValues: EarnMoneyFormValues) => {
  const { screenshot, seekerId } = formValues;

  const errors: EarnMoneyFormValidationErrors = {
    seekerId: isRequiredValidator(seekerId),
    screenshot: !!screenshot
      ? isFileSizeLessOrEqual(screenshot.file, MAX_UPLOAD_IMAGE_SIZE_IN_BYTES)
      : undefined,
  };

  return clearEmptyFields(errors);
};
