import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isRequiredValidator, areMatching, isOfValidLength } from 'logic/validators/validators';

export const UPDATE_PASSWORD_FORM_NAME = 'update-password';

export interface UpdatePasswordFormValues {
  password?: string;
  passwordConfirmation?: string;
}

export type UpdatePasswordFormValidationErrors = FormErrors<UpdatePasswordFormValues, any>;

export const validateUpdatePasswordForm = (formValues: UpdatePasswordFormValues) => {
  const { password, passwordConfirmation } = formValues;

  const errors: UpdatePasswordFormValidationErrors = {
    password: isRequiredValidator(password) || isOfValidLength(password),
    passwordConfirmation:
      isRequiredValidator(passwordConfirmation) || areMatching(passwordConfirmation, password),
  };

  return clearEmptyFields(errors);
};
