import { SelectOption } from 'components/form-inputs/select-option/select-option';

export enum TeachingLevel {
  klasse4 = 1,
  klasse7 = 2,
  klasse10 = 3,
  abitur = 4,
  berufsschule = 5,
  fachhochschule = 6,
  universtitatStudium = 7,
}

export enum TutoringRequestTeachingLevel {
  CLASS1 = 1,
  CLASS2 = 2,
  CLASS3 = 3,
  CLASS4 = 4,
  CLASS5 = 5,
  CLASS6 = 6,
  CLASS7 = 7,
  CLASS8 = 8,
  CLASS9 = 9,
  CLASS10 = 10,
  CLASS11 = 11,
  CLASS12 = 12,
  ABITUR = 13,
  UNIVERSITY = 14,
}

export enum TutoringRequestTeachingLevelV2 {
  CLASS1 = 'class1',
  CLASS2 = 'class2',
  CLASS3 = 'class3',
  CLASS4 = 'class4',
  CLASS5 = 'class5',
  CLASS6 = 'class6',
  CLASS7 = 'class7',
  CLASS8 = 'class8',
  CLASS9 = 'class9',
  CLASS10 = 'class10',
  CLASS11 = 'class11',
  CLASS12 = 'class12',
  ABITUR = 'abitur',
  UNIVERSITY = 'university',
  OTHER = 'other',
}

export type TeachingLevelSelectOption = SelectOption<TeachingLevel, string>;
export type RequestTeachingLevelSelectOption = SelectOption<TutoringRequestTeachingLevel, string>;
export type TeachingLevelVariants = 'teachingLevel' | 'requestTeachingLevel';

export const BasicTeachingLevels = [
  TeachingLevel.klasse4,
  TeachingLevel.klasse7,
  TeachingLevel.klasse10,
  TeachingLevel.abitur,
  TeachingLevel.berufsschule,
  TeachingLevel.fachhochschule,
  TeachingLevel.universtitatStudium,
];

export const RequestTutoringTeachingLevels = [
  TutoringRequestTeachingLevel.CLASS1,
  TutoringRequestTeachingLevel.CLASS2,
  TutoringRequestTeachingLevel.CLASS3,
  TutoringRequestTeachingLevel.CLASS4,
  TutoringRequestTeachingLevel.CLASS5,
  TutoringRequestTeachingLevel.CLASS6,
  TutoringRequestTeachingLevel.CLASS7,
  TutoringRequestTeachingLevel.CLASS8,
  TutoringRequestTeachingLevel.CLASS9,
  TutoringRequestTeachingLevel.CLASS10,
  TutoringRequestTeachingLevel.CLASS11,
  TutoringRequestTeachingLevel.CLASS12,
  TutoringRequestTeachingLevel.ABITUR,
  TutoringRequestTeachingLevel.UNIVERSITY,
];

export const RequestTutoringTeachingLevelsV2ForElitebookings = [
  TutoringRequestTeachingLevelV2.CLASS1,
  TutoringRequestTeachingLevelV2.CLASS2,
  TutoringRequestTeachingLevelV2.CLASS3,
  TutoringRequestTeachingLevelV2.CLASS4,
  TutoringRequestTeachingLevelV2.CLASS5,
  TutoringRequestTeachingLevelV2.CLASS6,
  TutoringRequestTeachingLevelV2.CLASS7,
  TutoringRequestTeachingLevelV2.CLASS8,
  TutoringRequestTeachingLevelV2.CLASS9,
  TutoringRequestTeachingLevelV2.CLASS10,
  TutoringRequestTeachingLevelV2.CLASS11,
  TutoringRequestTeachingLevelV2.CLASS12,
  TutoringRequestTeachingLevelV2.ABITUR,
  TutoringRequestTeachingLevelV2.UNIVERSITY,
  TutoringRequestTeachingLevelV2.OTHER,
];
