import { Dispatch } from 'redux';
import { touch, stopSubmit } from 'redux-form';
import { flatten } from '../utils/flatten';

export const showReduxFormErrorsAction = (formName: string, fields: string[]) =>
  touch(formName, ...fields);

const showReduxFormErrors = (dispatch: Dispatch) => (formName: string, fields: string[]) =>
  dispatch(touch(formName, ...fields));

export const reduxFormAsyncErrorsHandler = (dispatch: Dispatch) => (
  formName: string,
  errors: object
) => {
  dispatch(stopSubmit(formName, errors));
  showReduxFormErrors(dispatch)(formName, Object.keys(flatten(errors)));
};
