import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subject } from 'logic/api-models/api-models';
import { HIDDEN_SUBJECTS, SubjectType } from 'models/subject-type';
import {
  getPopularSubjects,
  mapSubjectsToSubjectOptions,
  mapSubjectsToSubjectOptionsNames,
  mapSubjectsToSubjectsByName,
  mapSubjectsToSubjectsByNumber,
} from './subject.logic';
import {
  SubjectsByName,
  SubjectsByNumber,
  SubjectsBySlug,
  SubjectSelectOption,
  SubjectSelectOptionNames,
} from './subject.model';

export interface SubjectState {
  subjects?: (Subject | undefined)[];
  subjectSelectOptionsNames?: SubjectSelectOptionNames[];
  subjectSelectOptions?: SubjectSelectOption[];
  popularSubjectSelectOptions?: (SubjectSelectOptionNames | undefined)[];
  subjectsByNumber?: SubjectsByNumber;
  subjectsByName?: SubjectsByName;
  subjectsBySlug?: SubjectsBySlug;
  eliteSubjectSelectOptions?: SubjectSelectOption[];
}

const initialState: SubjectState = {};

export const subjectSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    reset: () => initialState,
    fetchSubjects: () => {},
    setSubjects: (
      state,
      action: PayloadAction<{ subjects: (Subject | undefined)[] | undefined }>
    ) => {
      const subjects = action.payload.subjects?.filter(
        (subject) => subject?.subjectNumber !== SubjectType.CoronaHelp
      );
      const nonHiddenSubjects = subjects?.filter(
        (subject) => subject?.subjectNumber != null && !HIDDEN_SUBJECTS.has(subject.subjectNumber)
      );

      state.subjects = subjects;
      state.subjectSelectOptionsNames = mapSubjectsToSubjectOptionsNames(nonHiddenSubjects);
      state.subjectSelectOptions = mapSubjectsToSubjectOptions(subjects);
      state.popularSubjectSelectOptions = mapSubjectsToSubjectOptionsNames(
        getPopularSubjects(subjects, [
          SubjectType.Math,
          SubjectType.English,
          SubjectType.GermanSchool,
          SubjectType.French,
        ])
      );
      state.subjectsByNumber = mapSubjectsToSubjectsByNumber(subjects);
      state.subjectsByName = mapSubjectsToSubjectsByName(subjects);
      state.eliteSubjectSelectOptions = mapSubjectsToSubjectOptions(
        subjects?.filter((subject) => subject?.isElite)
      );
    },
  },
});
