import { reduce } from 'lodash';
import { Subject } from '../../api-models/api-models';
import { SUBJECT_NAME_SOFT_HYPHEN_MAP, SubjectType } from '../../../models/subject-type';
import { SubjectsBySlug, SubjectSelectOption, SubjectSelectOptionNames } from './subject.model';
import { SubjectsByName, SubjectsByNumber } from './subject.model';

export const mapSubjectsToSubjectOptionsNames = (
  subjects: (Subject | undefined)[] | undefined
): SubjectSelectOptionNames[] | undefined =>
  subjects?.map((subject) => ({
    label: subject?.subjectName || '',
    value: subject?.subjectName || '',
    tag: subject,
  }));

export const mapSubjectsToSubjectOptions = (
  subjects: (Subject | undefined)[] | undefined
): SubjectSelectOption[] | undefined =>
  subjects?.map((subject) => ({
    label: subject?.subjectName || '',
    value: subject?.subjectNumber || -1,
    tag: subject,
  }));

export const mapSubjectByNumberToSubjectSelectOption = (
  subjectNumbers: SubjectsByNumber
): SubjectSelectOption[] | undefined =>
  Object.values(subjectNumbers).map((subject) => ({
    label: subject?.subjectName || '',
    value: subject?.subjectNumber || -1,
    tag: subject,
  }));

export const getPopularSubjects = (
  subjects: (Subject | undefined)[] | undefined,
  popularSubjectNumbers: number[] | undefined
) =>
  popularSubjectNumbers?.map((popularSubjectNumber) =>
    subjects?.find((subject) => subject?.subjectNumber === popularSubjectNumber)
  );

export const getFilteredSubjects = (
  subjects: (Subject | undefined)[] | undefined,
  filteredSubjectList: number[] | undefined
) =>
  filteredSubjectList?.map((filteredSubject) =>
    subjects?.find((subject) => subject?.subjectNumber === filteredSubject)
  );

export const mapSubjectsToSubjectsByNumber = (
  subjects: (Subject | undefined)[] | undefined
): SubjectsByNumber =>
  reduce(
    subjects,
    (sum, subject) => ({
      ...sum,
      [subject?.subjectNumber || -1]: subject,
    }),
    {}
  );

export const mapSubjectsToSubjectsByName = (
  subjects: (Subject | undefined)[] | undefined
): SubjectsByNumber =>
  reduce(
    subjects,
    (sum, subject) => ({
      ...sum,
      [subject?.subjectName || -1]: subject,
    }),
    {}
  );

export const mapSubjectsToSubjectsBySlug = (
  subjects: (Subject | undefined)[] | undefined
): SubjectsByNumber =>
  reduce(
    subjects,
    (sum, subject) => ({
      ...sum,
      [subject?.slug || -1]: subject,
    }),
    {}
  );

export const getSubjectByName = (
  subjectsByName: SubjectsByName | undefined,
  subjectName: string | undefined | null
) => (subjectName && subjectsByName && subjectsByName[subjectName]) || undefined;

export const getSubjectBySlug = (
  subjectsBySlug: SubjectsBySlug | undefined,
  subjectSlug: string | undefined | null
) => (subjectSlug && subjectsBySlug && subjectsBySlug[subjectSlug]) || undefined;

export const NO_SUBJECT = 'no-subject';

export const getSubjectName = (
  subjectsByNumber: SubjectsByNumber | undefined,
  subjectType: SubjectType
) => (subjectsByNumber && subjectsByNumber[subjectType]?.subjectName) || NO_SUBJECT;

export const getSubjectSlug = (
  subjectsByNumber: SubjectsByNumber | undefined,
  subjectType: SubjectType
) => (subjectsByNumber && subjectsByNumber[subjectType]?.slug) || NO_SUBJECT;

export const getSubjectNameWithSoftHyphens = (
  subjectsByNumber: SubjectsByNumber | undefined,
  subjectType: SubjectType
) => {
  let subjectName = (subjectsByNumber && subjectsByNumber[subjectType]?.subjectName) || NO_SUBJECT;
  return SUBJECT_NAME_SOFT_HYPHEN_MAP.get(subjectName) ?? subjectName;
};
