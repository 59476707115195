import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from '../authentication/feature/authorization.feature';

export interface NavigationState {}

const initialState: NavigationState = {};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    navigateTo: (
      _state,
      _action: PayloadAction<{
        path: string;
        authenticate?: boolean;
        authorizeFeature?: Feature;
        openInNewTab?: boolean;
      }>
    ) => undefined,
    back: () => undefined,
  },
});
