import React from 'react';
import { Helmet } from 'react-helmet';
import { AppRoute } from 'app/app.route.const';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { hasWindow } from 'logic/utils/window';
import { isContentfulDevelop } from 'logic/env/envs';

interface SEOProps {
  route?: AppRoute;
  title?: string;
  image?: string;
  description?: string;
  noIndex?: boolean;
  faq?: string;
  preventScaling?: boolean;
}

export const Seo: React.FC<SEOProps> = ({
  route,
  title,
  description,
  image,
  noIndex = false,
  faq,
  preventScaling,
}) => {
  const homeTitle = translate(translationKeys.seoRoute[AppRoute.Home].title);
  const homeDescription = translate(translationKeys.seoRoute[AppRoute.Home].description);
  const url = hasWindow ? window.location.href : '';
  const hasQuery = url.includes('?');

  const routeTitle = route && translate(translationKeys.seoRoute[route].title);
  const routeDescription = route && translate(translationKeys.seoRoute[route].description);

  const metaTitle = title || routeTitle || homeTitle || '';
  const metaDescription = description || routeDescription || homeDescription || '';
  const metaImage =
    image ||
    'https://api2.tutorspace.de/file/61961f484a3445001ac5a58e/content/TutorSpaceMetaImage_2021-11-18.jpg';

  return (
    <Helmet>
      <html lang="de" />
      {metaImage && <meta property="twitter:image" content={metaImage} />}
      {metaImage && <meta property="og:image" content={metaImage} />}
      <meta name="author" content="tutorspace.de" />
      <meta name="twitter:site" content="tutorspace.de" />
      {metaTitle && <title>{metaTitle}</title>}
      {metaTitle && <meta name="twitter:title" content={metaTitle} />}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaTitle && <meta property="article:tag" content={metaTitle} />}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && <meta property="og:description" content={metaDescription} />}
      {metaDescription && <meta name="twitter:description" content={metaDescription} />}
      <meta property="og:site_name" content="tutorspace.de" />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="fb:app_id" content="299504410786440" />
      <script type="application/ld+json">{faq}</script>
      {/* hide dev from search engines */}
      {(isContentfulDevelop() || hasQuery || noIndex) && <meta name="robots" content="noindex, nofollow" />}
      {preventScaling && (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      )}
    </Helmet>
  );
};
