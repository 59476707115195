import { isNumber } from 'lodash';

export const parseNumber = (id: string | null | number | undefined): number | undefined => {
  if (id === undefined || id === null) {
    return undefined;
  }

  const result = parseInt(`${id}`, 10);

  if (`${result}` === `${id}`) {
    return result;
  }

  return undefined;
};

export const parseNumberOrDefault = (defaultResult: number) => (value: any) => {
  const result = parseNumber(value);
  return result !== undefined ? result : defaultResult;
};

export const roundToXDecimals = (
  num: number | undefined,
  numOfDecimals = 2,
  separator: ',' | '.' = ','
) => {
  if (numOfDecimals < 0) {
    throw new Error('Number of decimals cannot be less than 0');
  }

  if (!isNumber(num)) return num;

  const rounded = (Math.round(num * 10 ** numOfDecimals) / 10 ** numOfDecimals).toFixed(
    numOfDecimals
  );

  return separator === '.' ? rounded : rounded.replace('.', ',');
};

export const formatAverageRating = (averageRating: number) => roundToXDecimals(averageRating);
