import {
  getChooseUserType,
  getIsAuthenticatedUserAnInstitute,
  getIsAuthenticatedUserAnRegular,
  getShouldVerifyPhone,
} from '../authentication.selectors';
import { Feature } from './authorization.feature';
import { Dispatch, Store } from 'redux';
import { modalSlice } from 'logic/store/modal';
import { AuthenticationStepKey } from '../authentication.model';

export const isAuthorized = (dispatch: Dispatch, getState: Store['getState']) => (
  feature: Feature | undefined
) => {
  if (!feature) return true;
  if (!userHasRegisteredPhone(dispatch, getState)) return false;
  if (!userHasSelectedUserType(dispatch, getState)) return false;

  return isAuthorizedByFeature(getState)(feature);
};

export const isAuthorizedByFeature = (getState: Store['getState']) => (
  feature: Feature | undefined
) => {
  switch (feature) {
    case Feature.UpdateAvailability:
    case Feature.SocialCall:
    case Feature.IntroductionCall:
    case Feature.ApplyAsElite:
    case Feature.MyOffer:
      return userIsRegularUser(getState);
    case Feature.Institute:
      return userIsAnInstitute(getState);
    default:
      return true;
  }
};

const userHasRegisteredPhone = (dispatch: Dispatch, getState: Store['getState']) => {
  const shouldVerifyPhone = getShouldVerifyPhone(getState());
  if (shouldVerifyPhone) {
    dispatch(
      modalSlice.actions.openAuthenticationModal({
        stepKey: AuthenticationStepKey.RegisterPhone,
      })
    );
    return false;
  }
  return true;
};

const userHasSelectedUserType = (dispatch: Dispatch, getState: Store['getState']) => {
  const shouldChooseUserType = getChooseUserType(getState());
  if (shouldChooseUserType) {
    dispatch(
      modalSlice.actions.openAuthenticationModal({
        stepKey: AuthenticationStepKey.SelectUserType,
      })
    );
    return false;
  }
  return true;
};

const userIsAnInstitute = (getState: Store['getState']) =>
  getIsAuthenticatedUserAnInstitute(getState());

const userIsRegularUser = (getState: Store['getState']) =>
  getIsAuthenticatedUserAnRegular(getState());
