import React from 'react';
import {
  DollarCircleOutlined,
  FileDoneOutlined,
  MailOutlined,
  QuestionOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { AppRoute } from 'app/app.route.const';
import { SidebarLinkType } from './types';
import { colors } from 'styles/colors';
import { translate, translationKeys } from 'logic/translations/translations.service';
import {
  AccountRight,
  AccountRights,
  AccountType,
  AccountTypeStringLiteral,
} from 'logic/api-models/api-models';
import { ClockCircleOutlined, CreditCardOutlined, DropboxOutlined } from '@ant-design/icons/lib';
import { generatePath } from 'react-router-dom';
import { ViewAs } from 'src/pages-all/user/user-profile/up-coming-lessons/UpComingLessons.types';

export type SideBarLinks = {
  regular: SidebarLinkType[];
  eliteTutor: SidebarLinkType[];
  eliteSeeker: SidebarLinkType[];
};

const inactiveColor = { color: colors.sidebarIconGray };
const activeColor = { color: colors.primary };

const tutoringInstituteSidebarOptionsSubscriptionModel: SidebarLinkType[] = [
  {
    path: AppRoute.InstituteOffers,
    text: translate(translationKeys.sidebar.tutoringInstitute.myOffers),
    Icon: <TagOutlined style={inactiveColor} size={20} />,
    IconActive: <TagOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.InstituteSettings,
    text: translate(translationKeys.sidebar.tutoringInstitute.settings),
    Icon: <DollarCircleOutlined style={inactiveColor} size={20} />,
    IconActive: <DollarCircleOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.UserProfile,
    text: translate(translationKeys.sidebar.tutoringInstitute.myProfile),
    Icon: <UserOutlined style={inactiveColor} size={20} />,
    IconActive: <UserOutlined style={activeColor} size={20} />,
  },
];

const tutoringInstituteSidebarOptionsPPLModel: SidebarLinkType[] = [
  {
    path: AppRoute.MyOfferRequests,
    text: translate(translationKeys.sidebar.tutoringInstitute.messages),
    Icon: <MailOutlined style={inactiveColor} size={20} />,
    IconActive: <MailOutlined style={activeColor} size={20} />,
  },
  ...tutoringInstituteSidebarOptionsSubscriptionModel,
];

export const regularUserSidebar: SidebarLinkType[] = [
  {
    path: AppRoute.MyOfferRequests,
    text: translate(translationKeys.sidebar.regularUser.myMessages),
    Icon: <MailOutlined style={inactiveColor} size={20} />,
    IconActive: <MailOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.MyOffer,
    text: translate(translationKeys.sidebar.regularUser.myOffer),
    Icon: <TagOutlined style={inactiveColor} size={20} />,
    IconActive: <TagOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.UserProfile,
    text: translate(translationKeys.sidebar.regularUser.myProfile),
    Icon: <UserOutlined style={inactiveColor} size={20} />,
    IconActive: <UserOutlined style={activeColor} size={20} />,
  },
];

export const elitetutorUserSidebarTop: SidebarLinkType[] = [
  {
    path: generatePath(AppRoute.UnitOverview, { viewAs: ViewAs.TUTOR }),
    text: translate(translationKeys.sidebar.elitetutor.unitOverview),
    Icon: <ClockCircleOutlined style={inactiveColor} size={20} />,
    IconActive: <ClockCircleOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.Earnings,
    text: translate(translationKeys.sidebar.elitetutor.earnings),
    Icon: <CreditCardOutlined style={inactiveColor} size={20} />,
    IconActive: <CreditCardOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.EarnMoney,
    text: translate(translationKeys.sidebar.elitetutor.earnMoney),
    Icon: <ClockCircleOutlined style={inactiveColor} size={20} />,
    IconActive: <ClockCircleOutlined style={activeColor} size={20} />,
    showBadge: true,
  },
];
export const elitetutorUserSidebarSubjects: SidebarLinkType[] = [
  {
    path: AppRoute.MySubjects,
    text: translate(translationKeys.sidebar.elitetutor.mySubjects),
    Icon: <FileDoneOutlined style={inactiveColor} size={20} />,
    IconActive: <FileDoneOutlined style={activeColor} size={20} />,
  },
];
export const elitetutorUserSidebarBottom: SidebarLinkType[] = [
  {
    path: AppRoute.External_FaqElitetutor,
    text: translate(translationKeys.sidebar.elitetutor.faq),
    Icon: <QuestionOutlined style={inactiveColor} size={20} />,
    IconActive: <QuestionOutlined style={activeColor} size={20} />,
  },
];

export const eliteseekerUserSidebar: SidebarLinkType[] = [
  {
    path: generatePath(AppRoute.UnitOverview, { viewAs: ViewAs.SEEKER }),
    text: translate(translationKeys.sidebar.eliteseeker.unitOverview),
    Icon: <ClockCircleOutlined style={inactiveColor} size={20} />,
    IconActive: <ClockCircleOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.Billing,
    text: translate(translationKeys.sidebar.eliteseeker.billing),
    Icon: <CreditCardOutlined style={inactiveColor} size={20} />,
    IconActive: <CreditCardOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.MyPricing,
    text: translate(translationKeys.sidebar.eliteseeker.myPricing),
    Icon: <DropboxOutlined style={inactiveColor} size={20} />,
    IconActive: <DropboxOutlined style={activeColor} size={20} />,
  },
  {
    path: AppRoute.FAQ,
    text: translate(translationKeys.sidebar.eliteseeker.faq),
    Icon: <QuestionOutlined style={inactiveColor} size={20} />,
    IconActive: <QuestionOutlined style={activeColor} size={20} />,
  },
];

export const getSidebarLinks = (
  accountType: AccountTypeStringLiteral,
  accountRights: AccountRights | undefined,
  paymentModel?: 'PPL' | 'STANDARD'
): SideBarLinks => {
  if (accountType === AccountType.TUTORING_INSTITUTE && paymentModel === 'PPL')
    return {
      regular: tutoringInstituteSidebarOptionsPPLModel,
      eliteTutor: [],
      eliteSeeker: [],
    };
  if (accountType === AccountType.TUTORING_INSTITUTE)
    return {
      regular: tutoringInstituteSidebarOptionsSubscriptionModel,
      eliteTutor: [],
      eliteSeeker: [],
    };

  let eliteTutorOptions: SidebarLinkType[] = [];
  let eliteSeekerOptions: SidebarLinkType[] = [];

  if (accountRights?.includes(AccountRight.ELITETUROR)) {
    eliteTutorOptions = [...elitetutorUserSidebarTop, ...elitetutorUserSidebarSubjects, ...elitetutorUserSidebarBottom];
  } else if (accountRights?.includes(AccountRight.ELITETUTOR_SUBJECTS)) {
    eliteTutorOptions = [...elitetutorUserSidebarSubjects];
  }
  if (accountRights?.includes(AccountRight.ELITESEEKER)) {
    eliteSeekerOptions = [...eliteseekerUserSidebar];
  }

  return {
    regular: regularUserSidebar,
    eliteTutor: eliteTutorOptions,
    eliteSeeker: eliteSeekerOptions,
  };
};
