import { User } from 'logic/api-models/api-models';

export const shouldUpdateUserPhoneNumber = (
  oldNumber: string | undefined,
  newNumber: string | undefined
) => !!newNumber && (!oldNumber || oldNumber !== newNumber);

export const shouldVerifyPhone = (user: User | undefined) => {
  return !!user ? !user.isLegacyUser && !user.isPhoneNumberVerified : undefined;
};

export const shouldChooseUserType = (user: User | undefined) => {
  return !!user ? !user.isLegacyUser && !user.accountType : undefined;
};

export const haveUserAnOffer = (user: User | undefined) =>
  !!user
    ? user?.offer?._id !== undefined && user?.offer?._id !== null && user?.offer?._id !== ''
    : undefined;
