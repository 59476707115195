import { Typography } from 'antd';
import { TextAlignType } from 'src/pages-all/static/components';
import styled from 'styled-components';
import { colors } from 'styles/colors';

interface HeadingProps {
  align?: TextAlignType;
  size?: number;
  color?: string;
}

export const Heading = styled(Typography.Title)<HeadingProps>`
  &.ant-typography {
    font-size: ${({ size }) => `${size ? size : 26}px`};
    font-weight: 700;
    color: ${({ color }) => (color ? color : colors.primaryDark)};
    margin-bottom: 20px;
    text-align: ${({ align }) => align && align};
  }
`;
