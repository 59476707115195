import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UserRatingsResponse,
  UserRatingFormResponse,
  RateTutorRequest,
} from 'logic/api-models/api-models';

export interface RatingState {
  form?: UserRatingFormResponse;
  ratings: UserRatingsResponse['ratings'];
  badges: UserRatingsResponse['badges'];
  avgRating?: UserRatingsResponse['avgRating'];
  totalRatings?: number;
}

const initialState: RatingState = {
  ratings: [],
  badges: {},
  avgRating: 0,
  totalRatings: 0,
};

export const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {
    fetchUserRatings: (_state, _action: PayloadAction<{ offerId: string }>) => undefined,
    setUserRatings: (state, action: PayloadAction<{ userRatings?: UserRatingsResponse }>) => {
      if (action.payload.userRatings) {
        const { avgRating, badges, ratings } = action.payload.userRatings;
        state.avgRating = avgRating;
        state.badges = badges;
        state.ratings = ratings;
        state.totalRatings = ratings.length;
      }
    },
    fetchRatingForm: (_state, _action: PayloadAction<{ uniqId: string }>) => {},
    setUserRatingsForm: (
      state,
      action: PayloadAction<{ form: UserRatingFormResponse | undefined }>
    ) => {
      state.form = action.payload.form;
    },
    sendRating: (
      _state,
      _action: PayloadAction<{ uniqId: string; rateObject: RateTutorRequest }>
    ) => {},
  },
});
