import { env } from '../env/envs';
import { createAxios } from './axios-create';
import { getCommonHeaders } from './axios-headers-common';

export const axiosApiClient = (headers?: object, baseURL?: string) =>
  createAxios({
    baseURL: baseURL || env.GATSBY_REACT_APP_API_URL,
    headers: {
      ...getCommonHeaders(),
      ...headers,
    },
  });
