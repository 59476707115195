import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { logError } from 'logic/log/log.logic';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { ErrorHandler } from './error-handler';

interface ForbiddenErrorData {
  statusCode?: number;
  error?: string;
  message?: string;
}

export const isForbiddenError = (error: AxiosError<ForbiddenErrorData>) =>
  getApiErrorStatusCode(error) === StatusCodes.FORBIDDEN &&
  error.response?.data.statusCode === StatusCodes.FORBIDDEN;

export const forbiddenErrorHandler: ErrorHandler<AxiosError<ForbiddenErrorData>> = (error) => {
  if (error && isForbiddenError(error)) {
    logError(error.response?.data?.message || translate(translationKeys.errors.forbiddenError));
    return true;
  }

  return false;
};
