import styled from 'styled-components';
import { colors } from 'styles/colors';

export const RowWrapper = styled.li`
  min-height: 52px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 100px 100px 85px 85px 75px 60px;
  grid-row: 1;
  align-items: center;
  grid-gap: 8px;
  padding: 12px 24px;
  line-height: 1.15;

  &:nth-child(even) {
    background-color: ${colors.catskillWhite};
  }
`;

export const INSTITUTE_OFFER_PAGE_SIZE = 10;
