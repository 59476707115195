import Big from 'big.js';
import { clearEmptyFields } from 'logic/validators/utils';
import { FormErrors } from 'redux-form';
import { isRequiredValidator } from 'logic/validators/validators';
import {
  CouponDiscount,
  ElitepaymentChangeSubscriptionRequest,
  ElitepaymentCreateSubscriptionIntentRequest,
  ElitepaymentPlan,
  ElitepaymentPrice,
  GetPersonalizedSubscriptionTokenDataResponse,
} from 'logic/api-models/api-models';

export const MY_PRICING_FORM_NAME = 'my-pricing-form';

export interface MyPricingFormValues {
  [MyPricingFormFields.lessonUnitCount]?: number;
  [MyPricingFormFields.plan]?: ElitepaymentPlan;
}

export type MyPricingFormValidationErrors = FormErrors<MyPricingFormValues, string>;

export const validateMyPricingForm = (formValues: MyPricingFormValues) => {
  const { lessonUnitCount, plan } = formValues;

  const errors: MyPricingFormValidationErrors = {
    lessonUnitCount: isRequiredValidator(lessonUnitCount),
    plan: isRequiredValidator(plan),
  };

  return clearEmptyFields(errors);
};

export enum MyPricingFormFields {
  lessonUnitCount = 'lessonUnitCount',
  plan = 'plan',
}

// TODO: deprecate and delete this function -> use parsePriceWithUnit / formatPrice instead of this function
// only works for euro
export const parsePrice = (price?: ElitepaymentPrice) => {
  const priceUnit = parseFloat(price?.unitAmount?.toString() || '')
    .toFixed(2)
    .toString()
    .replace('.', ',');

  return priceUnit + (price?.currency === 'EUR' ? '\xa0€' : '');
};

const formatPrice = (price: number) => {
  // use Big.js to round because it works more consistently than toFixed (roundHalfUp)
  return new Big(price).round(2).toNumber().toFixed(2).replace('.', ',');
};

export const parsePriceWithUnit = (price: number | null, currency?: string) => {
  if (price == null || currency !== 'EUR') return null;

  const totalPriceUnit = formatPrice(price);
  return totalPriceUnit + '\xa0€';
};

// only works for euro
export const parseTotalMonthlyPriceWithUnitWithoutCoupon = (
  lessonUnits?: number,
  price?: ElitepaymentPrice
) => {
  if (lessonUnits == null || price == null || price.currency !== 'EUR') return null;

  const monthlyPrice = new Big(price.unitAmount).times(lessonUnits).toNumber();
  const formattedMonthlyPrice = formatPrice(monthlyPrice);

  return formattedMonthlyPrice + '\xa0€';
};

// only works for euro
export const calculateAndParseAbsolutDiscountPrice = (
  lessonUnitCount?: number,
  priceA?: ElitepaymentPrice,
  priceB?: ElitepaymentPrice
) => {
  if (
    lessonUnitCount == null ||
    priceA == null ||
    priceB == null ||
    priceA.currency !== 'EUR' ||
    priceB.currency !== 'EUR'
  ) {
    return null;
  }

  const price = new Big(priceA.unitAmount).minus(priceB.unitAmount).toNumber();
  const absoluteDiscountPrice = new Big(price).times(lessonUnitCount).toNumber();
  const formattedAbsoluteDiscountPrice = formatPrice(absoluteDiscountPrice);

  return formattedAbsoluteDiscountPrice + '\xa0€';
};

export const calculateAndParseRelativeDiscountRatio = (
  priceA?: ElitepaymentPrice,
  priceB?: ElitepaymentPrice
) => {
  if (priceA == null || priceB == null) return null;

  const discountRatio = new Big(priceA.unitAmount).div(priceB.unitAmount);
  const formattedDiscount = new Big(1).minus(discountRatio).times(100).round().toString();

  return formattedDiscount + '\xa0%';
};

export const calculateTotalPriceWithoutCoupon = (
  lessonUnitCount?: number,
  price?: ElitepaymentPrice
) => {
  if (lessonUnitCount == null || price == null) return null;

  return new Big(price.unitAmount).times(lessonUnitCount).toNumber();
};

export const calculateAbsoluteCouponDiscount = (
  price: number,
  discount?: CouponDiscount
): number | null => {
  if (discount == null) {
    return null;
  }

  if (discount.type === 'percent' && discount.percent) {
    return new Big(discount.percent).div(100).times(price).round(2).toNumber();
  } else if (discount.type === 'fixed' && discount.currencies) {
    const euroCurrency = discount.currencies.find((currency) => currency.currency === 'EUR');
    if (!euroCurrency) return null;

    return euroCurrency.amount ?? null;
  }

  return null;
};

export const calculateTotalMonthlyPriceAfterCouponDiscount = (
  price: number,
  absoluteDiscount: number | null
) => {
  if (absoluteDiscount == null) {
    return null;
  }

  return new Big(price).minus(absoluteDiscount).toNumber();
};

export const generateCouponDiscountText = (
  personalizedSubscriptionData: GetPersonalizedSubscriptionTokenDataResponse | null,
  absoluteCouponDiscountWithUnit: string | null
) => {
  if (!personalizedSubscriptionData?.discount) {
    return null;
  }

  if (personalizedSubscriptionData.discount.type === 'percent') {
    return `${personalizedSubscriptionData.discount.percent}\xa0%`;
  } else if (
    personalizedSubscriptionData.discount.type === 'fixed' &&
    absoluteCouponDiscountWithUnit
  ) {
    return absoluteCouponDiscountWithUnit;
  }

  return null;
};

export const mapMyPricingFormToRequest: (
  formValues: MyPricingFormValues | undefined,
  couponCode?: string
) => ElitepaymentCreateSubscriptionIntentRequest = (formValues, couponCode) => {
  const request: ElitepaymentCreateSubscriptionIntentRequest = {
    planId: formValues?.plan?.id || '',
    planVersionHash: formValues?.plan?.versionHash || '',
    elitehoursPerMonth: formValues?.lessonUnitCount || 0,
    couponCode,
  };

  return request;
};

export const mapMyPricingFormToSubsciptionChangeRequest: (
  formValues?: MyPricingFormValues,
  subscriptionVersionHash?: string,
  couponCode?: string
) => ElitepaymentChangeSubscriptionRequest = (formValues, subscriptionVersionHash) => {
  const request: ElitepaymentChangeSubscriptionRequest = {
    elitehoursPerMonth: formValues?.lessonUnitCount || 0,
    planVersionHash: formValues?.plan?.versionHash || '',
    subscriptionVersionHash: subscriptionVersionHash || '',
    planId: formValues?.plan?.id || '',
  };

  return request;
};
