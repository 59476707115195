import { combineEpics } from 'redux-observable';
import { filter, map, tap } from 'rxjs/operators';
import { setFirebaseUserId } from 'logic/analytics/analytics';
import { getAppVersion } from '../../version/version';
import { getAuthenticatedUserId } from '../authentication/authentication.selectors';
import { initializeSlice } from './initialize.slice';
import { initGDPR } from 'logic/cookies/cookies';
import { RootEpic } from 'app/app.epics.type';
import { subjectSlice } from '../subject/subject.slice';

const initializeEpic$: RootEpic = (action$, state$, { managed }) =>
  action$.pipe(
    filter(initializeSlice.actions.initialize.match),
    managed(
      initializeSlice.actions.initialize,
      tap(() => {
        initGDPR();
        const userId = getAuthenticatedUserId(state$.value);
        setFirebaseUserId(userId);
        console.log(`current appVersion: ${getAppVersion()}`);
      })
    ),
    map(() => subjectSlice.actions.fetchSubjects())
  );

export const initializeEpics$ = combineEpics(initializeEpic$);
