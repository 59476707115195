import styled from 'styled-components';
import { Layout } from 'antd';
import { colors } from 'src/styles/colors';

const { Content } = Layout;

export const MainLayout = styled(Content)`
  display: flex;
  justify-content: center;
  background-color: ${colors.catskillWhite};
`;
