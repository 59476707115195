import { env } from 'logic/env/envs';
import { parseNumber } from './number';
import { isString, trimSlahes } from './string';

export const FILE_SIZE_POWER_BASE = 1000;

export const formatFileSize = (
  fileSizeInBytesValue: string | number | null | undefined,
  megaBytesDecimalPlaces = 1
) => {
  const fileSizeInBytes = parseNumber(fileSizeInBytesValue);

  if (fileSizeInBytes === undefined) {
    return undefined;
  }

  const fileSizeInKBytes = Math.round(fileSizeInBytes / FILE_SIZE_POWER_BASE);
  const fileSizeInMBytes = fileSizeInBytes / FILE_SIZE_POWER_BASE / FILE_SIZE_POWER_BASE;

  if (fileSizeInBytes < FILE_SIZE_POWER_BASE) {
    return `${fileSizeInBytes} B`;
  }

  if (fileSizeInKBytes < FILE_SIZE_POWER_BASE) {
    return `${fileSizeInKBytes} KB`;
  }

  return `${fileSizeInMBytes.toFixed(megaBytesDecimalPlaces)} MB`;
};

export const getAbsoluteFileUrlCreator = (apiUrl: string | undefined) => (
  url: string | undefined
) => {
  if (!url || !isString(url)) return url;

  const isRelative = !url.includes('http');

  if (isRelative && (!apiUrl || !isString(apiUrl))) return url;

  return isRelative ? `${trimSlahes(apiUrl)}/${trimSlahes(url)}` : url;
};

export const getAbsoluteFileUrl = getAbsoluteFileUrlCreator(env.GATSBY_REACT_APP_API_URL);
