import { FormStateMap, reducer as formReducer } from 'redux-form';
import { offerSlice, OfferState } from 'logic/store/offer/offer.slice';
import { ratingSlice, RatingState } from 'logic/store/rating/rating.slice';
import { subjectSlice, SubjectState } from 'logic/store/subject/subject.slice';
import {
  authenticationSlice,
  AuthenticationState,
} from '../logic/store/authentication/authentication.slice';
import { geolocationSlice, GeolocationState } from '../logic/store/geolocation/geolocation.slice';
import { initializeSlice, InitializeState } from '../logic/store/initialize/initialize.slice';
import { modalSlice, ModalState } from '../logic/store/modal/modal.slice';
import { trackerSlice, TrackerState } from '../logic/store/tracker/tracker.slice';
import { stepFlowSlice, StepFlowState } from 'logic/store/step-flow/step-flow.slice';
import { pdfSlice, PdfState } from 'logic/store/pdf/pdf.slice';
import {
  requestTutoringSlice,
  RequestTutoringState,
} from 'logic/store/request-tutoring/request-tutoring.slice';
import { instituteSlice, InstituteState } from 'logic/store/institute/institute.slice';
import { PaymentState, paymentSlice } from 'logic/store/payment/payment.slice';
import { ConferenceState, conferenceSlice } from 'logic/store/conference/conference.slice';
import { elitetutorSlice, ElitetutorState } from 'logic/store/elitetutor/elitetutor.slice';

export interface StoreState {
  initialize: InitializeState;
  authentication: AuthenticationState;
  modal: ModalState;
  geolocation: GeolocationState;
  subject: SubjectState;
  offer: OfferState;
  stepFlow: StepFlowState;
  form: FormStateMap;
  tracker: TrackerState;
  rating: RatingState;
  pdf: PdfState;
  requestTutoring: RequestTutoringState;
  institute: InstituteState;
  payment: PaymentState;
  conference: ConferenceState
  elitetutor: ElitetutorState,
}

export const reducers = {
  initialize: initializeSlice.reducer,
  authentication: authenticationSlice.reducer,
  modal: modalSlice.reducer,
  geolocation: geolocationSlice.reducer,
  subject: subjectSlice.reducer,
  offer: offerSlice.reducer,
  stepFlow: stepFlowSlice.reducer,
  form: formReducer.plugin({}),
  tracker: trackerSlice.reducer,
  rating: ratingSlice.reducer,
  pdf: pdfSlice.reducer,
  requestTutoring: requestTutoringSlice.reducer,
  institute: instituteSlice.reducer,
  payment: paymentSlice.reducer,
  conference: conferenceSlice.reducer,
  elitetutor: elitetutorSlice.reducer,
};
