import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestTutoringSteps } from '../offer/offer.model';
import { OfferResponse } from 'logic/api-models/api-models';

export interface RequestTutoringState {
  requestTutoringCurrIdx: number;
  requestTutoringFlow: RequestTutoringSteps[];
}

const initialState: RequestTutoringState = {
  requestTutoringCurrIdx: 0,
  requestTutoringFlow: [],
};

export const requestTutoringSlice = createSlice({
  name: 'request-tutoring',
  initialState,
  reducers: {
    reset: () => initialState,
    initRequestTutoringFlow: (_state, _action: PayloadAction<{ offer: OfferResponse, isRefugeeTutor: boolean }>) => undefined,
    moveToNextTutoringRequestFlowStep: (_state, _action: PayloadAction) => undefined,
    moveToPreviousTutoringRequestFlowStep: (_state, _action: PayloadAction) => undefined,
    setRequestTutoringCurrIdx: (_state, _action: PayloadAction<number>) => {
      _state.requestTutoringCurrIdx = _action.payload;
    },
    setRequestTutoringFlow: (_state, _action: PayloadAction<RequestTutoringSteps[]>) => {
      _state.requestTutoringFlow = _action.payload;
    },
  },
});
