import { isEmpty } from 'lodash';
import { flatten } from 'logic/utils/flatten';
import { Store } from 'redux';
import { getFormValues, stopSubmit } from 'redux-form';
import { filter } from 'rxjs/operators';
import { OfValidReduxFormWitchStore } from './of-valid-redux-form.operator.type';
import { showReduxFormErrorsAction } from './show-redux-form-errors.action';
import { logSlice } from 'logic/store/log/log.slice';

export const ofValidReduxFormWitchStore: (store: Store) => OfValidReduxFormWitchStore = (store) => (
  formName,
  formValidator,
  customErrorMessage
) => (source) =>
  source.pipe(
    filter(() => {
      const formValues = getFormValues(formName)(store.getState()) || {};

      const errors = formValidator(formValues);
      const hasErrors = !isEmpty(errors);

      if (hasErrors) {
        store.dispatch(showReduxFormErrorsAction(formName, Object.keys(flatten(errors))));
        store.dispatch(stopSubmit(formName, errors));
        if (customErrorMessage) {
          store.dispatch(logSlice.actions.logError({ message: customErrorMessage }));
        }
        return false;
      } else {
        return true;
      }
    })
  );
