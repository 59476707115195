import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { GroupWrap } from 'components/group-wrap/group-wrap';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { Text } from 'components/text/Text';
import { Stack } from 'components/stack/stack';
import { AppRoute } from 'app/app.route.const';
import { colors } from 'styles/colors';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';
import { SocialButtons } from 'src/pages-all/master-page/components/master-footer/SocialButtons';
import { LOGO_WITH_TEXT_BLACK } from 'assets/icons/icons';

const Container = styled.div<{ noBorderTop?: boolean}>`
  margin-top: ${({ noBorderTop }) => noBorderTop ? 0 : 32}px;
  margin-bottom: 16px;
  padding-top: ${({ noBorderTop }) => noBorderTop ? 0 : 32}px;
  border-top: ${({ noBorderTop }) => noBorderTop ? 0 : 1}px solid ${colors.black};
`;

const FooterStripContainer = styled(GroupWrap)<{ minTablet: boolean }>`
  flex-direction: ${({ minTablet }) => (minTablet ? 'row' : 'column')};
  justify-content: ${({ minTablet }) => (minTablet ? 'normal' : 'center')};
  margin: 0 8px 0 8px;
  .footer-strip-container {
    margin: 0;
    ${({ minTablet }) =>
      minTablet &&
      css`
        &:nth-child(2) {
          display: flex;
          justify-content: flex-end;
          flex: 1;
        }
      `}
  }
`;

const SocialButtonWrapperBottom = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const FooterPulledRight = styled(Stack)<{ minTablet: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: ${({ minTablet }) => (minTablet ? 'flex-end' : 'center')};
  justify-content: center;
  color: ${colors.primary};
`;

const TutorSpace = styled(Link)`
  img {
    height: 26px;
    width: auto;
  }
  margin-right: 8px;
  overflow: hidden;
`;

const FooterLink = styled(Link)``;

const FooterLinkWrapper = styled.div<{ minTablet: boolean }>`
  ${({ minTablet }) =>
    minTablet
      ? ''
      : css`
          width: 100%;
          text-align: center;
          overflow: hidden;
        `}
`;

export const FooterStrip: React.FC<{ noBorderTop?: boolean }> = ({ noBorderTop }) => {
  const { minTablet } = useBreakpoints();

  return (
    <Container noBorderTop={noBorderTop}>
      <FooterStripContainer gap={0.5} classes="footer-strip-container" minTablet={minTablet}>
        <Stack gap={minTablet ? 0.5 : 1} flow={'row'} textAlign={minTablet ? 'left' : 'center'} style={{ margin: minTablet ? '' : '15px' }}>
          <TutorSpace to="/">
            <img src={LOGO_WITH_TEXT_BLACK} alt="logo" loading="lazy" />
          </TutorSpace>
          <Text>Copyright @ {`${new Date().getFullYear()}`} TutorSpace GmbH</Text>
        </Stack>
        <FooterPulledRight gap={0.5} minTablet={minTablet}>
          {minTablet && <SocialButtons />}
          <Stack gap={1} flow={minTablet ? 'column' : 'row'} alignItems={'center'}>
            <FooterLinkWrapper minTablet={minTablet}>
              <FooterLink to={AppRoute.FooterImpressum}>
                <Text size={14} strong>
                  {translate(translationKeys.footer.links.imprint)}
                </Text>
              </FooterLink>
            </FooterLinkWrapper>
            <FooterLinkWrapper minTablet={minTablet}>
              <FooterLink to={AppRoute.FooterTOS}>
                <Text size={14} strong>
                  {translate(translationKeys.footer.links.termsOfUse)}
                </Text>
              </FooterLink>
            </FooterLinkWrapper>
            <FooterLinkWrapper minTablet={minTablet}>
              <FooterLink to={AppRoute.FooterDataProtection}>
                <Text size={14} strong>
                  {translate(translationKeys.footer.links.dataProtection)}
                </Text>
              </FooterLink>
            </FooterLinkWrapper>
          </Stack>
          {!minTablet && (
            <SocialButtonWrapperBottom>
              <SocialButtons />
            </SocialButtonWrapperBottom>
          )}
        </FooterPulledRight>
      </FooterStripContainer>
    </Container>
  );
};
