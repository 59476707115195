import { ButtonProps } from 'antd/lib/button';
import { showConfirmModal } from 'logic/modals/confirm-modal/confirm-modal';
import { translate, translationKeys } from 'logic/translations/translations.service';
import React from 'react';

interface DialogResultHandlers {
  onDialogResponseCancel?: ButtonProps['onClick'];
  onDialogResponseOK?: ButtonProps['onClick'];
}

export const handleDeleteAccountDialog = (dialogResultHandlers: DialogResultHandlers) => {
  showConfirmModal({
    width: 340,
    icon: null,
    title: (
      <>
        {translate(translationKeys.userProfile.deleteAccount.confirmation.title1)}
        <br />
        {translate(translationKeys.userProfile.deleteAccount.confirmation.title2)}
      </>
    ),
    content: (
      <>
        <br />
        {translate(translationKeys.userProfile.deleteAccount.confirmation.description1)}
        <br />
        <br />
        {translate(translationKeys.userProfile.deleteAccount.confirmation.description2)}
        <br />
      </>
    ),
    buttons: [
      {
        text: translate(translationKeys.userProfile.deleteAccount.confirmation.cancelButton),
        onClick: dialogResultHandlers.onDialogResponseCancel,
        type: 'primary',
      },
      {
        text: translate(translationKeys.userProfile.deleteAccount.confirmation.okButton),
        onClick: dialogResultHandlers.onDialogResponseOK,
      },
    ],
  });
};
