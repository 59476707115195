import { AppRoute } from 'app/app.route.const';
import { useUtms } from 'logic/hooks/use-utms';

export const useHomeActionUrl = () => {
  const { utmQueryString } = useUtms();

  const homeActionUrl = utmQueryString
    ? `${AppRoute.External_TrialHour}${utmQueryString}`
    : AppRoute.External_TrialHour_Home;

  return { homeActionUrl };
};
