import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OffersListResponse } from 'logic/api-models/api-models';
import { InstituteOfferFormValues } from 'src/pages-all/offer-form/institute-offer-form-page/institute-offer-form.logic';

export interface InstituteState {
  offers?: OffersListResponse;
  currentOfferPage?: number;
}

const initialState: InstituteState = {};

export const instituteSlice = createSlice({
  name: 'institute',
  initialState,
  reducers: {
    reset: () => initialState,
    createInstituteOffer: (
      _state,
      _action: PayloadAction<{ formValues: InstituteOfferFormValues | undefined }>
    ) => {},
    updateInstituteOffer: (
      _state,
      _action: PayloadAction<{ formValues: InstituteOfferFormValues | undefined }>
    ) => {},
    toggleInstituteOfferActive: (
      _state,
      _action: PayloadAction<{ id: string; isActive: boolean }>
    ) => {},
    fetchInstituteOffers: (
      _state,
      _action: PayloadAction<{ page?: number; pageSize?: number }>
    ) => {},
    setInstituteOffers: (state, action: PayloadAction<{ response: OffersListResponse | undefined }>) => {
      state.offers = action.payload.response;
    },
    fetchInstituteOfferForEdit: (_state, _action: PayloadAction<{ id: string | undefined }>) => {},
    tryRemoveOffer: (_state, _action: PayloadAction<string | undefined>) => {},
    removeOffer: (_state, _action: PayloadAction<string | undefined>) => {},
    setInstituteOfferPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.currentOfferPage = action.payload;
      },
  },
});
