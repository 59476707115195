import { axiosWebClient } from '../../axios/axios-web-guest-client';
import { env, isDevelopment } from '../../env/envs';
import { MapBoxPlace, MapBoxPlacesResponse } from './geolocation.model';

export const geolocationApi = {
  fetchPlaces: (searchPhrase: string, searchPlaces: MapBoxPlace[] | undefined) => {
    const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
    const searchPhraseParam = `${encodeURI(searchPhrase)}.json`;
    const accessToken = `?access_token=${env.GATSBY_REACT_APP_MAP_BOX_TOKEN}`;
    const autocomplete = '&autocomplete=true';
    const country = isDevelopment()
      ? `&country=${encodeURI('de,at,ch,pl')}`
      : `&country=${encodeURI('de,at,ch')}`;
    const types = searchPlaces ? `&types=${searchPlaces.join(',')}` : '';
    const language = isDevelopment() ? '&language=de,pl' : '&language=de';

    return axiosWebClient().get<MapBoxPlacesResponse>(
      `${url}/${searchPhraseParam}${accessToken}${autocomplete}${country}${types}${language}`
    );
  },
  getBrowserGeolocation: () =>
    new Promise<GeolocationPosition>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    ),
};
