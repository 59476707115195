import { authenticationSlice } from '../authentication.slice';
import { store } from 'app/app.store';

export const logInWithAppleIfCan = () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const login = urlParams.get('login');

    if (login) {
      const data = JSON.parse(new Buffer(login, 'base64').toString());
      store.dispatch(authenticationSlice.actions.loginWithAppleResponse({ response: data }));
    }
  } finally {
  }
};
