import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import styled from 'styled-components';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { Stack } from 'components/stack/stack';
import { Text } from 'components/text/Text';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';
import { GroupWrap } from 'components/group-wrap/group-wrap';
import { AppRoute } from 'app/app.route.const';
import { SubjectType } from 'models/subject-type';
import { getSubjectName, getSubjectSlug } from 'logic/store/subject/subject.logic';
import { SubjectsByNumber } from 'logic/store/subject/subject.model';
import { getSubjectsByNumber } from 'logic/store/subject/subject.selectors';
import { FooterStrip } from './footer-strip';
import { colors } from 'styles/colors';
import { fontSize } from 'styles/theme';
import { GOLDENCUP } from 'assets/icons/icons';
import { CustomIcon } from 'components/custom-icon/CustomIcon';
import { slugifyText } from 'logic/slugify-text/slugify-text';

const { Footer } = Layout;

type FooterSectionItem = {
  title: string;
  url: string;
  noGatsby?: boolean;
  action?: () => void;
};

type FooterSection = {
  title: string;
  subItems: FooterSectionItem[];
};

const getSubjects = (subjectsByNumber: SubjectsByNumber = {}, online?: boolean): FooterSection => {
  const subjects = [
    SubjectType.Math,
    SubjectType.English,
    SubjectType.GermanSchool,
    SubjectType.Physics,
    SubjectType.French,
    SubjectType.Spanish,
    SubjectType.Chemistry,
  ];

  const onlineTitlePrefix = translate(translationKeys.footer.subjects.onlineTitle);
  const title = translate(
    online ? translationKeys.footer.subjects.onlineTitle : translationKeys.footer.subjects.title
  );

  return {
    title,
    subItems: subjects.map((subject) => {
      const title = getSubjectName(subjectsByNumber, subject);
      const titleOnline = `${onlineTitlePrefix} ${getSubjectName(subjectsByNumber, subject).replace(
        ' (Schule)',
        ''
      )}`;
      return {
        title: online ? titleOnline : title,
        url: generatePath(
          online ? AppRoute.SearchOnlineTutors : AppRoute.SearchLocalTutorsBySubject,
          {
            subject: getSubjectSlug(subjectsByNumber, subject),
          }
        ),
      };
    }),
  };
};

const localTutoring: FooterSection = {
  title: translate(translationKeys.footer.localTutoring.title),
  subItems: [
    {
      title: translate(translationKeys.footer.localTutoring.subItems.compareFree),
      url: AppRoute.Home,
    },
    {
      title: translate(translationKeys.footer.localTutoring.subItems.howItWorks),
      url: AppRoute.Home + '#how-to-section',
    },
    {
      title: translate(translationKeys.footer.localTutoring.subItems.becomeATutor),
      url: AppRoute.ForTutorsBecomeTutor,
    },
    {
      title: translate(translationKeys.footer.localTutoring.subItems.cities),
      url: AppRoute.Cities,
    },
  ],
};

const onlineTutoring: FooterSection = {
  title: translate(translationKeys.footer.onlineTutoring.title),
  subItems: [
    {
      title: translate(translationKeys.footer.onlineTutoring.subItems.bookAFreeTrialLesson),
      url: AppRoute.Home,
    },
    {
      title: translate(translationKeys.footer.onlineTutoring.subItems.becomeAnEliteTutor),
      url: AppRoute.TutorspacePremiumBecome,
    },
    {
      title: translate(translationKeys.footer.onlineTutoring.subItems.onlineTutoring),
      url: AppRoute.Home,
    },
  ],
};

const aboutTutorSpace: FooterSection = {
  title: translate(translationKeys.footer.aboutTutorSpace.title),
  subItems: [
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.aboutUs),
      url: AppRoute.AboutUs,
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.jobs),
      url: AppRoute.Jobs,
      noGatsby: true,
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.donationInitiative),
      url: AppRoute.DonationInitiative,
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.faq),
      url: AppRoute.FAQ,
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.blog),
      url: AppRoute.Blog,
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.contact),
      url: 'mailto:support@tutorspace.de',
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.press),
      url: AppRoute.Press,
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.alena),
      url: AppRoute.EXTERNAL_ALENA,
    },
    {
      title: translate(translationKeys.footer.aboutTutorSpace.subItems.founder),
      url: AppRoute.PatrickNadler,
    },
  ],
};

const citys: FooterSection = {
  title: translate(translationKeys.footer.citys.title),
  subItems: Object.entries(translationKeys.footer.citys.cityList).map(([i, translateKey]) => ({
    title: translate(translateKey),
    url: AppRoute.Home + slugifyText(translate(translateKey)) + '/nachhilfe',
  })),
};

const FakeLink = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const FakeButton = styled.span`
  border-radius: 4px;
  color: ${colors.white};
  background: ${colors.primary};
  padding: 3px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: ${fontSize.sm};
`;

const RewardDiv = styled.div<{ minTablet: boolean }>`
  display: flex;
  flex-direction: ${({ minTablet }) => (minTablet ? 'row' : 'column')};
  margin: ${({ minTablet }) => (minTablet ? '' : '0 auto')};
  gap: 5px;
  width: 80%;
`;

const RewardItemDiv = styled.div`
  font-size: ${fontSize.sm};
`;

const Section: React.FC<{ section: FooterSection; recommended?: boolean }> = ({
  section,
  recommended,
}) => {
  const { minTablet } = useBreakpoints();

  return (
    <Stack
      gap={0.5}
      flow="row"
      justifyContent={'start'}
      textAlign={minTablet ? 'left' : 'center'}
      alignItems="center"
      style={{ padding: '0 8px' }}
    >
      <Text size={18} strong>
        {section.title}{' '}
        {recommended && minTablet && (
          <FakeButton>{translate(translationKeys.footer.onlineTutoring.extra)}</FakeButton>
        )}
      </Text>
      {section.subItems.map((item: FooterSectionItem, index) =>
        item.action ? (
          <FakeLink key={index} onClick={item.action}>
            <Text size={14}>{item.title}</Text>
          </FakeLink>
        ) : item.noGatsby ? (
          <a key={index} href={item.url}>
            <Text size={14}>{item.title}</Text>
          </a>
        ) : (
          <a key={index} href={item.url}>
            <Text size={14}>{item.title}</Text>
          </a>
        )
      )}
      {recommended && (
        <RewardDiv minTablet={minTablet}>
          <RewardItemDiv>
            <CustomIcon height={40} src={GOLDENCUP} />
          </RewardItemDiv>
          <RewardItemDiv>
            <span>{translate(translationKeys.footer.rewards.bestTututors.title)}</span>
          </RewardItemDiv>
        </RewardDiv>
      )}
    </Stack>
  );
};

const FooterWithPadding = styled(Footer)`
  padding: 16px 8px;
  line-height: normal;
`;

export const MasterFooter: React.FC = () => {
  const { minTablet, minDesktop } = useBreakpoints();
  const subjectsByNumber = useSelector(getSubjectsByNumber);
  const subjects = useMemo(() => getSubjects(subjectsByNumber), [subjectsByNumber]);
  const subjectsForOnline = useMemo(() => getSubjects(subjectsByNumber, true), [subjectsByNumber]);

  return (
    <FooterWithPadding>
      {minTablet && !minDesktop ? (
        <GroupWrap gap={3} justifyContent={'normal'} alignItems="baseline">
          <Stack gap={minTablet ? 2.5 : 1} flow="row">
            <Section section={subjects} />
            <Section section={subjectsForOnline} />
          </Stack>
          <Stack gap={minTablet ? 2.5 : 1} flow="row">
            <Section section={localTutoring} />
            <Section section={onlineTutoring} />
          </Stack>
          <Section section={aboutTutorSpace} />
        </GroupWrap>
      ) : (
        <Stack
          gap={1}
          justifyContent={'center'}
          autoColumns={'1fr 1fr 1fr 1fr'}
          flow={minTablet ? 'column' : 'row'}
          alignItems={minTablet ? 'start' : 'center'}
          justifyItems={minTablet ? 'start' : 'center'}
          textAlign="center"
        >
          <>
            <Section section={subjects} />
            <Section section={subjectsForOnline} />
            <Section section={citys} />
          </>
          <Stack
            gap={minTablet ? 2.5 : 1}
            flow="row"
            style={{ placeItems: minTablet ? '' : 'center' }}
          >
            <Section section={localTutoring} />
            <Section section={onlineTutoring} />
          </Stack>
          <Stack
            gap={minTablet ? 2.5 : 1}
            flow="row"
            style={{ placeItems: minTablet ? '' : 'center' }}
          >
            <Section section={aboutTutorSpace} />
          </Stack>
        </Stack>
      )}
      <FooterStrip />
    </FooterWithPadding>
  );
};
