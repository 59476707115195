import { isProduction } from 'logic/env/envs';
import { GetAccessToken } from './axios-headers-authentication.model';

export const getAuthenticationHeaders = (
  getAccessToken: GetAccessToken
): { [key: string]: string } => {
  const accessToken = getAccessToken();

  if (!isProduction()) {
    console.log('accessToken', accessToken);
  }

  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
};
