import { AccountType } from 'logic/api-models/api-models';
import { StoreState } from '../../../app/app.reducers';
import { shouldChooseUserType, shouldVerifyPhone, haveUserAnOffer } from './authentication.logic';
import { getUserFullName } from '../../../logic/utils/user-label';

export const getAccessToken = (state: StoreState) => state.authentication.accessToken;

export const getAuthenticatedUser = (state: StoreState) => state.authentication.user;

export const getIsAuthenticated = (state: StoreState) => !!state.authentication.accessToken;

export const getIsAuthorized = (state: StoreState) => !getShouldVerifyPhone(state);

export const getShouldVerifyPhone = (state: StoreState) =>
  shouldVerifyPhone(getAuthenticatedUser(state));

export const getChooseUserType = (state: StoreState) =>
  shouldChooseUserType(getAuthenticatedUser(state));

export const getHaveIAnOffer = (state: StoreState) => haveUserAnOffer(state.authentication.user);

export const getAuthenticatedUserId = (state: StoreState) => getAuthenticatedUser(state)?._id;

export const getIsAuthenticatedUserAnInstitute = (state: StoreState) =>
  getAccountType(state) === AccountType.TUTORING_INSTITUTE;

export const getIsAuthenticatedUserAnRegular = (state: StoreState) =>
  state.authentication.user?.isLegacyUser ||
  (!!getAccountType(state) && getAccountType(state) !== AccountType.TUTORING_INSTITUTE);

export const getAccountType = (state: StoreState) => state.authentication.user?.accountType;

export const getAccountRights = (state: StoreState) => state.authentication.user?.rights;

export const getIsTutoringSchoolUser = (state: StoreState) =>
  getAccountType(state) === AccountType.TUTORING_INSTITUTE;

export const getTutoringInstituteOverallStats = ({ authentication }: StoreState) => {
  return {
    totalOffersAppearedOnSearch: authentication?.user?.totalOffersAppearedOnSearch,
    totalOffersDetailsDisplayed: authentication?.user?.totalOffersDetailsDisplayed,
    totalRatings: authentication?.user?.totalRatings,
    averageRating: authentication?.user?.averageRating,
  };
};

export const getAuthenticatedUserFullName = (state: StoreState) =>
  getUserFullName(getAuthenticatedUser(state));

export const getHasAnyPaymentModel = (state: StoreState) =>
  !!state.authentication.user?.subscriptions?.length;
export const getPaymentModel = (state: StoreState) => state.authentication.user?.subscriptions?.[0];

export const isAdmin = (state: StoreState) => (state.authentication.user?.role === 'ADMIN');
