import PoweroffOutlined from '@ant-design/icons/lib/icons/PoweroffOutlined';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import { Button, Dropdown, Menu } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigationSlice } from 'logic/store/navigation/navigation.slice';
import {
  getAccountRights,
  getAccountType,
  getAuthenticatedUser,
} from '../../../../logic/store/authentication/authentication.selectors';
import { authenticationSlice } from '../../../../logic/store/authentication/authentication.slice';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { getUserNameLabel } from '../../../../logic/utils/user-label';
import styled from 'styled-components';
import { AccountType } from 'logic/api-models/api-models';
import { getSidebarLinks } from 'components/sidebar';
import { colors } from '../../../../styles/colors';

export const UserDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector(getAuthenticatedUser);

  const handleLogout = () => dispatch(authenticationSlice.actions.logout({}));

  const handleNavigateClick = (path: string) =>
    dispatch(
      navigationSlice.actions.navigateTo({
        path: path,
      })
    );

  const userLabel = getUserNameLabel(authenticatedUser);
  const accountType = useSelector(getAccountType) || AccountType.STUDENT;
  const accountRights = useSelector(getAccountRights);
  const menuOptions = getSidebarLinks(accountType, accountRights);

  const menu = (
    <Menu>
      {menuOptions.regular.map((menu, i) => (
        <Menu.Item key={i} onClick={() => handleNavigateClick(menu.path)}>
          {menu.Icon}
          {menu.text}
        </Menu.Item>
      ))}
      {menuOptions.eliteTutor.length > 0 && (
        <>
          <hr style={{ margin: '8px 16px 8px 16px' }} />
          <Menu.ItemGroup
            key={'item-group'}
            title={<div style={{ color: colors.black }}>{translate(translationKeys.sidebar.elitetutor.title)}</div>}
          >
            {menuOptions.eliteTutor.map((menu, i) => (
              <Menu.Item key={i} onClick={() => handleNavigateClick(menu.path)}>
                {menu.Icon}
                {menu.text}
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        </>
      )}
      {menuOptions.eliteSeeker.length > 0 && (
        <>
          <hr style={{ margin: '8px 16px 8px 16px' }} />
          <Menu.ItemGroup
            key={'item-group'}
            title={<div style={{ color: colors.black }}>{translate(translationKeys.sidebar.eliteseeker.title)}</div>}
          >
            {menuOptions.eliteSeeker.map((menu, i) => (
              <Menu.Item key={i} onClick={() => handleNavigateClick(menu.path)}>
                {menu.Icon}
                {menu.text}
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        </>
      )}
      <hr style={{ margin: '8px 16px 8px 16px' }} />
      <Menu.Item onClick={handleLogout}>
        <PoweroffOutlined />
        {translate(translationKeys.buttons.logout)}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <UserButton type="text" size={'large'}>
        <UserLabel>{userLabel}</UserLabel>
        <DownOutlined />
      </UserButton>
    </Dropdown>
  );
};

const UserButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
`;

const UserLabel = styled.div`
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
`;
