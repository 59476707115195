import { reset } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { concat, from, of, pipe, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { AppRoute } from 'app/app.route.const';
import {
  SET_PASSWORD_FORM_NAME,
  validateSetPasswordForm,
} from 'components/authentication/forms/set-password/set-password.logic';
import {
  UPDATE_PASSWORD_FORM_NAME,
  validateUpdatePasswordForm,
} from 'src/pages-all/user/user-profile/components/password-section/update-password.logic';
import { incorrectEmailErrorHandler } from 'logic/error/incorrectEmail.error';
import {
  AnalyticsAuthEvent,
  PixelEvents,
  triggerAnalyticsAuthEvent,
  triggerFBPixelEvent,
} from 'logic/analytics/analytics';
import {
  FORGOT_PASSWORD_FORM_NAME,
  validateForgotPasswordForm,
} from '../../../components/authentication/forms/forgot-password/forgot-password.logic';
import {
  LOGIN_FROM_NAME,
  validateLoginForm,
} from '../../../components/authentication/forms/login/login.logic';
import {
  REGISTER_WITH_EMAIL_FORM_NAME,
  validateRegisterWithEmailForm,
} from '../../../components/authentication/forms/register-with-email/register-with-email.logic';

import {
  LoginRequest,
  RegisterElitetutorQuestionnaire,
  RegisterElitetutorRequest,
  RegisterRequest,
  RegisterWithoutPasswordRequest,
  UserUpdateRequest,
} from '../../api-models/api-models';
import { wrongCredentialsErrorHandler } from '../../error/wrong-credentials.error';
import { trimValue } from '../../formaters/formaters';
import { logError, logSuccess } from '../../log/log.logic';
import { translate, translationKeys } from '../../translations/translations.service';
import { normalizeEmail } from '../../utils/email';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from '../const';
import { modalSlice } from '../modal/modal.slice';
import { navigationSlice } from '../navigation/navigation.slice';
import { AuthenticationStepKey } from './authentication.model';
import {
  getAuthenticatedUser,
  getAuthenticatedUserId,
  getShouldVerifyPhone,
} from './authentication.selectors';
import { authenticationSlice } from './authentication.slice';
import { deleteAccountEpics$ } from './delete-account/delete-account.epic';
import { emailEpics$ } from './email/email.epic';
import { getSuccessfulLoginRedirectPath } from '../modal/modal.selectors';
import { RootEpic } from 'app/app.epics.type';
import { shouldChooseUserType } from './authentication.logic';
import { userNameEpics$ } from './name/user-name.epic';
import { REGISTER_PHONE_FORM_NAME } from 'components/authentication/forms/register-phone/register-phone.const';
import { isUnauthenticatedError } from 'logic/error/unauthenticated.error';
import { initializeSlice } from 'logic/store/initialize/initialize.slice';
import { stepFlowSlice } from 'logic/store/step-flow/step-flow.slice';
import { geolocationSlice } from 'logic/store/geolocation/geolocation.slice';
import { offerSlice } from 'logic/store/offer/offer.slice';
import { instituteSlice } from 'logic/store/institute';
import { paymentSlice } from 'logic/store/payment';
import { conferenceSlice } from 'logic/store/conference/conference.slice';
import { elitetutorSlice } from 'logic/store/elitetutor/elitetutor.slice';
import {
  UPDATE_BIRTHDAY_FORM_NAME,
  validateUpdateBirthdayForm,
} from 'src/pages-all/user/user-profile/up-coming-lessons/components/update-birthday.logic';
import { logSlice } from 'logic/store/log/log.slice';
import {
  REGISTER_AS_ELITETUTOR_FORM_NAME,
  RegisterAsElitetutorFormValues,
  validateRegisterAsElitetutorForm,
} from 'src/pages-all/register-as-elitetutor/forms/register-as-elitetutor/register-as-elitetutor.logic';
import { parsePhoneNumberForPayload } from 'logic/phone/phone.mapper';
import { RegisterAsElitetutorPaths } from 'src/pages-all/register-as-elitetutor/register-as-elitetutor.const';
import {
  RegisterAsElitetutorAnswers,
  RegisterAsElitetutorUserDetails,
} from 'src/pages-all/register-as-elitetutor/hooks/use-register-as-elitetutor-local-storage';
import { generatePath } from 'react-router-dom';
import { UtmParams } from 'models/utm-params';

const loginWithEmail$: RootEpic = (
  action$,
  _,
  { managed, badRequestFormErrorHandler, ofValidReduxForm, authenticationApi }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.loginWithEmail.match),
    ofValidReduxForm(LOGIN_FROM_NAME, validateLoginForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.loginWithEmail,
      pipe(
        map(
          (action): LoginRequest => ({
            emailId: normalizeEmail(action.payload.formValues?.email),
            password: action.payload.formValues?.password,
          })
        ),
        switchMap((request) => from(authenticationApi.login(request)))
      ),
      [wrongCredentialsErrorHandler, badRequestFormErrorHandler(LOGIN_FROM_NAME)]
    ),
    tap((response) => {
      triggerAnalyticsAuthEvent(AnalyticsAuthEvent.Login, response.data.data?.user?._id, 'email');
      logSuccess(translate(translationKeys.confirmation.welcomeBackToTutorSpace));
    }),
    map((response) =>
      authenticationSlice.actions.postLoginActions({ loginResponse: response.data.data })
    )
  );

const postLoginActions$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(authenticationSlice.actions.postLoginActions.match),
    mergeMap((action) => {
      const rights = action.payload.loginResponse?.user?.rights;
      const hasElitetutorOrEliteseekerRights =
        rights &&
        rights.length > 0 &&
        rights.find(
          (right) =>
            right === 'elitetutor' || right === 'eliteseeker' || right === 'elitetutor_subjects'
        );
      const unitOverview = hasElitetutorOrEliteseekerRights
        ? generatePath(AppRoute.UnitOverview, {
            viewAs: rights && rights.includes('elitetutor') ? 'tutor' : 'schueler',
          })
        : undefined;
      const redirectPath = getSuccessfulLoginRedirectPath(state$.value) || unitOverview;
      const result = [
        authenticationSlice.actions.setUser({ user: action.payload.loginResponse?.user }),
        authenticationSlice.actions.setAccessToken({
          accessToken: action.payload.loginResponse?.token,
        }),
        ...(redirectPath ? [navigationSlice.actions.navigateTo({ path: redirectPath })] : []),
        authenticationSlice.actions.continueOnboardingIfNeeded(),
      ];
      return of(...result);
    })
  );

const continueOnboardingIfNeeded$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(authenticationSlice.actions.continueOnboardingIfNeeded.match),
    map(() =>
      getShouldVerifyPhone(state$.value)
        ? modalSlice.actions.openAuthenticationModal({
            stepKey: AuthenticationStepKey.RegisterPhone,
          })
        : shouldChooseUserType(state$.value.authentication.user)
        ? modalSlice.actions.openAuthenticationModal({
            stepKey: AuthenticationStepKey.SelectUserType,
          })
        : modalSlice.actions.closeAuthenticationModal()
    )
  );

const forgotPassword$: RootEpic = (
  action$,
  _,
  { dispatch, managed, ofValidReduxForm, authenticationApi }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.forgotPassword.match),
    ofValidReduxForm(FORGOT_PASSWORD_FORM_NAME, validateForgotPasswordForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.forgotPassword,
      pipe(
        map((action) => ({
          email: normalizeEmail(action.payload.formValues?.email),
        })),
        switchMap((request) => from(authenticationApi.remindPassword(request))),
        tap(() => {
          dispatch(modalSlice.actions.closeAuthenticationModal());
          logSuccess(translate(translationKeys.confirmation.weHaveSentYouEmailToResetPassword));
        })
      ),
      [incorrectEmailErrorHandler]
    ),
    ignoreElements()
  );

const validatePasswordToken$: RootEpic = (action$, _, { dispatch, managed, authenticationApi }) =>
  action$.pipe(
    filter(authenticationSlice.actions.validatePasswordToken.match),
    managed(
      authenticationSlice.actions.validatePasswordToken,
      pipe(
        switchMap((action) => {
          const { token = '' } = action.payload;
          return from(authenticationApi.validatePasswordToken(token));
        }),
        tap((response) => {
          const { isTokenCorrect } = response.data.data || {};
          if (isTokenCorrect) {
            dispatch(authenticationSlice.actions.setTokenValid(true));
          } else {
            dispatch(navigationSlice.actions.navigateTo({ path: AppRoute.Home }));
            logError(translate(translationKeys.errors.tokenExpired));
          }
        })
      )
    ),
    ignoreElements()
  );

const setPassword$: RootEpic = (
  action$,
  _,
  { dispatch, managed, ofValidReduxForm, authenticationApi }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.setPassword.match),
    ofValidReduxForm(SET_PASSWORD_FORM_NAME, validateSetPasswordForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.setPassword,
      pipe(
        switchMap((action) => {
          const { password = '', token } = action.payload;
          return from(authenticationApi.setPassword({ password, token }));
        }),
        tap(() => {
          dispatch(authenticationSlice.actions.setTokenValid(false));
          dispatch(navigationSlice.actions.navigateTo({ path: AppRoute.Home }));
          logSuccess(
            translate(translationKeys.confirmation.yourPasswordHasBeenSuccessfullyUpdated)
          );
        })
      )
    ),
    ignoreElements()
  );

const registerWithEmail: RootEpic = (
  action$,
  _,
  { managed, badRequestFormErrorHandler, ofValidReduxForm, authenticationApi }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.registerWithEmail.match),
    ofValidReduxForm(REGISTER_WITH_EMAIL_FORM_NAME, validateRegisterWithEmailForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.registerWithEmail,
      pipe(
        map(
          (action): RegisterRequest => ({
            firstName: trimValue(action.payload.formValues?.firstName) || '',
            lastName: trimValue(action.payload.formValues?.lastName) || '',
            emailId: normalizeEmail(action.payload.formValues?.emailId),
            password: action.payload.formValues?.password || '',
          })
        ),
        switchMap((request) => from(authenticationApi.register(request)))
      ),
      [badRequestFormErrorHandler(REGISTER_WITH_EMAIL_FORM_NAME)]
    ),
    tap((response) => {
      triggerFBPixelEvent(PixelEvents.CompleteRegistration);
      triggerAnalyticsAuthEvent(AnalyticsAuthEvent.SignUp, response.data.data?.user?._id, 'email');
      logSuccess(translate(translationKeys.confirmation.welcomeToTheTutorSpaceCommunity));
    }),
    map((response) =>
      authenticationSlice.actions.postLoginActions({ loginResponse: response.data.data })
    )
  );

// this action is currently specific to the `register as elitetutor` flow - see register-as-elitetutor.page.tsx
const registerWithEmailWithoutPassword: RootEpic = (
  action$,
  _,
  { dispatch, managed, badRequestFormErrorHandler, ofValidReduxForm, authenticationApi }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.registerWithEmailWithoutPassword.match),
    ofValidReduxForm(REGISTER_AS_ELITETUTOR_FORM_NAME, validateRegisterAsElitetutorForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.registerWithEmailWithoutPassword,
      pipe(
        map(
          (action): RegisterWithoutPasswordRequest => ({
            firstName: trimValue(action.payload.formValues?.firstName) || '',
            lastName: trimValue(action.payload.formValues?.lastName) || '',
            emailId: normalizeEmail(action.payload.formValues?.emailId),
            phoneNumber: parsePhoneNumberForPayload(
              action.payload.formValues?.phoneCountryCode,
              action.payload.formValues?.phoneNumber
            ),
          })
        ),
        switchMap((request) => from(authenticationApi.registerWithoutPassword(request)))
      ),
      [badRequestFormErrorHandler(REGISTER_AS_ELITETUTOR_FORM_NAME)]
    ),
    tap((response) => {
      triggerFBPixelEvent(PixelEvents.CompleteRegistration);
      triggerAnalyticsAuthEvent(AnalyticsAuthEvent.SignUp, response.data.data?.user?._id, 'email');
      logSuccess(translate(translationKeys.confirmation.welcomeToTheTutorSpaceCommunity));
    }),
    map((response) =>
      authenticationSlice.actions.postLoginActions({ loginResponse: response.data.data })
    ),
    tap(() => {
      dispatch(
        navigationSlice.actions.navigateTo({
          path: `${AppRoute.TutorspacePremiumRegisterAsTutor}/${RegisterAsElitetutorPaths.TeachingLevel}`,
        })
      );
    })
  );

const mapQuestionnaireAnswers = (
  answers?: RegisterAsElitetutorAnswers
): RegisterElitetutorQuestionnaire => {
  if (!answers) return [];

  return Object.entries(answers).map(([key, value]) => ({
    question: key,
    answer: [value].toString(),
  }));
};

const mapToRegisterElitetutorRequest = (action: {
  payload: {
    formValues: RegisterAsElitetutorFormValues;
    answers?: RegisterAsElitetutorAnswers;
    userDetails?: RegisterAsElitetutorUserDetails;
    utmParams: UtmParams;
  };
  type: string;
}): RegisterElitetutorRequest => {
  return {
    firstName: trimValue(action.payload.formValues?.firstName) || '',
    lastName: trimValue(action.payload.formValues?.lastName) || '',
    emailId: normalizeEmail(action.payload.formValues?.emailId),
    phoneNumber: parsePhoneNumberForPayload(
      action.payload.formValues?.phoneCountryCode,
      action.payload.formValues?.phoneNumber
    ),
    elitesubjects: action.payload?.userDetails?.subjects || [],
    questionnaire: [
      ...mapQuestionnaireAnswers(action.payload.answers),
      { question: 'Postleitzahl', answer: action.payload.formValues.zipCode || '' },
    ],
    utm: {
      source: action.payload.utmParams.utmSource,
      medium: action.payload.utmParams.utmMedium,
      campaign: action.payload.utmParams.utmCampaign,
      content: action.payload.utmParams.utmContent,
    },
  };
};

const registerAsElitetutor: RootEpic = (
  action$,
  _,
  { dispatch, managed, badRequestFormErrorHandler, ofValidReduxForm, authenticationApi }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.registerAsElitetutor.match),
    ofValidReduxForm(REGISTER_AS_ELITETUTOR_FORM_NAME, validateRegisterAsElitetutorForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.registerAsElitetutor,
      pipe(
        map((action): RegisterElitetutorRequest => mapToRegisterElitetutorRequest(action)),
        switchMap((request) => from(authenticationApi.registerAsElitetutor(request)))
      ),
      [badRequestFormErrorHandler(REGISTER_AS_ELITETUTOR_FORM_NAME)]
    ),
    tap((response) => {
      triggerFBPixelEvent(PixelEvents.SubmitApplication);
      triggerAnalyticsAuthEvent(AnalyticsAuthEvent.SignUp, response.data.data?.user?._id, 'email');
      logSuccess(translate(translationKeys.confirmation.welcomeToTheTutorSpaceCommunity));
    }),
    map((response) =>
      authenticationSlice.actions.postLoginActions({ loginResponse: response.data.data })
    ),
    tap(() => {
      dispatch(
        navigationSlice.actions.navigateTo({
          path: `${AppRoute.TutorspacePremiumRegisterAsTutor}/${RegisterAsElitetutorPaths.TeachingLevel}`,
        })
      );
    })
  );

const updateUserType$: RootEpic = (action$, state$, { dispatch, managed, authenticationApi }) =>
  action$.pipe(
    filter(authenticationSlice.actions.setUserType.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.setUserType,
      pipe(
        switchMap((action) => {
          const { userType } = action.payload;
          const userId = getAuthenticatedUser(state$.value)?._id || '';
          return from(authenticationApi.updateUser(userId, { accountType: userType })).pipe(
            switchMap(() => from(authenticationApi.fetchCurrentUser())),
            tap((response) =>
              dispatch(
                authenticationSlice.actions.setUser({
                  user: response.data.data,
                })
              )
            )
          );
        }),
        tap(() => {
          dispatch(modalSlice.actions.closeAuthenticationModal());
          logSuccess(translate(translationKeys.confirmation.yourUserTypeWasSuccessfullyUpdated));
        })
      )
    ),
    ignoreElements()
  );

const updatePassword$: RootEpic = (
  action$,
  state$,
  { dispatch, managed, ofValidReduxForm, authenticationApi }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.updatePassword.match),
    ofValidReduxForm(UPDATE_PASSWORD_FORM_NAME, validateUpdatePasswordForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.updatePassword,
      pipe(
        switchMap((action) => {
          const { password = '' } = action.payload as any;
          const userId = getAuthenticatedUser(state$.value)?._id || '';
          return from(authenticationApi.updateUser(userId, { password })).pipe(
            switchMap(() => from(authenticationApi.fetchCurrentUser())),
            tap((response) =>
              dispatch(
                authenticationSlice.actions.setUser({
                  user: response.data.data,
                })
              )
            )
          );
        }),
        tap(() => {
          logSuccess(
            translate(translationKeys.confirmation.yourPasswordHasBeenSuccessfullyUpdated)
          );
        })
      )
    ),
    ignoreElements()
  );

const updateUserFullBirthday$: RootEpic = (
  action$,
  state$,
  { managed, ofValidReduxForm, authenticationApi, badRequestFormErrorHandler }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.updateFullBirthday.match),
    ofValidReduxForm(UPDATE_BIRTHDAY_FORM_NAME, validateUpdateBirthdayForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.updateFullBirthday,
      pipe(
        switchMap((action) => {
          const userId = getAuthenticatedUser(state$.value)?._id || '';
          const request: UserUpdateRequest = {
            birthdayFull: trimValue(action.payload.formValues?.birthday),
          };
          return from(authenticationApi.updateUser(userId, request));
        })
      ),
      [badRequestFormErrorHandler(UPDATE_BIRTHDAY_FORM_NAME)]
    ),
    switchMap((response) =>
      of(
        authenticationSlice.actions.setUser({ user: response.data.data }),
        logSlice.actions.logSuccess({
          message: translate(translationKeys.confirmation.userFullBirthdayWasSuccessfullyUpdated),
        }),
        modalSlice.actions.closeBirthdayRequiredModal()
      )
    )
  );

const closeAuthenticationModal$: RootEpic = (action$) =>
  action$.pipe(
    filter(modalSlice.actions.closeAuthenticationModal.match),
    mergeMap(() => of(reset(REGISTER_PHONE_FORM_NAME), reset(REGISTER_WITH_EMAIL_FORM_NAME)))
  );

const logout$: RootEpic = (action$, _, { managed, authenticationApi }) =>
  action$.pipe(
    filter(authenticationSlice.actions.logout.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      authenticationSlice.actions.logout,
      mergeMap((action) =>
        from(authenticationApi.logout()).pipe(
          map((response) => ({ action, response })),
          catchError((error) =>
            // Hide only 401 responses, so the local logout a few lines below can complete normally
            error && isUnauthenticatedError(error)
              ? of({ action, response: null })
              : throwError(error)
          )
        )
      )
    ),
    tap(({ action }) => {
      if (!action?.payload?.hideNotification) {
        logSuccess(translate(translationKeys.confirmation.youHaveBeenSuccessfullyLoggedOut));
      }
    }),
    mergeMap(({ action }) => {
      const obs = of(
        authenticationSlice.actions.reset(),
        initializeSlice.actions.reset(),
        modalSlice.actions.reset(),
        stepFlowSlice.actions.reset(),
        geolocationSlice.actions.reset(),
        offerSlice.actions.reset(),
        instituteSlice.actions.reset(),
        paymentSlice.actions.reset(),
        conferenceSlice.actions.reset(),
        elitetutorSlice.actions.reset()
      );
      if (action?.payload?.followupAction) {
        return concat(obs, of(action.payload.followupAction));
      } else {
        return obs;
      }
    })
  );

const fetchCurrentUser$: RootEpic = (
  action$,
  _,
  { managed, authenticationApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.fetchCurrentUser.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      authenticationSlice.actions.fetchCurrentUser,
      pipe(
        switchMap(() => from(authenticationApi.fetchCurrentUser())),
        map((response) => authenticationSlice.actions.setUser({ user: response.data.data }))
      )
    )
  );

const deleteUserVideo$: RootEpic = (
  action$,
  state$,
  { managed, authenticationApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(authenticationSlice.actions.deleteUserVideo.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    map(() => getAuthenticatedUserId(state$.value)),
    managed(
      authenticationSlice.actions.deleteUserVideo,
      switchMap((userId) => from(authenticationApi.deleteUserVideo(userId)))
    ),
    tap(() => {
      logSuccess(translate(translationKeys.confirmation.userVideoWasDeletedSuccessfully));
    }),
    ignoreElements()
  );

const setUserEtag$: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(authenticationSlice.actions.setUserEtag.match),
    filter(() => !!state$.value.authentication.userEtag),
    filter(() => !!state$.value.authentication.accessToken),
    map(({ payload: { userEtag } }) => userEtag),
    distinctUntilChanged(),
    map(() => authenticationSlice.actions.fetchCurrentUser())
  );

export const authenticationEpics$ = combineEpics(
  loginWithEmail$,
  continueOnboardingIfNeeded$,
  forgotPassword$,
  validatePasswordToken$,
  setPassword$,
  registerWithEmail,
  registerWithEmailWithoutPassword,
  registerAsElitetutor,
  updatePassword$,
  updateUserFullBirthday$,
  emailEpics$,
  closeAuthenticationModal$,
  logout$,
  fetchCurrentUser$,
  deleteAccountEpics$,
  postLoginActions$,
  updateUserType$,
  userNameEpics$,
  deleteUserVideo$,
  setUserEtag$
);
