import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AppSpin } from '../components/app-spin/app-spin';
import { InitializeGate } from '../components/initialize-gate/initialize-gate';
import { AppRouter } from './app.route';
import { history } from './app.route.history';
import { persistor, store } from './app.store';
import { BreakpointsProvider } from 'logic/hooks/use-breakpoints';
import { AuthenticationModalLazy } from 'components/authentication/authentication.modal.lazy';
import '../styles/base.less';
import { I18nConfigProvider } from 'logic/translations/use-i18n-config';

export const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<AppSpin />} persistor={persistor}>
        <InitializeGate>
          <BreakpointsProvider>
            <I18nConfigProvider>
              <Router history={history}>
                <AppRouter />
                <AuthenticationModalLazy />
              </Router>
            </I18nConfigProvider>
          </BreakpointsProvider>
        </InitializeGate>
      </PersistGate>
    </Provider>
  );
};
