import { FormErrors } from 'redux-form';
import { clearEmptyFields } from 'logic/validators/utils';
import { isMinLengthValidator, isRequiredValidator } from 'logic/validators/validators';

export const UPDATE_USER_NAME_FORM_NAME = 'update-user-name';
export const MIN_FIRST_NAME_LENGTH = 3;
export const MIN_LAST_NAME_LENGTH = 3;

export interface UpdateUserNameFormValues {
  firstName?: string;
  lastName?: string;
}

export type UpdateUserNameFormValidationErrors = FormErrors<UpdateUserNameFormValues, any>;

export const validateUpdateUserNameForm = (formValues: UpdateUserNameFormValues) => {
  const { firstName, lastName } = formValues;

  const errors: UpdateUserNameFormValidationErrors = {
    firstName:
      isRequiredValidator(firstName) || isMinLengthValidator(MIN_FIRST_NAME_LENGTH)(firstName),
    lastName: isRequiredValidator(lastName) || isMinLengthValidator(MIN_LAST_NAME_LENGTH)(lastName),
  };

  return clearEmptyFields(errors);
};
