module.exports = {
  colors: {
    red: '#D22017',
    yellow: '#FFE81D',
    orange: '#FFD000',
    teal: '#25CED1',
    'dark-teal': '#08AFB2',
    dominant: '#212121',
    black: '#000',
    transparent: 'rgba(0, 0, 0, 0)',
    gray: {
      100: '#e9e9e9',
      200: '#D8D8D8',
      300: '#e0e0e0',
      400: '#C1C1C1',
      500: '#979797',
      600: '#757575',
      700: '#616161',
      800: '#494545',
      900: '#212121',
    },
  },
  fontSize: {
    xs: '0.625rem', // 10px
    sm: '0.75rem', // 12px
    base: '1rem', // 16px
    lg: '1.25rem', // 20px
    xl: '1.625rem', // 26px
    '2xl': '2rem', // 32px
    '3xl': '2.5rem', // 40px
    '4xl': '3.5rem', // 56px
    '5xl': '4rem', // 64px
    '6xl': '5rem', // 80px
    '7xl': '6rem', // 96px
  },
  shadows: {
    shadow1: '0px 10px 30px 3px rgba(0,0,0,0.15)',
    shadow2: '0px 1px 5px 1px rgba(0,0,0,0.15)',
  },
};
