import React, { createContext, FC, useContext, useMemo } from 'react';
import { useMatchMedia } from './use-match-media';

const assertContext = (context: unknown, name: string) => {
  if (context === undefined)
    throw new Error(
      `Either you or the component you used uses the use${name} hook which needs to be placed inside a ${name}Provider. Please wrap your component inside <${name}Provider></${name}Provider>.`
    );
};

export enum Breakpoints {
  maxSmallMobile = 'max-width: 375px',
  maxMobileLarge = 'max-width: 425px',
  minTablet = 'min-width: 768px',
  maxTablet = 'max-width: 991px',
  minDesktop = 'min-width: 992px',
  maxDesktop = 'max-width: 1199px',
  minWideDesktop = 'min-width: 1200px',
  minHdDesktop = 'min-width: 1400px',
  min1024 = 'min-width: 1024px',
  min400 = 'min-width: 400px',
  min500 = 'min-width: 500px',
  min1600 = 'min-width: 1600px',
}

type ContextValue = {
  maxSmallMobile: boolean;
  maxMobileLarge: boolean;
  minTablet: boolean;
  maxTablet: boolean;
  min1024: boolean;
  min400: boolean;
  min500: boolean;
  minDesktop: boolean;
  maxDesktop: boolean;
  minWideDesktop: boolean;
  minHdDesktop: boolean;
  min1600: boolean;
};

// pass undefined as any, because we run assertContext at runtime
const BreakpointsContext = createContext<ContextValue>(undefined as any);

export function useBreakpoints() {
  const context = useContext(BreakpointsContext);
  assertContext(context, 'Breakpoints');
  return context;
}

export const BreakpointsProvider: FC = ({ children }) => {
  const maxSmallMobile = useMatchMedia(`(${Breakpoints.maxSmallMobile})`);
  const maxMobileLarge = useMatchMedia(`(${Breakpoints.maxMobileLarge})`);
  const minTablet = useMatchMedia(`(${Breakpoints.minTablet})`);
  const maxTablet = useMatchMedia(`(${Breakpoints.maxTablet})`);
  const minDesktop = useMatchMedia(`(${Breakpoints.minDesktop})`);
  const maxDesktop = useMatchMedia(`(${Breakpoints.maxDesktop})`);
  const minWideDesktop = useMatchMedia(`(${Breakpoints.minWideDesktop})`);
  const minHdDesktop = useMatchMedia(`(${Breakpoints.minHdDesktop})`);
  const min1024 = useMatchMedia(`(${Breakpoints.min1024})`);
  const min400 = useMatchMedia(`(${Breakpoints.min400})`);
  const min500 = useMatchMedia(`(${Breakpoints.min500})`);
  const min1600 = useMatchMedia(`(${Breakpoints.min1600})`);

  const value = useMemo(
    () => ({
      maxSmallMobile,
      maxMobileLarge,
      minTablet,
      maxTablet,
      minDesktop,
      maxDesktop,
      minWideDesktop,
      minHdDesktop,
      min1024,
      min400,
      min500,
      min1600,
    }),
    [
      maxSmallMobile,
      maxMobileLarge,
      minTablet,
      maxTablet,
      minDesktop,
      maxDesktop,
      minWideDesktop,
      minHdDesktop,
      min1024,
      min400,
      min500,
      min1600,
    ]
  );

  return <BreakpointsContext.Provider value={value}>{children}</BreakpointsContext.Provider>;
};
