import { StoreState } from 'app/app.reducers';

export const getAddPaymentMethodModalOpened = (state: StoreState) =>
  state.payment.addPaymentMethodModalOpened;
export const getBillingInfoModalOpened = (state: StoreState) =>
  state.payment.billingInfoModalOpened;
export const getConfirmPaymentStartModalOpened = (state: StoreState) =>
  state.payment.confirmPaymentStartModalOpened;

export const getOfferIdPendingActivation = (state: StoreState) =>
  state.payment.offerIdPendingActivation;

export const getPaymentMethods = (state: StoreState) => state.payment.paymentMethods;
export const getNextInvoice = (state: StoreState) => state.payment.nextInvoice;
export const getConfig = (state: StoreState) => state.payment.config;
export const getSubscriptions = (state: StoreState) => state.payment.subscriptions;
export const getInvoices = (state: StoreState) => state.payment.invoices || [];

export const getAvailableSubscriptionPrice = (state: StoreState) =>
  state.payment.config?.availablePrices?.length
    ? state.payment.config?.availablePrices[0]
    : undefined;

export const getIsBillingInfoProvided = (state: StoreState) =>
  [
    state.payment.billingInfo?.companyName,
    state.payment.billingInfo?.city,
    state.payment.billingInfo?.addressLine1,
    state.payment.billingInfo?.country,
    state.payment.billingInfo?.postalCode,
  ].every(Boolean);

export const getDiscountPercentOff = (state: StoreState) => state.payment.discount?.percentOff;

export const getStandardPaymentMethodId = (state: StoreState) =>
  state.payment.billingInfo?.defaultPaymentMethod;

export const getSubscriptionStatus = (state: StoreState) => state.payment.subscriptions?.status;

export const getIsSubscriptionActive = (state: StoreState) =>
  state.payment.subscriptions?.status === 'active';

export const getIsSubscriptionPastDue = (state: StoreState) =>
  state.payment.subscriptions?.status === 'past_due';

export const getPaymentCollectionMethod = (state: StoreState) =>
  state.payment.subscriptions?.collectionMethod;

// Checks if user activated payment by invoice previously
export const getIsPaymentByInvoiceActive = (state: StoreState) =>
  !!state.payment.paymentMethods?.invoice?.isActive;

export const getIsPaymentByInvoiceDefault = (state: StoreState) =>
  !!state.payment.paymentMethods?.invoice?.isDefault;

export const getThisMonthUsage = (state: StoreState) => state.payment.nextInvoice?.usage || 0;

export const getHasUnpaidInvoices = (state: StoreState) =>
  state.payment?.invoices?.some((invoice) => invoice.status !== 'paid');

export const getHasUnpaidInvoicesFromPreviousSubscription = (state: StoreState) =>
  getHasUnpaidInvoices(state) && !getSubscriptions(state);

export const getAreOffersInvisible = (state: StoreState) =>
  !getIsBillingInfoProvided(state) ||
  !getIsAnyPaymentMethodProvided(state) ||
  (!!state.institute.offers?.length &&
    !!state.payment.invoices?.length &&
    !getSubscriptions(state)) ||
  getHasUnpaidInvoicesFromPreviousSubscription(state);

export const getIsAnyPaymentMethodProvided = (state: StoreState) =>
  (getIsPaymentByInvoiceActive(state) && getIsPaymentByInvoiceDefault(state)) ||
  !!state.payment.paymentMethods?.sepa?.length ||
  !!state.payment.paymentMethods?.cards?.length;

export const getIsTogglingOffersEnabled = (state: StoreState) =>
  getIsBillingInfoProvided(state) && getIsAnyPaymentMethodProvided(state);

export const getAvailablePriceId = (state: StoreState) =>
  state.payment.config?.availablePrices?.[0];

export const getPPLPrice = (state: StoreState) =>
  state.payment.subscriptions?.price?.unitAmount || 0;
