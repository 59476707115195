import { translate, translationKeys } from 'logic/translations/translations.service';
import {
  BasicTeachingLevels,
  RequestTutoringTeachingLevels,
  TeachingLevel,
  TeachingLevelVariants,
} from './teaching-level';

export const getTutoringLevelsByVariant = (variant: TeachingLevelVariants): number[] => {
  switch (variant) {
    case 'requestTeachingLevel':
      return RequestTutoringTeachingLevels;
    case 'teachingLevel':
      return BasicTeachingLevels;
    default:
      return BasicTeachingLevels;
  }
};

export const getTeachingLevelsToSubjectOptions = (levelsVariant: TeachingLevelVariants) =>
  getTutoringLevelsByVariant(levelsVariant).map((level) => ({
    label: translate(translationKeys.common[levelsVariant][level]),
    value: level,
  }));

export const translateTeachingLevel = (level: TeachingLevel | undefined) => {
  const translationKey = level ? translationKeys.common.teachingLevel[level] : undefined;
  return (
    (translationKey && translate(translationKey)) ||
    translate(translationKeys.common.teachingLevel.unknown)
  );
};
