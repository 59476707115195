import { reduce } from 'lodash';
import {
  MapBoxContext,
  MapBoxContextSelectOption,
  MapBoxGeometryType,
  MapBoxPlace,
  MapBoxPlacesFeature,
  MapBoxPlacesResponse,
  PlaceSelectOption,
} from './geolocation.model';

export const mapMapBoxPlacesToSelectOptions = (
  geolocations: MapBoxPlacesResponse | undefined,
  valuePlace: MapBoxPlace | undefined
): PlaceSelectOption[] | undefined =>
  geolocations?.features?.map((feature) =>
    mapMapBoxPlacesFeatureToPlaceSelectOption(feature, valuePlace && [valuePlace])
  );

export const mapMapBoxPlacesFeatureToPlaceSelectOption = (
  feature: MapBoxPlacesFeature | undefined,
  placeTypes: MapBoxPlace[] | undefined
) => {
  const context = mapMapBoxPlacesResponseToContext(feature?.context);
  const [longitude, latitude] = (feature?.geometry?.type === MapBoxGeometryType.Point &&
    feature.geometry.coordinates?.length === 2 &&
    feature.geometry.coordinates) || [undefined, undefined];

  const res: PlaceSelectOption = {
    label: feature?.place_name || '',
    value: {
      id: feature?.id,
      labelShort: mapMapBoxPlacesResponseToValue(feature, context, placeTypes),
      labelLong: feature?.place_name,
      longitude,
      latitude,
      context,
    },
  };
  return res;
};

export const mapMapBoxPlacesResponseToValue = (
  feature: MapBoxPlacesFeature | undefined,
  context: MapBoxContextSelectOption | undefined,
  placeTypes: MapBoxPlace[] | undefined
) => {
  const firstContext =
    context && placeTypes && placeTypes?.length > 0 ? context[placeTypes[0]] : undefined;

  let value = placeTypes
    ? feature?.place_type?.find((type) => placeTypes.includes(type as MapBoxPlace))
      ? placeTypes.includes(MapBoxPlace.Street)
        ? feature.place_name || ''
        : feature.text_de || ''
      : firstContext?.text || ''
    : feature?.text_de || '';

  if (placeTypes?.includes(MapBoxPlace.Locality)) {
    value = parseLocalityPlaceName(feature?.place_name || '');
  }

  return value || feature?.place_name;
};

const parseLocalityPlaceName = (value: string): string => {
  if (!value) return '';
  // example: Spandau, Berlin, Deutschland
  const arr = value
    ?.split(',')
    .slice(0, 2)
    .map((v) => v.trim());

  if (arr.length !== 2) {
    return arr[0];
  }

  const [district, city] = arr;

  // if the district includes the city in its name.
  // like. Berlin, Berlin-Mitte
  if (district.includes(city)) {
    return district;
  }
  return `${city}-${district}`;
};

export const mapMapBoxPlacesResponseToContext = (context: MapBoxContext[] | undefined) =>
  reduce(
    context,
    (akk, item) => {
      const splited = item.id?.split('.');
      const field = (splited?.length && splited[0]) || undefined;

      return !!field
        ? {
            ...akk,
            [field]: item,
          }
        : akk;
    },
    {} as MapBoxContextSelectOption
  );

export const getPlaceIndex = (valuePlace: MapBoxPlace | undefined) => valuePlace || 'no_place';
