import { Form, Select } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';
import * as React from 'react';
import { touch, WrappedFieldProps } from 'redux-form';
import { isArray } from 'lodash';
import { SelectOption } from '../select-option/select-option';
import './form-select-input.styles.less';
import { parseEmptyValueAs } from '../../../logic/formaters/formaters';

const { Option } = Select;

interface OwnProps {
  toolTipMessage?: string;
  options?: SelectOption<any, any>[];
  isInsideOtherField?: boolean;
  onPressEnter: any;
  renderTag?: (tag: any) => string;
  additionalAntFormItemStyles?: any;
}

type FormSelectInputProps = WrappedFieldProps & SelectProps<any> & FormItemProps & OwnProps;

export const FormSelectInput = ({
  value: componentValue,
  onPressEnter,
  label,
  required,
  validateStatus,
  labelCol,
  wrapperCol,
  toolTipMessage,
  options = [],
  isInsideOtherField = false,
  meta,
  mode,
  input,
  renderTag,
  additionalAntFormItemStyles,
  ...rest
}: FormSelectInputProps) => {
  const errorMessage = !!meta && meta.error;
  const showError = !!meta && meta.touched && !!meta.error;
  const renderOptions = () =>
    options.map((option: SelectOption, index) => (
      <Option
        key={index}
        value={option.value || ''}
        label={option.label}
        disabled={option.disabled}
        tag={option.tag}
      >
        {option.imageUrl && (
          <span role={'img'}>
            <img
              src={option.imageUrl}
              className={'select-option-image'}
              alt={`${option.label}`}
              loading="lazy"
            />
          </span>
        )}
        {option.label}
        {renderTag && option.tag && (
          <span className={'select-option-tag'}>{renderTag(option.tag)}</span>
        )}
      </Option>
    ));
  const { value, onBlur, onFocus, onChange, ...restInput } = input;

  const handleBlur: SelectProps<any>['onBlur'] = (e) => {
    meta.dispatch(touch(meta.form, input.name));
  };

  const handleFocus = () => {
    onFocus({} as React.FocusEvent);
  };

  const handleChange = (value: any) => {
    const parsedValue = parseEmptyValueAs(mode === 'multiple' ? [] : null)(value);
    onChange(parsedValue);
  };

  const parseValue = (value: any) => {
    if (mode === 'multiple') {
      return isArray(value) ? value : [];
    }
    return value ? value : undefined;
  };

  const renderSelect = () => (
    <Select<any>
      value={parseValue(value)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      mode={mode}
      {...restInput}
      {...rest}
    >
      {renderOptions()}
    </Select>
  );

  return isInsideOtherField ? (
    renderSelect()
  ) : (
    <Form.Item
      label={label}
      style={additionalAntFormItemStyles}
      required={required}
      validateStatus={showError ? 'error' : undefined}
      help={showError ? errorMessage : undefined}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      {renderSelect()}
    </Form.Item>
  );
};
