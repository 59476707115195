import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { AppRoute } from 'app/app.route.const';
import { navigationSlice } from 'logic/store/navigation/navigation.slice';
import { getApiErrorStatusCode } from '../axios/axios-api-error';
import { ErrorHandler } from './error-handler';
import { Dispatch } from 'redux';

export const isNotFoundError = (error: AxiosError) =>
  getApiErrorStatusCode(error) === StatusCodes.NOT_FOUND;

export const notFoundErrorHandler: (dispatch: Dispatch) => ErrorHandler<AxiosError> = (
  dispatch
) => (error) => {
  if (error && isNotFoundError(error)) {
    dispatch(navigationSlice.actions.navigateTo({ path: AppRoute.NotFound }));
    return true;
  }

  return false;
};
