import {
  GetPaymentMethodsResponse,
  GetSubscriptionResponse,
  GetBillingInfoResponse,
  SetUpIntentResponse,
  PaymentsConfigResponse,
  PaymentsNextInvoiceResponse,
  PaymentCollectionMethod,
  DiscountResponse,
  InvoiceResponse,
  InvoicePaymentSettingsRequest,
  SetUpNewSubscriptionRequest,
} from 'logic/api-models/payments';
import { ApiResponse } from 'logic/api-models/api-models';
import { axiosApiAuthenticatedClient } from 'logic/axios/axios-api-authenticated-client';
import { GetAccessToken } from 'logic/axios/axios-headers-authentication.model';
import { BillingInfoFormValues } from 'src/pages-all/tutoring-institute/components';

export const paymentsApiWithGetAccessToken = (getAccessToken: GetAccessToken) => ({
  getPaymentMethods: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<GetPaymentMethodsResponse>>(
      '/payments/payment-method'
    ),
  getSubscriptions: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<GetSubscriptionResponse>>(
      '/payments/subscription'
    ),
  getBillingInfo: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<GetBillingInfoResponse>>(
      '/payments/billing'
    ),
  getConfig: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<PaymentsConfigResponse>>(
      '/payments/config'
    ),
  getNextInvoice: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<PaymentsNextInvoiceResponse>>(
      '/payments/next-invoice'
    ),
  getDiscount: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<DiscountResponse>>(
      '/payments/discount'
    ),
  getInvoices: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<InvoiceResponse[]>>(
      '/payments/invoice'
    ),
  setUpIntent: () =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<SetUpIntentResponse>>(
      '/payments/setup-intent'
    ),
  setUpNewSubscription: (body: SetUpNewSubscriptionRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).post('/payments/subscription', body),
  updateBillingInfo: (billingInfo: BillingInfoFormValues) =>
    axiosApiAuthenticatedClient(getAccessToken).patch('/payments/billing', billingInfo),
  updateStandardPaymentMethod: (defaultPaymentMethodId: string) =>
    axiosApiAuthenticatedClient(getAccessToken).patch('/payments/billing', {
      defaultPaymentMethod: defaultPaymentMethodId,
    }),
  updatePaymentCollectionMethod: (collectionMethod: PaymentCollectionMethod) =>
    axiosApiAuthenticatedClient(getAccessToken).patch('/payments/subscription', {
      collectionMethod,
    }),
  updateInvoicePaymentSettings: (props: InvoicePaymentSettingsRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).patch('/payments/payment-method/invoice', props),
  cancelSubscription: () =>
    axiosApiAuthenticatedClient(getAccessToken).patch<ApiResponse<GetSubscriptionResponse>>(
      '/payments/subscription',
      {
        cancelAtPeriodEnd: true,
      }
    ),
});

export type PaymentApi = ReturnType<typeof paymentsApiWithGetAccessToken>;
