import { useMemo } from 'react';
import { parseQueryString } from 'logic/store/navigation/navigation.logic';
import { EUtmParams, UtmParams } from 'models/utm-params';

export const useUtms = () => {
  const utmParams: UtmParams = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      utmSource: urlParams.get(EUtmParams.UtmSource) || undefined,
      utmMedium: urlParams.get(EUtmParams.UtmMedium) || undefined,
      utmCampaign: urlParams.get(EUtmParams.UtmCampaign) || undefined,
      utmContent: urlParams.get(EUtmParams.UtmContent) || undefined,
    };
  }, []);

  const hasUTMS = !!(
    utmParams.utmSource ||
    utmParams.utmMedium ||
    utmParams.utmCampaign ||
    utmParams.utmContent
  );

  const utmQueryString = hasUTMS ? parseQueryString(utmParams) : undefined;

  return { utmParams, utmQueryString };
};
