import { StoreState } from '../../../app/app.reducers';

export const getAuthtenticationStep = (state: StoreState) => state.modal.authenticationStepKey;

export const getIsAuthtenticationVisible = (state: StoreState) => !!getAuthtenticationStep(state);

export const getFbUser = (state: StoreState) =>
  state.modal.continueWithFacebookConfirmationData?.fbUser;

export const getFbAccessToken = (state: StoreState) =>
  state.modal.continueWithFacebookConfirmationData?.fbAccessToken;

export const getUserContactVisible = (state: StoreState) => state.modal.userContactVisible;

export const getUserContactUser = (state: StoreState) => state.modal.userContactUser;

export const getRatingsVisible = (state: StoreState) => state.modal.ratingsVisible;

export const getSuccessfulLoginRedirectPath = (state: StoreState) =>
  state.modal.successfulLoginRedirectPath;

export const getRequestTutoringModalVisible = (state: StoreState) =>
  state.modal.requestTutoringModalVisible;

export const getReportOfferModalVisible = (state: StoreState) =>
  state.modal.reportOfferModalVisible;

export const getRateOfferModalVisible = (state: StoreState) => state.modal.giveRatingModalVisible;

export const getUpdatePayoutInfoModalVisible = (state: StoreState) =>
  state.modal.updatePayoutInfoModalVisible;

export const getManualPayoutModalVisible = (state: StoreState) =>
  state.modal.manualPayoutModalVisible;

export const getBirthdayRequiredModalVisible = (state: StoreState) =>
  state.modal.birthdayRequiredModalVisible;

export const getPromptCreditPurchaseModalVisible = (state: StoreState) =>
  state.modal.promptCreditPurchaseModalVisible;

export const getEliteratingModalVisible = (state: StoreState) =>
  state.modal.eliteratingModalVisible;
