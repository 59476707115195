import {
  ApiResponse,
  BookingForSeekerResponse,
  BookingListResponse,
  CreateBookingRequest,
  CreateBookingResponse,
  CreditTutorResponse,
  ElitepaymentChangeSubscriptionRequest,
  ElitePaymentConfigResponse,
  ElitepaymentCreateSubscriptionIntentRequest,
  ElitepaymentCreateSubscriptionIntentRequestWithToken,
  ElitepaymentCreditPurchaseRequest,
  ElitepaymentCreditPurchaseResponse,
  ElitepaymentSeekerInfoResponse,
  ElitepaymentSubscriptionIntentResponse,
  EliteratingResponse,
  EliteseekerCreditEventListResponse,
  ElitetutorCreditEventListResponse,
  GetPersonalizedSubscriptionTokenDataResponse,
  SeekerSubscriptionChangeInfoResponse,
  SubjectApplicationDetailsListResponse,
  SubmitEliteApplicationRequest,
  SubmitEliteApplicationResponse,
  UpdateCreditTutorPayoutInfo,
  UpdateElitetutorVacanciesRequest,
  VacancyListResponse,
} from 'logic/api-models/api-models';
import { axiosApiAuthenticatedClient } from 'logic/axios/axios-api-authenticated-client';
import { GetAccessToken } from 'logic/axios/axios-headers-authentication.model';
import { LessonTypePreset } from 'src/pages-all/user/user-profile/up-coming-lessons/UpComingLessons.types';
import { axiosApiClient } from 'logic/axios/axios-api-guest-client';
import { EarnMoneyFormValues } from 'src/pages-all/user/user-profile/earn-money/earn-money.logic';
import { axiosApiAuthenticatedFileClient } from 'logic/axios/axios-api-authenticated-file-client';

export const elitetutorApiWithGetAccessToken = (getAccessToken: GetAccessToken) => ({
  applyAsElite: (request: SubmitEliteApplicationRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<SubmitEliteApplicationResponse>>(
      '/tutor-onboarding',
      request
    ),
  getSubjectApplications: (userID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).get<
      ApiResponse<SubjectApplicationDetailsListResponse>
    >(`/user/${userID}/subject-application`),
  getElitetutorBookings: (id: string, size: number, page: number, preset?: LessonTypePreset) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<BookingListResponse>>(
      `/elitetutor/${id}/booking?size=${size || 10}&page=${page || 1}&preset=${
        preset || LessonTypePreset.ONGOING_OR_UPCOMING
      }`
    ),
  getEliteseekerBookings: (id: string, size: number, page: number, preset?: LessonTypePreset) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<BookingListResponse>>(
      `/eliteseeker/${id}/booking?size=${size || 10}&page=${page || 1}&preset=${
        preset || LessonTypePreset.ONGOING_OR_UPCOMING
      }`
    ),
  updateAvailability: (request: UpdateElitetutorVacanciesRequest, tutorID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).put<ApiResponse<VacancyListResponse>>(
      `/elitetutor/${tutorID}/vacancy`,
      request
    ),
  fetchVacancyList: (tutorID: string) =>
    axiosApiClient().get<ApiResponse<VacancyListResponse>>(
      `/elitetutor/${tutorID}/vacancy?bookings=true`
    ),
  createBooking: (request: CreateBookingRequest, tutorID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<CreateBookingResponse>>(
      `/elitetutor/${tutorID}/booking`,
      request
    ),
  getElitePaymentConfig: () =>
    axiosApiClient().get<ApiResponse<ElitePaymentConfigResponse>>('/elitepayment/config'),
  createSubscriptionIntent: (request: ElitepaymentCreateSubscriptionIntentRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).post<
      ApiResponse<ElitepaymentSubscriptionIntentResponse>
    >('/elitepayment/subscription-intent', request),
  createSubscriptionIntentWithToken: (
    request: ElitepaymentCreateSubscriptionIntentRequestWithToken
  ) =>
    axiosApiAuthenticatedClient(getAccessToken).post<
      ApiResponse<ElitepaymentSubscriptionIntentResponse>
    >('/elitepayment/subscription-intent-with-token', request),
  getPersonalizedSubscriptionTokenData: (token: string) =>
    axiosApiAuthenticatedClient(getAccessToken).get<
      ApiResponse<GetPersonalizedSubscriptionTokenDataResponse>
    >(`/elitepayment/personalized-subscription/${token}`),
  getElitePaymentSeekerInfo: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<ElitepaymentSeekerInfoResponse>>(
      '/elitepayment/seeker-info'
    ),
  getEliteSeekerCreditEvent: (seekerID: string, size: number, page: number) =>
    axiosApiAuthenticatedClient(getAccessToken).get<
      ApiResponse<EliteseekerCreditEventListResponse>
    >(`/eliteseeker/${seekerID}/creditevent?size=${size || 10}&page=${page || 1}`),
  cancelBooking: (seekerID: string, bookingID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).delete<ApiResponse<BookingForSeekerResponse>>(
      `/eliteseeker/${seekerID}/booking/${bookingID}`
    ),
  getEliteTutorCreditEvent: (tutorID: string, size: number, page: number) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<ElitetutorCreditEventListResponse>>(
      `/elitetutor/${tutorID}/creditevent?size=${size || 10}&page=${page || 1}`
    ),
  getCurrentCreditTutor: (tutorID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<CreditTutorResponse>>(
      `/elitetutor/${tutorID}/credit-tutor`
    ),
  updateCreditTutorPayoutInfo: (request: UpdateCreditTutorPayoutInfo, tutorID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).put<ApiResponse<CreditTutorResponse>>(
      `/elitetutor/${tutorID}/credit-tutor/payout-info`,
      request
    ),
  deleteCreditTutorPayoutInfo: (tutorID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).delete<ApiResponse<CreditTutorResponse>>(
      `/elitetutor/${tutorID}/credit-tutor/payout-info`
    ),
  requestManualPayout: (tutorID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<CreditTutorResponse>>(
      `/elitetutor/${tutorID}/credit-tutor/request-payout`
    ),
  subscriptionChangePreview: (request: ElitepaymentChangeSubscriptionRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).put<
      ApiResponse<SeekerSubscriptionChangeInfoResponse>
    >('/elitepayment/subscription-change/preview', request),
  subscriptionChange: (request: ElitepaymentChangeSubscriptionRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).put<
      ApiResponse<SeekerSubscriptionChangeInfoResponse>
    >('/elitepayment/subscription-change', request),
  purchaseCredit: (request: ElitepaymentCreditPurchaseRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).put<
      ApiResponse<ElitepaymentCreditPurchaseResponse>
    >('/elitepayment/credit-purchase', request),
  promptCreditPurchase: (seekerID: string) =>
    axiosApiAuthenticatedClient(getAccessToken).post<
      ApiResponse<ElitepaymentCreditPurchaseResponse>
    >(`/elitepayment/prompt-credit-purchase/${seekerID}`),
  getEliteRatings: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<EliteratingResponse[]>>(
      '/eliterating'
    ),
  uploadEliterating: (data: EarnMoneyFormValues) => {
    const formData = new FormData();
    if (data.screenshot?.file) {
      formData.append('file', data.screenshot.file);
    }
    return axiosApiAuthenticatedFileClient(getAccessToken).post<ApiResponse<{}>>(
      `/eliterating/${data.seekerId}`,
      formData
    );
  },
});

export type ElitetutorApi = ReturnType<typeof elitetutorApiWithGetAccessToken>;
