import { isNumber } from 'lodash';

export const trimValue = (value: string | undefined) => (!!value ? value.trim() : value);

export const parseEmptyValueAs = <T>(defaultEmptyValue: T) => (
  value: any[] | string | number | null | undefined
): T | any[] | string | number | null | undefined =>
  !value && !isNumber(value) ? defaultEmptyValue : value;

export const STATE_CODE_MAX_LENGTH = 2;
