import React, { CSSProperties, FC } from 'react';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { Breakpoints } from 'logic/hooks/use-breakpoints';
import { ContentContainer } from 'components/content-container/content-container';
import './userprofilewrapper.styles.less';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';
import { Sidebar, SideBarLinks } from 'components/sidebar';

export const LayoutContainer = styled.div<{ showSidebar?: boolean }>`
  display: grid;

  grid-template-areas:
    'content';

  @media (${Breakpoints.minWideDesktop}) {
    ${({ showSidebar }) =>
            showSidebar
                    ? css`
                      grid-template-columns: 264px 880px;
                      grid-template-areas: 'sidebar content';
                    `
                    : css`
                      grid-template-columns: 880px;
                      grid-template-areas: 'content';
                    `}
  }
`;

export const LayoutContent = styled.div`
  grid-area: content;
`;

export const ContentContainerWrapper = styled(ContentContainer)`
  @media (${Breakpoints.minTablet}) {
    ${tw`px-4`}
  }
`;

export type UserProfile = {
  sidebarLinks?: SideBarLinks;
  style?: CSSProperties;
};

export const UserProfileWrapper: FC<UserProfile> = ({ children, sidebarLinks, style }) => {
  const { minTablet } = useBreakpoints();

  return (
    <ContentContainerWrapper background="teal">
      <LayoutContainer
        className={minTablet ? 'profile-full-height' : undefined}
        showSidebar={!!sidebarLinks?.regular?.length}
      >
        {sidebarLinks?.regular?.length && <Sidebar options={sidebarLinks} />}
        <LayoutContent style={style}>{children}</LayoutContent>
      </LayoutContainer>
    </ContentContainerWrapper>
  );
};
