import React from 'react';
import { useSelector } from 'react-redux';
import { getIsAuthtenticationVisible } from '../../logic/store/modal/modal.selectors';
import loadable from '@loadable/component';

const AuthenticationModal = loadable(() =>
  import('components/authentication/authentication.modal')
);

export const AuthenticationModalLazy = () => {
  const isVisible = useSelector(getIsAuthtenticationVisible);

  return isVisible ? <AuthenticationModal /> : null;
};
