import styled, { css } from 'styled-components';

export const Stack = styled.div<{
  gap?: number;
  flow?: 'row' | 'column' | 'row-reverse';
  inline?: boolean;
  display?: 'flex' | 'block';
  alignItems?: 'center' | 'baseline' | 'start' | 'end' | 'stretch' | 'flex-start' | 'flex-end';
  justifyContent?: 'normal' | 'center' | 'space-between' | 'space-around' | 'end' | 'start';
  justifyItems?: 'center' | 'start' | 'end' | 'stretch';
  textAlign?: 'center' | 'start' | 'end' | 'left' | 'right' | 'justify' | 'justify-all';
  autoColumns?: string;
  templateColumns?: string;
  autoRows?: string;
  templateRows?: string;
  fullWidth?: boolean;
}>`
  display: ${({ inline = false, display }) =>
    display ? display : inline ? 'inline-grid' : 'grid'};
  grid-auto-flow: ${({ flow = 'column' }) => flow};
  flex-direction: ${({ display, flow }) => display === 'flex' && flow && flow};
  grid-gap: ${({ gap = 0 }) => gap}rem;
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
  grid-auto-columns: ${({ autoColumns = 'auto' }) => autoColumns};
  grid-template-columns: ${({ templateColumns = 'none' }) => templateColumns};
  grid-auto-rows: ${({ autoRows = 'auto' }) => autoRows};
  grid-template-rows: ${({ templateRows = 'none' }) => templateRows};
  justify-items: ${({ justifyItems = 'legacy' }) => justifyItems};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  ${({ fullWidth = false }) =>
    fullWidth
      ? css`
          width: 100%;
          min-width: 100%;
        `
      : ''}
`;
