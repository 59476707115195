export const colors = {
  primary: '#BB3B5C',
  white: '#FFFFFF',
  transparentWhite: '#FFFFFFA0',
  black: '#000000',
  primaryDark: '#2a002D',
  blueGray: '#2F4251',
  blueGray2: '#96A4B0',
  darkGray: '#595858',
  grayText: '#929292',
  gray3: '#486270',
  gray4: '#DDDDDD',
  gray5: '#666666',
  gray6: '#F5F5F5',
  gray7: '#707070',
  gray7Transparent: '#70707020',
  gray8: '#EEEEEE',
  gray9: '#FAFAFA',
  gray10: '#87868D',
  gray11: '#C2C2C2',
  gray12: '#F4F4F4',
  gray13: '#EAEAEA',
  gray14: '#979797',
  gray15: '#D9D9D9',
  gray16: '#707070',
  gray17: '#455A64',
  gray18: '#B0B0B0',
  lightblueGray: '#ECEFF8',
  sidebarIconGray: '#7F7F7F',
  sidebarActiveRowGary: '#F6F5F5',
  border: '#E4E4E4',
  lightTeal: '#F5F8FA',
  green: '#016E00',
  darkGreen: '#015D00',
  separator: '#8080809B',
  red: '#E2665F',
  vibrantRed: '#F2295B',
  redLight: '#FFF2F0',
  catskillWhite: '#f5f8fa',
  background: '#F4F7F9',
  blue: '#91d5ff',
  blue2: '#5C6BC0',
  blueViolet: '#522EA9',
  blueLight: '#e6f7ff',
  violet: '#2f277f',
  darkGreen2: '#141C26',
  darkGreen3: '#33691f',
  orange: '#ef6c00',
  lightOrange: '#FCEDDE',
  blueDark: '#6f93ff',
  primaryLight: '#EB6978',
  violetLight: '#353353',
  violetLight2: '#786f8c',
  violetElite: '#181031',
  greenBright: '#44B76F',
  blueWhite: '#EBEEF8',
  uaYellow: '#F7D003',
  reviewVerified: '#b1f2d0',
  patrickNadlerGrey: '#33373B',
};

export const gradient = {
  primary: `linear-gradient(30deg, ${colors.primaryDark} 9%, ${colors.primary} 76%)`,
};
