import { combineEpics } from 'redux-observable';
import { filter, map, tap } from 'rxjs/operators';
import {
  AnalyticsAuthEvent,
  PixelEvents,
  triggerAnalyticsAuthEvent,
  triggerFBPixelEvent,
} from 'logic/analytics/analytics';
import { logSuccess } from '../../../log/log.logic';
import { translate, translationKeys } from '../../../translations/translations.service';
import { authenticationSlice } from '../authentication.slice';
import { RootEpic } from 'app/app.epics.type';

const loginWithAppleResponse$: RootEpic = (action$, _, { managed }) =>
  action$.pipe(
    filter(authenticationSlice.actions.loginWithAppleResponse.match),
    managed(
      authenticationSlice.actions.loginWithAppleResponse,
      tap((action) => {
        const isNew = action.payload.response?.isNewUser;
        const analyticsEventType = isNew ? AnalyticsAuthEvent.SignUp : AnalyticsAuthEvent.Login;

        if (isNew) {
          triggerFBPixelEvent(PixelEvents.CompleteRegistration);
        }
        triggerAnalyticsAuthEvent(analyticsEventType, action.payload.response?.user?._id, 'apple');
        logSuccess(translate(translationKeys.confirmation.welcomeToTheTutorSpaceCommunity));
      })
    ),
    map((action) =>
      authenticationSlice.actions.postLoginActions({ loginResponse: action.payload.response })
    )
  );

export const authenticationAppleEpics$ = combineEpics(loginWithAppleResponse$);
