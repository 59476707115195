import { components } from './generated-api-models';
import { OfferType } from './offer-type';
import { SortOffersBy } from './sort-offer-by';
import { TutoringType } from './tutoring-type';

export interface ApiResponse<T> {
  data?: T;
}

export type LoginRequest = components['schemas']['LoginDto'];
export type FacebookLoginRequest = components['schemas']['FacebookLoginDto'];
export type LoginResponse = Undefineable<components['schemas']['AuthenticationResponseDto']>;

export type RegisterRequest = components['schemas']['RegisterDto'];
export type RegisterWithoutPasswordRequest = components['schemas']['RegisterWithoutPasswordDto'];
export type RegisterElitetutorRequest = components['schemas']['RegisterElitetutorDto'];
export type RegisterElitetutorQuestionnaire = components['schemas']['QuestionnaireDto'][];
export type RegisterResponse = Undefineable<components['schemas']['AuthenticationResponseDto']>;

export type User = Undefineable<components['schemas']['UserResponseDto']>;

export type UserUpdateRequest = components['schemas']['UserUpdateDto'];
export type SmsActivationRequest = components['schemas']['SmsActivationDto'];
export type ResendSmsRequest = components['schemas']['ResendSmsDto'];

export type CreateOfferRequest = components['schemas']['CreateOfferDto'];
export type CreateOfferResponse = Undefineable<components['schemas']['OfferResponseDto']>;

export type SubmitEliteApplicationRequest = components['schemas']['SubmitApplicationDto'];
export type SubmitEliteApplicationResponse = Undefineable<
  components['schemas']['SubmitApplicationResponseDto']
>;

export type SubjectApplicationDetailsListResponse = components['schemas']['SubjectApplicationDetailsListResponseDto'];
export type SubjectApplicationDetails = components['schemas']['SubjectApplicationDetailsDto'];
export type SocialCallStatus = components['schemas']['SubjectApplicationDetailsListResponseDto']['socialCallStatus'];

type CreateSchoolOfferDto = UndefineableNullable<components['schemas']['CreateSchoolOfferDto']>;
export interface CreateInstituteOfferRequest extends CreateSchoolOfferDto {
  openingHours: OpeningHoursRequest[];
}

export enum WeekDay {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export interface OpeningHoursRequest {
  dayOfWeek: WeekDay;
  opens: string;
  closes: string;
}

export type OpeningHoursResponse = Undefineable<OpeningHoursRequest>;

export type UpdateOfferRequest = components['schemas']['UpdateOfferDto'];
export type UpdateOfferResponse = Undefineable<components['schemas']['OfferResponseDto']>;

export type SendPasswordResetRequest = components['schemas']['SendPasswordResetLinkDto'];

export type ResetPasswordRequest = components['schemas']['ResetPasswordDto'];

export type CheckResetTokenResponse = components['schemas']['CheckResetTokenResponseDto'];

export type Subject = Undefineable<components['schemas']['SubjectResponseDto']>;

export type SubjectResponse = Subject[];

export interface SearchOffersRequest {
  page: number;
  size: number;
  subjects?: number[];
  eliteSubjects?: number[];
  lat?: number;
  lng?: number;
  distanceInKms?: number;
  tutoringType: TutoringType[];
  offerType?: OfferType[];
  sortBy?: SortOffersBy;
  teachingLevel?: number;
}
export type OffersListResponse = Undefineable<components['schemas']['OfferListResponseDto']>;
export type OfferSearchResponseDto = Undefineable<components['schemas']['OfferSearchResponseDto']>;
export type OfferResponse = Undefineable<components['schemas']['OfferResponseDto']>;
export type UserOfferResponse = Undefineable<components['schemas']['UserOfferResponseDto']>;

export type OfferImageResponse = Undefineable<components['schemas']['OfferImageReferenceDto']>;

export type TutoringRequestRequest = components['schemas']['AddTutoringRequestDto'];
export type TutoringRequestResponse = Undefineable<
  components['schemas']['TutoringRequestResponseDto']
>;
export type TutoringRequestUser = Undefineable<components['schemas']['TutoringRequestUserDto']>;

export type EditTutoringRequestRequest = components['schemas']['EditTutoringRequestDto'];

export type PlacesListResponse = Undefineable<components['schemas']['PlacesListResponseDto']>;

export type FileResponse = Undefineable<components['schemas']['FileResponseDto']>;

export type UserRatingsResponse = components['schemas']['AllRatingsResponseDto'];
export type UserRatingFormResponse = components['schemas']['RatingResponseDto'];
export type RateTutorRequest = components['schemas']['RateUserDto'];

export enum AccountType {
  PARENT = 'PARENT',
  COLLEGE_STUDENT = 'COLLEGE_STUDENT',
  STUDENT = 'STUDENT',
  TUTORING_INSTITUTE = 'TUTORING_INSTITUTE',
  OTHER = 'OTHER',
}

export type AccountTypeStringLiteral = components['schemas']['UserResponseDto']['accountType'];

export enum AccountRight {
  ELITETUROR = 'elitetutor',
  ELITETUTOR_SUBJECTS = 'elitetutor_subjects',
  ELITESEEKER = 'eliteseeker',
}

export type AccountRights = Undefineable<components['schemas']['UserResponseDto']['rights']>;

export enum GroupSize {
  GROUP = 'GROUP',
  SINGLE = 'SINGLE',
}

export type RoomListResponse = Undefineable<components['schemas']['RoomListResponseDto']>;
export type RoomResponse = Undefineable<components['schemas']['RoomResponseDto']>;

export type JoinRoomResponse = Undefineable<components['schemas']['JoinRoomResponseDto']>;

export type BookingListResponse = Undefineable<components['schemas']['BookingListResponseDto']>;
export type Booking = Undefineable<components['schemas']['BookingResponseDto']>;
export type BookingForSeeker = Undefineable<components['schemas']['BookingForSeekerResponseDto']>;
export type UpdateElitetutorVacanciesRequest = Undefineable<
  components['schemas']['UpdateVacanciesDto']
>;

export type VacancyListResponse = Undefineable<components['schemas']['VacancyListResponseDto']>;
export type Vacancies = Undefineable<components['schemas']['VacancyListResponseDto']['vacancies']>;
export type Bookings = Undefineable<components['schemas']['VacancyListResponseDto']['bookings']>;

export type CreateBookingRequest = Undefineable<components['schemas']['CreateBookingDto']>;
export type CreateBookingResponse = Undefineable<components['schemas']['BookingResponseDto']>;

export type requestTeachingLevel = components['schemas']['BookingDetailsDto']['requestTeachingLevel'];

export type ElitePaymentConfigResponse = components['schemas']['ElitepaymentConfigDto'];
export type ElitepaymentPlan = components['schemas']['ElitepaymentPlanDto'];
export type ElitepaymentPrice = components['schemas']['ElitepaymentPriceDto'];
export type ElitepaymentTier = components['schemas']['ElitepaymentTierDto'];

export type ElitepaymentCreateSubscriptionIntentRequest = components['schemas']['ElitepaymentCreateSubscriptionIntentDto'];

export type ElitepaymentCreateSubscriptionIntentRequestWithToken = components['schemas']['ElitepaymentCreateSubscriptionIntentWithTokenDto'];

export type ElitepaymentSubscriptionIntentResponse = components['schemas']['ElitepaymentSubscriptionIntentResponseDto'];

export type ElitepaymentSeekerInfoResponse = components['schemas']['SeekerInfoResponseDto'];

export type EliteseekerCreditEventListResponse = components['schemas']['SeekerCreditEventListResponseDto'];

export type BookingForSeekerResponse = components['schemas']['BookingForSeekerResponseDto'];

export enum SubscriptionState {
  active = 'active',
  canceled = 'canceled',
  expired = 'expired',
  failed = 'failed',
  future = 'future',
  paused = 'paused',
}

export enum SeekerCreditEventType {
  booking = 'booking',
  credit_issued = 'credit_issued',
  credit_expired = 'credit_expired',
  booking_canceled = 'booking_canceled',
  no_show_refund = 'no_show_refund',
  correction_add = 'correction_add',
  correction_subtract = 'correction_subtract',
  free_booking_grant = 'free_booking_grant',
  free_booking_revoke = 'free_booking_revoke',
}

export type SeekerCreditEventFlags = components['schemas']['SeekerCreditEventFlagsResponseDto'];

export type ElitetutorCreditEventListResponse = components['schemas']['TutorCreditEventListResponseDto'];

export type CreditTutorResponse = components['schemas']['CreditTutorResponseDto'];

export type ElitepaymentCreditPurchaseResponse = components['schemas']['ElitepaymentCreditPurchaseResponseDto'];

export type UpdateCreditTutorPayoutInfo = Undefineable<
  components['schemas']['UpdateCreditTutorPayoutInfoDto']
>;

export type TutorCreditEventFlags = components['schemas']['TutorCreditEventFlagsResponseDto'];

export type TutorCreditEvent = components['schemas']['TutorCreditEventResponseDto'];

export type ElitepaymentChangeSubscriptionRequest = components['schemas']['ElitepaymentChangeSubscriptionDto'];

export type ElitepaymentCreditPurchaseRequest = components['schemas']['ElitepaymentCreditPurchaseDto'];

export type SeekerSubscriptionChangeInfoResponse = components['schemas']['SeekerSubscriptionChangeInfoResponseDto'];

export enum ElitetutorCreditEventType {
  lesson = 'lesson',
  lesson_trial = 'lesson_trial',
  bonus_trial = 'bonus_trial',
  penalty = 'penalty',
  payout = 'payout',
  correction = 'correction',
}

export type GetRecordingResponse = components['schemas']['GetRecordingResponseDto'];

export type EliteratingResponse = components['schemas']['EliteratingResponseDto'];

export type GetPersonalizedSubscriptionTokenDataResponse = components['schemas']['GetPersonalizedSubscriptionTokenDataResponseDto'];

export type CouponDiscount = components['schemas']['CouponDiscountResponseDto'];
