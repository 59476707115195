import { combineEpics, StateObservable } from 'redux-observable';
import { EMPTY, forkJoin, from, of, pipe } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import {
  ELITE_SEARCH_FORM_NAME,
  REFUGEE_SEARCH_FORM_NAME,
  SEARCH_FORM_NAME,
  SearchFormValues,
  validateSearchForm,
} from 'components/search/search.logic';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from '../../const';
import { getSubjectBySlug, mapSubjectsToSubjectsBySlug } from '../../subject/subject.logic';
import { subjectApi } from '../../subject/subjects.api';
import { subjectSlice } from '../../subject/subject.slice';
import { selectSubjectBySlug } from '../../subject/subject.selectors';
import { navigationSlice } from '../../navigation/navigation.slice';
import { offerSlice } from '../offer.slice';
import { RootEpic } from 'app/app.epics.type';
import { StoreState } from 'app/app.reducers';
import { Dispatch } from 'redux';
import {
  allowedMapBoxPlaceTypes,
  getEliteSearchResultsPath,
  getSearchAlternativeOffersTrackName,
  getSearchFormValuesForAlternativeTutoringKind,
  getSearchResultsPath,
  isBerlinOrNotLocality,
  mapSearchFormToEliteRequest,
  mapSearchFormToRequest,
} from './search-offer.logic';
import { geolocationApi } from 'logic/store/geolocation/geolocation.api';
import { MapBoxPlace } from 'logic/store/geolocation/geolocation.model';
import { initialize } from 'redux-form';
import { mapMapBoxPlacesFeatureToPlaceSelectOption } from 'logic/store/geolocation/geolocation.logic';
import { SEARCH_SORT_BY_DEFAULT } from 'logic/api-models/sort-offer-by';
import { AlternativeOffersKey } from '../offer.model';
import { TutoringKind } from 'components/search/search.const';

const navigateToSearchResultsEpic$: RootEpic = (action$, _, { managed, ofValidReduxForm }) =>
  action$.pipe(
    filter(offerSlice.actions.navigateToSearchResults.match),
    ofValidReduxForm(SEARCH_FORM_NAME, validateSearchForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      offerSlice.actions.navigateToSearchResults,
      pipe(
        map((action) => getSearchResultsPath(action.payload.formValues)),
        switchMap((path) => (path ? of(navigationSlice.actions.navigateTo({ path })) : EMPTY))
      )
    )
  );

const searchOffersEpic$: RootEpic = (action$, state$, { dispatch, managed, offerApi }) =>
  action$.pipe(
    filter(offerSlice.actions.searchOffers.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    map((action) => action.payload.formValues),
    managed(
      offerSlice.actions.searchOffers,
      pipe(
        initializeSubjectAndCityIfNeeded(state$, dispatch),
        tap((formValues) => dispatch(initialize(SEARCH_FORM_NAME, formValues))),
        switchMap((formValues) =>
          of(formValues).pipe(
            map((formValues) => mapSearchFormToRequest(formValues)),
            switchMap((request) => from(offerApi.searchOffers(request))),
            switchMap((response) =>
              of(
                offerSlice.actions.setOfferSearchResults({ offers: response.data.data }),
                offerSlice.actions.searchAlternativeOffers({
                  formValues,
                  alternativeOffersKey: AlternativeOffersKey.AlternativeOffersFirst,
                }),
                offerSlice.actions.searchAlternativeOffers({
                  formValues,
                  alternativeOffersKey: AlternativeOffersKey.AlternativeOffersSecond,
                })
              )
            )
          )
        )
      )
    )
  );

const searchAlternativeOffersEpic$: RootEpic = (action$, _, { managed, offerApi }) =>
  action$.pipe(
    filter(offerSlice.actions.searchAlternativeOffers.match),
    mergeMap((action) =>
      of(action).pipe(
        managed(
          getSearchAlternativeOffersTrackName(
            offerSlice.actions.searchAlternativeOffers.name,
            action.payload.alternativeOffersKey
          ),
          pipe(
            map((action) => action.payload.formValues),
            updateSearchFormValuesForAlternativeTutoringKind(action.payload.alternativeOffersKey),
            updateSearchFormValuesWithBrowserCoordinatesIfNeeded(),
            map((formValues) => mapSearchFormToRequest(formValues)),
            mergeMap((request) => from(offerApi.searchOffers(request))),
            map((response) =>
              offerSlice.actions.setAlternativeOfferSearchResults({
                offers: response.data.data,
                alternativeOffersKey: action.payload.alternativeOffersKey,
              })
            )
          )
        )
      )
    )
  );

const updateSearchFormValuesForAlternativeTutoringKind = (
  alternativeOffersKey: AlternativeOffersKey
) =>
  map((formValues: SearchFormValues | undefined) => ({
    ...formValues,
    kind: getSearchFormValuesForAlternativeTutoringKind(formValues?.kind, alternativeOffersKey),
  }));

const initializeSubjectAndCityIfNeeded = (
  state$: StateObservable<StoreState>,
  dispatch: Dispatch
) =>
  switchMap((formValues: SearchFormValues | undefined) =>
    forkJoin([
      fetchSubjectsIfNeeded$(formValues, state$.value, dispatch),
      fetchCityIfNeeded$(formValues),
    ]).pipe(
      map(([subject, citySelectOption]) => ({
        ...formValues,
        subject,
        city: citySelectOption?.value,
        citySlugResolved: citySelectOption?.value.labelShort,
        longitude: citySelectOption?.value.longitude,
        latitude: citySelectOption?.value.latitude,
        subjectName: subject?.subjectName,
        subjectSlugResolved: subject?.subjectName,
        sortBy: formValues?.sortBy || SEARCH_SORT_BY_DEFAULT,
      }))
    )
  );

const fetchSubjectsIfNeeded$ = (
  formValues: SearchFormValues | undefined,
  storeState: StoreState,
  dispatch: Dispatch
) => {
  const subjectSlug = formValues?.subjectSlug;
  const subject = selectSubjectBySlug(subjectSlug)(storeState);
  const shouldFetchSubjects = !!subjectSlug && !subject;

  return shouldFetchSubjects
    ? from(subjectApi.fetchSubjects()).pipe(
        map((subjectsResponse) => subjectsResponse.data.data),
        tap((subjects) => dispatch(subjectSlice.actions.setSubjects({ subjects }))),
        map((subjects) => getSubjectBySlug(mapSubjectsToSubjectsBySlug(subjects), subjectSlug))
      )
    : of(subject);
};

const fetchCityIfNeeded$ = (formValues: SearchFormValues | undefined) => {
  const searchPhrase = formValues?.cityId || formValues?.citySlug || '';

  return searchPhrase
    ? from(geolocationApi.fetchPlaces(searchPhrase, [MapBoxPlace.City, MapBoxPlace.Locality])).pipe(
        map((response) => response.data.features?.filter(isBerlinOrNotLocality)[0]),
        map((cityFeature) =>
          mapMapBoxPlacesFeatureToPlaceSelectOption(
            cityFeature,
            allowedMapBoxPlaceTypes(searchPhrase)
          )
        ),
        catchError(() => of(undefined))
      )
    : of(undefined);
};

const updateSearchFormValuesWithBrowserCoordinatesIfNeeded = () =>
  mergeMap((formValues: SearchFormValues | undefined) => {
    const shouldFetchBrowserCoordinates =
      formValues?.kind !== TutoringKind.onlineTutoring &&
      (!formValues?.longitude || !formValues?.latitude);

    return shouldFetchBrowserCoordinates
      ? from(geolocationApi.getBrowserGeolocation()).pipe(
          map((response) => ({
            ...formValues,
            longitude: response.coords.longitude,
            latitude: response.coords.latitude,
          })),
          catchError(() => of(formValues))
        )
      : of(formValues);
  });

const searchEliteOffersEpic$: RootEpic = (action$, state$, { managed, offerApi, dispatch }) =>
  action$.pipe(
    filter(offerSlice.actions.searchEliteOffers.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    map((action) => action.payload.formValues),
    managed(
      offerSlice.actions.searchEliteOffers,
      pipe(
        initializeSubjectAndCityIfNeeded(state$, dispatch),
        tap((formValues) => dispatch(initialize(ELITE_SEARCH_FORM_NAME, formValues))),
        switchMap((formValues) =>
          of(formValues).pipe(
            map((formValues) => mapSearchFormToEliteRequest(formValues)),
            switchMap((request) => from(offerApi.searchOffers(request))),
            switchMap((response) =>
              of(offerSlice.actions.setEliteOfferSearchResults({ offers: response.data.data }))
            )
          )
        )
      )
    )
  );

const searchRefugeeOffersEpic$: RootEpic = (action$, state$, { dispatch, managed, offerApi }) =>
  action$.pipe(
    filter(offerSlice.actions.searchRefugeeOffers.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    map((action) => action.payload.formValues),
    managed(
      offerSlice.actions.searchRefugeeOffers,
      pipe(
        initializeSubjectAndCityIfNeeded(state$, dispatch),
        tap((formValues) => dispatch(initialize(REFUGEE_SEARCH_FORM_NAME, formValues))),
        switchMap((formValues) =>
          of(formValues).pipe(
            map((formValues) => mapSearchFormToRequest(formValues)),
            switchMap((request) => from(offerApi.searchOffers(request))),
            switchMap((response) =>
              of(offerSlice.actions.setRefugeeOfferSearchResults({ offers: response.data.data }))
            )
          )
        )
      )
    )
  );

const navigateToEliteSearchResultsEpic$: RootEpic = (action$, _, { managed, ofValidReduxForm }) =>
  action$.pipe(
    filter(offerSlice.actions.navigateToEliteSearchResults.match),
    ofValidReduxForm(ELITE_SEARCH_FORM_NAME, validateSearchForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      offerSlice.actions.navigateToEliteSearchResults,
      pipe(
        map((action) => getEliteSearchResultsPath(action.payload.formValues)),
        switchMap((path) => (path ? of(navigationSlice.actions.navigateTo({ path })) : EMPTY))
      )
    )
  );

const navigateToRefugeeLocalSearchResultsEpic$: RootEpic = (
  action$,
  _,
  { managed, ofValidReduxForm }
) =>
  action$.pipe(
    filter(offerSlice.actions.navigateToRefugeeLocalSearchResults.match),
    ofValidReduxForm(REFUGEE_SEARCH_FORM_NAME, validateSearchForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      offerSlice.actions.navigateToRefugeeLocalSearchResults,
      pipe(
        map((action) => getSearchResultsPath(action.payload.formValues)),
        switchMap((path) => (path ? of(navigationSlice.actions.navigateTo({ path })) : EMPTY))
      )
    )
  );

const navigateToRefugeeOnlineSearchResultsEpic$: RootEpic = (
  action$,
  _,
  { managed, ofValidReduxForm }
) =>
  action$.pipe(
    filter(offerSlice.actions.navigateToRefugeeOnlineSearchResults.match),
    ofValidReduxForm(REFUGEE_SEARCH_FORM_NAME, validateSearchForm),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    managed(
      offerSlice.actions.navigateToRefugeeOnlineSearchResults,
      pipe(
        map((action) => getSearchResultsPath(action.payload.formValues)),
        switchMap((path) => (path ? of(navigationSlice.actions.navigateTo({ path })) : EMPTY))
      )
    )
  );

export const searchOfferEpic$ = combineEpics(
  navigateToSearchResultsEpic$,
  searchOffersEpic$,
  searchRefugeeOffersEpic$,
  searchAlternativeOffersEpic$,
  searchEliteOffersEpic$,
  navigateToEliteSearchResultsEpic$,
  navigateToRefugeeOnlineSearchResultsEpic$,
  navigateToRefugeeLocalSearchResultsEpic$
);
