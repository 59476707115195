import {
  DIE_REINLANDPFALZ,
  STUTTGARTER_ZEITUNG,
  SWR,
  WIRTSCHAFTSWOCHE,
} from 'assets/images/images';

export const TITLE_LINE_HEIGHT = 1.55;

export const bodyFontSize = (mobile?: boolean) => {
  return mobile ? 16 : 18;
};

export const highlightedBodyFontSize = (mobile?: boolean) => {
  return mobile ? 18 : 20;
};

export const headlineFontSize = (mobile?: boolean) => {
  return mobile ? 26 : 38;
};

export const mediumFontSize = (mobile?: boolean) => {
  return mobile ? 20 : 26;
};

export const knownFromList = [
  { src: WIRTSCHAFTSWOCHE, alt: 'WIRTSCHAFTSWOCHE' },
  { src: STUTTGARTER_ZEITUNG, alt: 'STUTTGARTER_ZEITUNG' },
  { src: DIE_REINLANDPFALZ, alt: 'DIE_REINLANDPFALZ' },
  { src: SWR, alt: 'SWR' },
];
