import { Avatar, Button, Image, Space } from 'antd';
import * as React from 'react';
import { CSSProperties, useCallback, useMemo, useRef } from 'react';
import './right-menu.styles.less';
import { useDispatch, useSelector } from 'react-redux';
import { getImageSrc } from 'logic/utils/image';
import { useBreakpoints } from 'logic/hooks/use-breakpoints';
import { USER_PROFILE_DEFAULT_AVATAR_IMAGE } from 'assets/images/images';
import { AuthenticationStepKey } from '../../../../logic/store/authentication/authentication.model';
import {
  getAuthenticatedUser,
  getIsAuthenticated,
} from '../../../../logic/store/authentication/authentication.selectors';
import { modalSlice } from '../../../../logic/store/modal/modal.slice';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { UserDropdown } from '../user-dropdown/user-dropdown';
import { useLocalizedI18nGlobal } from '../../../../logic/translations/use-localized-i18n-global';

const RightMenu: React.FC = () => {
  useLocalizedI18nGlobal();
  const dispatch = useDispatch();
  const { minTablet } = useBreakpoints();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getAuthenticatedUser);
  const loginRef = useRef<HTMLElement | null>(null);
  const registerRef = useRef<HTMLElement | null>(null);

  const buttonStyle = useMemo<CSSProperties | undefined>(
    () => (minTablet ? undefined : { fontSize: 12 }),
    [minTablet]
  );

  const handleLogin = useCallback(() => {
    loginRef.current?.blur();
    dispatch(
      modalSlice.actions.openAuthenticationModal({
        stepKey: AuthenticationStepKey.Login,
      })
    );
  }, [dispatch]);

  const handleRegister = useCallback(() => {
    registerRef.current?.blur();
    dispatch(
      modalSlice.actions.openAuthenticationModal({
        stepKey: AuthenticationStepKey.RegisterWithEmail,
      })
    );
  }, [dispatch]);

  return (
    <Space className="right-menu">
      {!isAuthenticated && (
        <Button ref={loginRef} onClick={handleLogin} style={buttonStyle}>
          {translate(translationKeys.buttons.login)}
        </Button>
      )}
      {!isAuthenticated && (
        <Button ref={registerRef} onClick={handleRegister} style={buttonStyle}>
          {translate(translationKeys.buttons.register)}
        </Button>
      )}
      {isAuthenticated && (
        <Avatar
          icon={<Image src={USER_PROFILE_DEFAULT_AVATAR_IMAGE} />}
          src={getImageSrc(user?.imageUrl)}
        />
      )}
      {isAuthenticated && <UserDropdown />}
    </Space>
  );
};

export default RightMenu;
