import {
  ApiResponse,
  UserRatingsResponse,
  UserRatingFormResponse,
  RateTutorRequest,
} from 'logic/api-models/api-models';
import { axiosApiClient } from 'logic/axios/axios-api-guest-client';

export const ratingApi = {
  fetchRatingsByUserId: (userId: string) =>
    axiosApiClient().get<ApiResponse<UserRatingsResponse>>(`/user-rating/${userId}`),
  fetchRatingsByOfferId: (offerId: string) =>
    axiosApiClient().get<ApiResponse<UserRatingsResponse>>(`/offer-rating/${offerId}`),
  fetchRatingsFormByUniqId: (uniqId: string) =>
    axiosApiClient().get<ApiResponse<UserRatingFormResponse>>(`/user-rating/form/${uniqId}`),
  rateTutor: (uniqId: string, rateObject: RateTutorRequest) =>
    axiosApiClient().post<ApiResponse<UserRatingFormResponse>>(
      `/user-rating/form/${uniqId}`,
      rateObject
    ),
  delayRating: (uniqId: string) =>
    axiosApiClient().post<ApiResponse<any>>(`/user-rating/form/${uniqId}/resend-email`),
  denyRating: (uniqId: string) =>
    axiosApiClient().post<ApiResponse<any>>(`/user-rating/form/${uniqId}/didnt-take-place`),
};
