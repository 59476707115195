import { useState, useEffect } from 'react';
import { hasWindow } from 'logic/utils/window';

// pass a query like `(min-width: 768px)`
export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState<any>(() => {});

  useEffect(() => {
    if (hasWindow) {
      setMatches(matchMedia(query).matches);
      const mediaQueryList = window.matchMedia(query);
      const onChange = (event: MediaQueryListEvent) => setMatches(event.matches);

      // note 1: add/removeListener probably aren't deprecated
      // see https://github.com/microsoft/TypeScript/issues/32210
      // note 2: safari currently doesn't support add/removeEventListener
      mediaQueryList.addListener(onChange);
      return () => mediaQueryList.removeListener(onChange);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export default useMatchMedia;
