import {
  ApiResponse,
  CreateOfferRequest,
  CreateOfferResponse,
  EditTutoringRequestRequest,
  OfferSearchResponseDto,
  OfferResponse,
  SearchOffersRequest,
  TutoringRequestRequest,
  TutoringRequestResponse,
  UpdateOfferRequest,
  UpdateOfferResponse,
} from 'logic/api-models/api-models';
import { axiosApiAuthenticatedClient } from 'logic/axios/axios-api-authenticated-client';
import { axiosApiClient } from 'logic/axios/axios-api-guest-client';
import { ReportOfferReason } from 'logic/api-models/report-offer';
import { GetAccessToken } from 'logic/axios/axios-headers-authentication.model';
import { TutoringType } from 'logic/api-models/tutoring-type';

export const offerApiWithGetAccessToken = (getAccessToken: GetAccessToken) => ({
  searchOffers: (request: SearchOffersRequest) => {
    const isOnlineTutoring = request.tutoringType.includes(TutoringType.online);
    const pageParam = `?page=${request.page}`;
    const pageSizeParam = `&size=${request.size}`;
    const subjectsParam = request.subjects?.length ? `&subjects=${request.subjects}` : '';
    const eliteSubjectsParam = request.eliteSubjects?.length
      ? `&eliteSubjects=${request.eliteSubjects}`
      : '';
    const lat = request.lat && !isOnlineTutoring ? `&lat=${request.lat}` : '';
    const lng = request.lng && !isOnlineTutoring ? `&lng=${request.lng}` : '';
    const distanceInKmsParam = lat && lng ? `&distanceInKms=${request.distanceInKms}` : '';
    const tutoringTypeParam = `&tutoringType=${request.tutoringType}`;
    const offerTypeParam = request.offerType ? `&offerType=${request.offerType}` : '';
    const sortBy = request.sortBy ? `&sortBy=${request.sortBy}` : '';
    const version = '&v=2';
    const teachingLevel = request.teachingLevel ? `&teachingLevel=${request.teachingLevel}` : '';

    let apiInstance = axiosApiClient();
    if (getAccessToken()) {
      apiInstance = axiosApiAuthenticatedClient(getAccessToken);
    }

    return apiInstance.get<ApiResponse<OfferSearchResponseDto>>(
      `/offer${pageParam}${pageSizeParam}${subjectsParam}${lat}${lng}${distanceInKmsParam}${tutoringTypeParam}${offerTypeParam}${sortBy}${version}${eliteSubjectsParam}${teachingLevel}`
    );
  },
  fetchOffer: (offerId: string) =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<OfferResponse>>(
      `/offer/${offerId}`
    ),
  requestOffer: (request: Partial<TutoringRequestRequest>) =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<TutoringRequestResponse>>(
      '/tutoring-request',
      request
    ),
  fetchOfferRequests: () =>
    axiosApiAuthenticatedClient(getAccessToken).get<ApiResponse<TutoringRequestResponse[]>>(
      '/tutoring-request'
    ),
  updateOfferRequest: (requestId: string, body: EditTutoringRequestRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).patch<ApiResponse<TutoringRequestResponse[]>>(
      `/tutoring-request/${requestId}`,
      body
    ),
  createMyOffer: (request: CreateOfferRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).post<ApiResponse<CreateOfferResponse>>(
      '/offer',
      request
    ),
  removeOffer: (offerId?: string) =>
    axiosApiAuthenticatedClient(getAccessToken).delete<ApiResponse<null>>(`/offer/${offerId}`),
  updateMyOffer: (offerId: string, body: UpdateOfferRequest) =>
    axiosApiAuthenticatedClient(getAccessToken).patch<ApiResponse<UpdateOfferResponse>>(
      `/offer/${offerId}`,
      body
    ),
  rateOffer: (offerId: string, body: { rating: number; comment: string }) =>
    axiosApiAuthenticatedClient(getAccessToken).post(`/offer-rating/school/${offerId}`, body),
  reportOffer: (offerId: string, body: { reason: ReportOfferReason }) =>
    axiosApiAuthenticatedClient(getAccessToken).post(`/offer/${offerId}/report`, body),
});

export type OfferApi = ReturnType<typeof offerApiWithGetAccessToken>;
