import { of, EMPTY } from 'rxjs';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { isNotActivatedPhoneError } from 'logic/error/not-activated-phone.errer';
import { notFoundErrorHandler } from 'logic/error/not-found.error';
import { sentryErrorHandler } from 'logic/error/sentry.error';
import { forbiddenErrorHandler } from 'logic/error/forbidden.error';
import { defaultApiErrorHandler } from '../error/default-api.error';
import { defaultErrorHandler } from '../error/default.error';
import { internalServerErrorHandler } from '../error/internal-server.error';
import { unauthenticatedErrorHandler } from '../error/unauthenticated.error';
import { getTrackName } from '../store/tracker/tracker.logic';
import { TrackConfig, trackerSlice } from '../store/tracker/tracker.slice';
import { ManageWithDispatch } from './managed.operator.type';
import { customApiErrorHandler } from 'logic/error/custom-api.error';

export const managedWithDispatch: ManageWithDispatch = (dispatch) => (
  trackNameObj,
  operator,
  customErrorHandlers
) =>
  mergeMap((action) => {
    const trackName = getTrackName(trackNameObj);
    const { successMessage } = trackNameObj as TrackConfig;
    return of(action).pipe(
      tap(() =>
        dispatch(
          trackerSlice.actions.setInProgress({
            trackName,
            action,
          })
        )
      ),
      operator,
      tap((result) =>
        dispatch(trackerSlice.actions.setSuccess({ trackName, result, successMessage }))
      ),
      catchError((error: any | undefined) => {
        dispatch(
          trackerSlice.actions.setFailure({
            trackName,
            error,
          })
        );

        const errorWasHandled = !![
          ...(customErrorHandlers || []),
          customApiErrorHandler,
          isNotActivatedPhoneError,
          forbiddenErrorHandler,
          unauthenticatedErrorHandler(dispatch),
          notFoundErrorHandler(dispatch),
          sentryErrorHandler,
          internalServerErrorHandler,
          defaultApiErrorHandler,
        ].find((handler) => (!!handler ? handler(error, action) : false));

        if (!errorWasHandled) {
          defaultErrorHandler(error, action);
        }

        return EMPTY;
      })
    );
  });
