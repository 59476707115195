import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import {
  defaultI18nConfig,
  I18nConfig,
  I18nConfigCallbacks,
  KnownLocale,
  noopI18nConfigCallbacks,
} from 'logic/translations/i18n-config';
import { getLocaleFromStorageOrBrowser, storeLanguage } from 'logic/i18n/i18n.logic';

const I18nConfigContext = createContext<[I18nConfig, I18nConfigCallbacks]>([
  defaultI18nConfig,
  noopI18nConfigCallbacks,
]);

// Warning! Do not use more than once because i18n-js and moment rely on
// JavaScript globals, which are not scoped by their component context like
// this I18nConfigProvider.
export const I18nConfigProvider: FC = ({ children }) => {
  const [i18nConfig, setI18nConfig] = useState(defaultI18nConfig);

  const setLocale = (locale: KnownLocale): void => {
    storeLanguage(locale);
    // Avoid loop by changing the object only if the locale changed
    if (locale !== i18nConfig?.locale) {
      setI18nConfig({
        ...i18nConfig,
        locale,
      });
    }
  };

  const restoreOrAutodetectLocale = () => {
    const locale = getLocaleFromStorageOrBrowser();
    if (locale) {
      setLocale(locale);
    }
  };

  const i18nConfigCallbacks = { setLocale };
  useEffect(restoreOrAutodetectLocale, []);

  return (
    <I18nConfigContext.Provider value={[i18nConfig, i18nConfigCallbacks]}>
      {children}
    </I18nConfigContext.Provider>
  );
};

export const useI18nConfig = () => useContext(I18nConfigContext);
