import { combineEpics } from 'redux-observable';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { RootEpic } from 'app/app.epics.type';
import { logSlice } from './log.slice';
import { logError, logSuccess } from 'logic/log/log.logic';
import { EMPTY } from 'rxjs';

const logSuccess$: RootEpic = (action$) =>
  action$.pipe(
    filter(logSlice.actions.logSuccess.match),
    tap((action) => logSuccess(action.payload.message)),
    mergeMap(() => EMPTY)
  );

const logError$: RootEpic = (action$) =>
  action$.pipe(
    filter(logSlice.actions.logError.match),
    tap((action) => logError(action.payload.message)),
    mergeMap(() => EMPTY)
  );

export const logEpics$ = combineEpics(logSuccess$, logError$);
