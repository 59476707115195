import { combineEpics } from 'redux-observable';
import { from, pipe } from 'rxjs';
import { filter, ignoreElements, switchMap, tap, throttleTime } from 'rxjs/operators';
import { API_MULTIPLY_CALLS_TIMEOUT_MS } from '../const';
import { conferenceSlice } from 'logic/store/conference/conference.slice';
import { RootEpic } from 'app/app.epics.type';
import { Feature } from 'logic/store/authentication/feature/authorization.feature';
import { hasWindow } from 'logic/utils/window';
import { logError } from 'logic/log/log.logic';
import { translate, translationKeys } from 'logic/translations/translations.service';
import { generateJoinBBBError } from 'logic/store/conference/conference.logic';
import { isForbiddenError } from 'logic/error/general-api-error';
import { isNotFoundError } from 'logic/error/not-found.error';

const getSocialCall$: RootEpic = (
  action$,
  _,
  { dispatch, managed, conferenceApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(conferenceSlice.actions.fetchSocialCalls.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.SocialCall),
    managed(
      conferenceSlice.actions.fetchSocialCalls,
      pipe(
        switchMap(() => from(conferenceApi.fetchSocialCall())),
        tap((response) =>
          dispatch(conferenceSlice.actions.setSocialCalls({ socialCalls: response.data.data }))
        )
      )
    ),
    ignoreElements()
  );

const getIntroductionCall$: RootEpic = (
  action$,
  _,
  { dispatch, managed, conferenceApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(conferenceSlice.actions.fetchIntroductionCalls.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.IntroductionCall),
    managed(
      conferenceSlice.actions.fetchIntroductionCalls,
      pipe(
        switchMap(() => from(conferenceApi.fetchIntroductionCall())),
        tap((response) =>
          dispatch(
            conferenceSlice.actions.setIntroductionCalls({ introductionCalls: response.data.data })
          )
        )
      )
    ),
    ignoreElements()
  );

const joinCall$: RootEpic = (action$, _, { dispatch, managed, conferenceApi, ofIsAuthenticated }) =>
  action$.pipe(
    filter(conferenceSlice.actions.joinCall.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      conferenceSlice.actions.joinCall,
      pipe(
        switchMap((callRoom) => from(conferenceApi.joinCall(callRoom.payload))),
        tap((response) => {
          const joinUrl = response?.data?.data?.joinUrl;
          try {
            if (joinUrl && hasWindow) {
              window.location.assign(joinUrl);
            } else {
              logError(generateJoinBBBError(response?.data?.data));
            }
          } catch (e) {
            logError(translate(translationKeys.errors.contactSupport));
          }
        })
      ),
      [
        (error) => {
          if (isForbiddenError(error)) {
            logError(translate(translationKeys.errors.noBBBJoinPermission));
            return true;
          }
          if (isNotFoundError(error)) {
            logError(translate(translationKeys.errors.BBBRoomNotFound));
            return true;
          }
          return false;
        },
      ]
    ),
    ignoreElements()
  );

const createAndJoinTestCall$: RootEpic = (
  action$,
  _,
  { managed, conferenceApi, ofIsAuthenticated }
) =>
  action$.pipe(
    filter(conferenceSlice.actions.createAndJoinTestCall.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    managed(
      conferenceSlice.actions.createAndJoinTestCall,
      pipe(
        switchMap(() => from(conferenceApi.createAndJoinTestCall())),
        tap((response) => {
          const joinUrl = response?.data?.data?.joinUrl;
          try {
            if (joinUrl && hasWindow) {
              window.location.assign(joinUrl);
            } else {
              logError(generateJoinBBBError(response?.data?.data));
            }
          } catch (e) {
            logError(translate(translationKeys.errors.contactSupport));
          }
        })
      ),
      [
        (error) => {
          if (isForbiddenError(error)) {
            logError(translate(translationKeys.errors.noBBBJoinPermission));
            return true;
          }
          if (isNotFoundError(error)) {
            logError(translate(translationKeys.errors.BBBRoomNotFound));
            return true;
          }
          return false;
        },
      ]
    ),
    ignoreElements()
  );

const getRecordings$: RootEpic = (
  action$,
  _,
  { dispatch, managed, conferenceApi, ofIsAuthenticated, ofIsAuthorized }
) =>
  action$.pipe(
    filter(conferenceSlice.actions.getRecording.match),
    throttleTime(API_MULTIPLY_CALLS_TIMEOUT_MS),
    ofIsAuthenticated(),
    ofIsAuthorized(Feature.IntroductionCall),
    managed(
      conferenceSlice.actions.getRecording,
      pipe(
        switchMap((action) => from(conferenceApi.getRecording(action.payload))),
        tap((response) =>
          dispatch(conferenceSlice.actions.setRecordingResponse({ response: response.data.data }))
        )
      )
    ),
    ignoreElements()
  );

export const conferenceEpic$ = combineEpics(
  getSocialCall$,
  getIntroductionCall$,
  joinCall$,
  createAndJoinTestCall$,
  getRecordings$
);
