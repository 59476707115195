import { Country } from '../../models/country';
import {
  getCountryCodeOptions,
  getCountryCodesISOAlpha2SelectOptions,
  getCountryNamesWithCodeOptions,
} from './country-code.select-options';

export const DEFAULT_PHONE_COUNTRY_CODE = 'DE';

const countryCodes: Country[] = [
  { country_code: 'DE', name: 'Deutschland' },
  { country_code: 'AT', name: 'Österreich' },
  { country_code: 'UA', name: 'Ukraine' },

  { country_code: 'BE', name: 'Belgien' },
  { country_code: 'BG', name: 'Bulgarien' },
  { country_code: 'CZ', name: 'Tschechien' },
  { country_code: 'DK', name: 'Dänemark' },
  { country_code: 'EE', name: 'Estland' },
  { country_code: 'IE', name: 'Irland' },

  { country_code: 'GR', name: 'Griechenland' },
  { country_code: 'ES', name: 'Spanien' },
  { country_code: 'FR', name: 'Frankreich' },
  { country_code: 'HR', name: 'Kroatien' },
  { country_code: 'IT', name: 'Italien' },
  { country_code: 'CY', name: 'Zypern' },
  { country_code: 'LV', name: 'Lettland' },

  { country_code: 'LT', name: 'Litauen' },
  { country_code: 'LU', name: 'Luxemburg' },
  { country_code: 'HU', name: 'Ungarn' },
  { country_code: 'MT', name: 'Malta' },
  { country_code: 'NL', name: 'Niederlande' },
  { country_code: 'PL', name: 'Polen' },

  { country_code: 'PT', name: 'Portugal' },
  { country_code: 'RO', name: 'Rumänien' },
  { country_code: 'SI', name: 'Slowenien' },
  { country_code: 'SK', name: 'Slowakei' },
  { country_code: 'FI', name: 'Finnland' },
  { country_code: 'SE', name: 'Schweden' },
];

export const COUNTRY_CODES_OPTIONS = getCountryCodeOptions(countryCodes);

export const COUNTRY_NAMES_WITH_CODES_OPTIONS = getCountryNamesWithCodeOptions(countryCodes);

export const COUNTRY_CODES_ISO_ALPHA_2_OPTIONS = getCountryCodesISOAlpha2SelectOptions(
  countryCodes
);
