import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPlaceIndex } from './geolocation.logic';
import { MapBoxPlace, PlaceSelectOption } from './geolocation.model';

export type AutoCompleteByPlace = {
  [key: string]: (PlaceSelectOption | undefined)[] | undefined;
};

export interface GeolocationState {
  autocompletePlacesSelectOptions?: AutoCompleteByPlace;
}

const initialState: GeolocationState = {};

export const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    reset: () => initialState,
    fetchAutocompletePlaces: (
      _state,
      _action: PayloadAction<{
        searchPhrase: string | undefined;
        valuePlace: MapBoxPlace | undefined;
        searchPlaces: MapBoxPlace[] | undefined;
      }>
    ) => {},
    setAutocompletePlacesSelectOptions: (
      state,
      action: PayloadAction<{
        options: PlaceSelectOption[] | undefined;
        valuePlace: MapBoxPlace | undefined;
      }>
    ) => {
      if (!state.autocompletePlacesSelectOptions) state.autocompletePlacesSelectOptions = {};
      state.autocompletePlacesSelectOptions[getPlaceIndex(action.payload.valuePlace)] =
        action.payload.options;
    },
  },
});
