export const NUMBER_OF_QUESTIONS_FIRST_FUNNEL = '5';
export const NUMBER_OF_QUESTIONS_SECOND_FUNNEL = '6';

export enum RegisterAsElitetutorPaths {
  Subjects = 'faecher',
  ExcitingBrand = 'marke',
  MultipleMonths = 'mehrere-monate',
  GermanLevel = 'deutsch-niveau',
  ContactDetails = 'kontakt-details',
  GermanLevelTooLow = 'deutsch-niveau-zu-niedrig',
  TeachingLevel = 'unterricht-niveau',
  TutoringType = 'ort',
  Price = 'preis',
  IntroduceYourself = 'vorstellung',
  Address = 'adresse',
  Photo = 'photo',
  Birthday = 'geburtstag',
  Done = 'fertig',
}
