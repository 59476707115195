import { initialize } from 'redux-form';

export const fillReduxForm = <D = any, F = any>(
  formName: string,
  mapper: (data: D) => F,
  data: D
) => {
  const formValues = mapper(data);
  return initialize(formName, formValues);
};
