import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { ErrorHandler } from './error-handler';

export const sentryErrorHandler: ErrorHandler<AxiosError | undefined> = (error) => {
  const extra = { errorData: JSON.stringify(error?.response?.data, null, 2) };

  Sentry.captureException(error, { extra });
  return false;
};
