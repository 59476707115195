import { isEmpty } from 'lodash';
import { Store } from 'redux';
import { getApiErrorData } from '../../axios/axios-api-error';
import { getReduxFormRegisteredFields } from '../../redux-form/get-redux-form-registered-fields.selector';
import { reduxFormAsyncErrorsHandler } from '../../redux-form/show-redux-form-errors.action';
import { isBadRequestError } from '../bad-request.error';
import { BadRequestError, BadRequestFormErrorHandler } from './bad-request-form.model';
import { mapServerErrorsToReduxFormErrors } from './bad-request-form.parser';

export const badRequestFormErrorHandlerWithStore = (store: Store): BadRequestFormErrorHandler => (
  reduxFormName,
  reduxFormAsyncValidator = mapServerErrorsToReduxFormErrors
) => (error) => {
  if (error && isBadRequestError(error)) {
    const storeState = store.getState();
    const errorMessage = getApiErrorData<BadRequestError>(error);
    const formFields: string[] = getReduxFormRegisteredFields(reduxFormName)(storeState);
    const reduxFormErrors = !!errorMessage
      ? reduxFormAsyncValidator(errorMessage.validation, formFields)
      : undefined;
    if (reduxFormErrors && !isEmpty(reduxFormErrors)) {
      reduxFormAsyncErrorsHandler(store.dispatch)(reduxFormName, reduxFormErrors);
      return true;
    }
  }

  return false;
};
